import { S3, S3Client, AbortMultipartUploadCommand, DeleteObjectCommandOutput, PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3"; 
import { Upload, Progress } from "@aws-sdk/lib-storage";
import { v4 } from "uuid";
import dotenv from "dotenv"
import fs from "fs"
dotenv.config()

interface S3CONFIG {
    region:string,
    bucketName:string,
    accessKeyId:string,
    secretAccessKey:string,
}

export class s3{
    config:S3CONFIG;
    client:S3Client;
    s3:S3;
    requestTimeout:number = 60;

    constructor(config:S3CONFIG){
        this.config = config;
        this.client = new S3Client({
            region:this.config.region,
            credentials:{
                accessKeyId:this.config.accessKeyId,
                secretAccessKey:this.config.secretAccessKey,
            }
        })
        this.s3 = new S3({
            apiVersion:'2006-03-01',
            region:this.config.region,
            credentials:{
                accessKeyId:this.config.accessKeyId,
                secretAccessKey:this.config.secretAccessKey,
            }
        })
    }
    upload(data:{
        key: string,
        data: any,
        contentType: string,
    },progressCallback?:(percentage: number) => void){
        let k = v4()
        
        let upload = new Upload({
            client: this.client,
            partSize:1000 * 1024 * 1024,
            params: { 
                Bucket:this.config.bucketName,
                Key: k,
                Body: Buffer.from(data.data, "base64"),
                ContentType: data.contentType,
                ContentEncoding: "base64",
            },
        })

        return new Promise<{
            location:string,
            data:any
        }>((resolve,reject)=>{
            upload.on("httpUploadProgress", (progress)=>{
                if(progress.loaded && progress.total){
                    let perc = progress.loaded / progress.total;
                    if(progressCallback) progressCallback(perc);
                }
            });

            upload.done().then((d)=>{
                resolve({
                    location:`https://s3.${this.config.region}.amazonaws.com/${this.config.bucketName}/${k}`,
                    data:d
                })
            }).catch((err)=>reject(err))
        })
    }

    delete(Key:string):Promise<DeleteObjectCommandOutput | undefined | PromiseLike<DeleteObjectCommandOutput>>{
        
        return new Promise<DeleteObjectCommandOutput | undefined | PromiseLike<DeleteObjectCommandOutput>>((resolve,reject)=>{
            this.s3.deleteObject({
                Bucket:this.config.bucketName,
                Key
            }, (err, data)=>{
                if (err){
                    console.log(err, err.stack);
                    reject(err)
                }  // error
                else{    
                    resolve(data)
                }               // deleted
            })
        })
    }
}


export default new s3({
    region:"ap-southeast-1",
    bucketName:"gochaseme",
    accessKeyId:process.env.VUE_APP_AWS_ACCESS_KEY_ID || '',
    secretAccessKey:process.env.VUE_APP_AWS_SECRET_ACCESS_KEY || '',
});