
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import randomGen from "@/views/app/randomGenerator"  
import TextInput from "./TextInput.vue"
import IconButton from "./IconButton.vue"
import Confirm from "./Confirm.vue"
import LazyImage from "./LazyImage.vue"
import Audio from "./Audio"
import {Card, rate} from "@/firebase/api/games"
import Room from "@/components/room/Room.vue"
import { setCardStatus, finishPlaythrough, savePlaythrough, saveHistory } from "@/firebase/api/playthrough"
import confetti from "canvas-confetti"
import Countdown from './Countdown.vue'
import {focus, clearFocus} from "@/components/elements/ActivityWindow.vue"

@Options({
  components:{
    MovingIcon,
    TextInput,
    IconButton,
    Confirm,
    LazyImage,
    Room,
  },
  emits:[]
})
export default class CardItem extends Base { 
  submitted:boolean = false;
  correct:boolean = false;
  complete:boolean = false;
  incorrect:boolean = false;
  hinted:boolean = false;
  skipped:boolean = false;
  hintRequested:boolean = false;
  skipRequested:boolean = false;
  answer:string = "";

  doorRoomData={
    tiles:{
      m:{
        door:{
          facing:"s",
          locked:true,
          open:false,
        }
      }
    }
  }
  chestRoomData={
    tiles:{
      m:{
        chest:{
          facing:"s",
          locked:true,
          opened:false,
        }
      }
    }
  }

  randomGen=randomGen;
  focus=focus;
  clearFocus=clearFocus;

  mounted(){
    this.getDoorStatus();
    this.ensurePlaythroughStore();
    focus('door',null,null,null);
  }

  rate(rating:number){
    this.$store.commit('gameView_rate', rating);
    rate(this.$store.state.GameView.lobby.meta.gameId, this.$store.state.Auth.user.id, rating)
  }

  calculateTime(stoppedAt:number,started:number,pauses:number){
    let all = (stoppedAt - started)/1000;
    let minusPauses = all - pauses;
    return this.timeLeft(minusPauses);
  }

  timeLeft(duration:number){   
    let hrs:any = ~~(duration / 3600);
    let mins:any = ~~((duration % 3600) / 60);
    let secs:any = ~~duration % 60;
    let ret:any = "";

    if (hrs > 0) ret += hrs + " hours";
    
    if(mins && !secs) ret += " and ";
    else if(hrs && mins && secs) ret += ", ";
    if(mins > 0 || hrs > 0) ret += mins + " minute";
    if(mins > 1){
      ret += "s "
    }else{
      ret += " "
    }

    if(mins && secs) ret += "and ";
    ret += secs +" seconds";
    return ret;
  }

  updateAnswer(answer:string){
    this.submitted = false;
    this.incorrect = false;
    this.correct = false;
    this.answer = answer;
  }

  @Watch('$store.state.GameView.lobby.playthrough.cardStatus')
  getDoorStatus(){
    if(!this.$store.state.GameView.lobby.playthrough){
      return
    }
    if(this.$store.state.GameView.game.doorPasswordEnabled){
      let inst = ['door','chest'];
      let d = false;
      let ind = 0;
      for(let i=0;i<inst.length;i++){
        if(d) break;
        d = this.$store.state.GameView.lobby.playthrough?.cardStatus[inst[i]];
        ind = i;
      }
      switch(this.$store.state.GameView.game.exitType){
        case(1): // chest
          if(d) this.chestRoomData.tiles.m.chest.locked = false;
          else this.chestRoomData.tiles.m.chest.locked = true;
          break;

        default: // door
          if(d) this.doorRoomData.tiles.m.door.locked = false;
          else this.doorRoomData.tiles.m.door.locked = true;
          break;
      }
    }
  }

  ensurePlaythroughStore(){
    this.$store.commit('setCardStatus',{
      id:'door',
      complete:false,
      correct:false,
      incorrect:false,
      hinted:false,
      skipped:false
    })
  }

  submit(){
    this.submitted = true;
    this.incorrect = false;
    this.correct = false;

    let oneFailed:boolean = false;
    if(this.$store.state.GameView.game.doorPassword){
      let accepted:string = this.$store.state.GameView.game.doorPassword.split(';').map((acceptedValue:string) => {
        return acceptedValue.trim().toLowerCase();
      })
      
      if(!this.answer || !accepted.includes(this.answer.toLowerCase().trim())){
        oneFailed = true;
        this.incorrect = true;
      }

      if(oneFailed == false){
        this.success();
      }else{
        this.fail();
        focus(undefined, undefined, undefined, false)
      }
    }
  }

  success(){
    this.submitted = false;
    new Audio("/sounds/success.wav",true,3)
    setCardStatus(this.$store.state.GameView.lobby,{
      id:'door',
      incorrect:false,
      correct:true,
      complete:true,
      passedTime:new Date().getTime()
    })
    this.getDoorStatus();
  }
  fail(){
    this.submitted = false;
    new Audio("/sounds/wrong.wav",true,3)
    setCardStatus(this.$store.state.GameView.lobby,{
      id:'door',
      correct:false,
      incorrect:true,
    })
    this.getDoorStatus();
  }

  requestSkip(){
    this.skipRequested = true;
  }
  denySkip(){
    this.skipRequested = false;
  }
  approveSkip(){
    this.skipRequested = false;
    this.$store.commit('setCardStatus',{
      id:'door',
      correct:false,
      incorrect:false,
      complete:true,
      skipped:true,
      passedTime:new Date().getTime()
    })
    this.$store.commit('unlockNextCard')
  }

  requestHintOne(){
    this.hintRequested = true;
  }
  denyHint(){
    this.hintRequested = false;
  }
  approveHint(){
    this.hintRequested = false;

    this.$store.commit('setCardStatus',{
      id:'door',
      correct:false,
      incorrect:false,
      complete:false,
      hinted:true
    })
  }

  async escaped(){
    if(!this.$store.state.GameView.lobby.playthrough.finished){
      new Audio("/sounds/win.wav",true,3)
      await finishPlaythrough(this.$store.state.GameView.lobby);
      await savePlaythrough(this.$store.state.GameView.lobby);
      if(this.$store.state.Auth.user && !this.$store.state.Auth.user.guest){
        saveHistory(this.$store.state.GameView.lobby, true)
      }
    }
  }

  @Watch('$store.state.GameView.lobby.playthrough.finished')
  cannon(){
    if(!this.$store.state.GameView.lobby.playthrough){
      return
    }
    if(this.$store.state.GameView.lobby.playthrough.finished > 0){
      let c = this.$refs.c as HTMLCanvasElement;
      let myConfetti = confetti.create(c, {
        resize: true,
      });
      myConfetti({
        particleCount: 100,
        spread: 160
      });
    }
  }
}
