import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Solid = _resolveComponent("Solid")!
  const _component_Lock = _resolveComponent("Lock")!

  return (_openBlock(), _createElementBlock("div", {
    class: "room_door",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCheck && _ctx.handleCheck(...args)))
  }, [
    _createVNode(_component_Solid, {
      x: -0.45,
      y: -0.475,
      height: 1.80,
      width: 0.1,
      depth: 0.1,
      color: "#000000"
    }, null, 8, ["x", "y", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0.45,
      y: -0.475,
      height: 1.80,
      width: 0.1,
      depth: 0.1,
      color: "#000000"
    }, null, 8, ["x", "y", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0,
      y: -0.475,
      z: 1.8,
      height: 0.1,
      width: 1,
      depth: 0.1,
      color: "#000000"
    }, null, 8, ["y", "z", "height", "depth"]),
    _createElementVNode("div", {
      class: "doorGroup",
      style: _normalizeStyle({
      transform: `rotateZ(${_ctx.doorTransform?.rotateZ}deg)`  
    })
    }, [
      _createVNode(_component_Solid, {
        x: 0.1,
        y: -0.425,
        z: 0.7,
        height: 0.05,
        width: 0.2,
        depth: 0.05,
        color: "rgb(255, 110, 110)",
        ref: "handle",
        transform: {
        ['transform-origin']:'100% 50% 0',
        transform:`rotateY(${_ctx.handleTransform?.rotateY}deg)`
      }
      }, null, 8, ["x", "y", "z", "height", "width", "depth", "transform"]),
      _createVNode(_component_Lock, {
        locked: !this.unlocked
      }, null, 8, ["locked"]),
      _createVNode(_component_Solid, {
        x: -0.1,
        y: -0.475,
        height: 1.80,
        width: 0.8,
        depth: 0.05,
        color: "#fff"
      }, null, 8, ["x", "y", "height", "width", "depth"])
    ], 4)
  ]))
}