import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-61f27655")
const _hoisted_1 = ["data-focus"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = {
  key: 0,
  class: "outcome"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovingIcon = _resolveComponent("MovingIcon")!

  return (!_ctx.hide && _ctx.$store.state.Auth.user.id !== _ctx.player.id && _ctx.$store.state.GameView.activityWindows)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
    activityWindow:true,
  }),
        style: _normalizeStyle(_ctx.position),
        "data-focus": _ctx.dataFocus
      }, [
        (_ctx.player.avatar)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass({icon:true, [_ctx.player.avatar]:true, _img:true})
            }, null, 2))
          : (_ctx.player)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({icon:true, _img:true}),
                style: _normalizeStyle(`background-image: url(https://s3.ap-southeast-1.amazonaws.com/gochaseme/${_ctx.player.id}.jfif)`)
              }, null, 4))
            : _createCommentVNode("", true),
        (_ctx.player.focus && _ctx.player.focus.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass({value:true, wrong:_ctx.player.focus?.result == false})
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.player.focus.value), 1),
              (_ctx.player.focus.result !== undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.player.focus.result == false)
                      ? (_openBlock(), _createBlock(_component_MovingIcon, {
                          key: 0,
                          name: "cross",
                          autoplay: true
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.player.focus.result == true)
                      ? (_openBlock(), _createBlock(_component_MovingIcon, {
                          key: 1,
                          name: "tick",
                          autoplay: true
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 12, _hoisted_1))
    : _createCommentVNode("", true)
}