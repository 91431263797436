
import { Options, Vue } from 'vue-class-component';
import IconButton from "@/components/elements/IconButton.vue"
import TextInput from "@/components/elements/TextInput.vue"
import TextArea from "@/components/elements/TextArea.vue"
import Checkbox from "@/components/elements/Checkbox.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import MovingIcon from "movingicons"
import { db } from '@/firebase';

@Options({  
  components: {
    IconButton,
    MovingIcon,
    TextInput,
    Checkbox,
    TextArea,
    Dropdown
  },
})
export default class Cookie extends Vue {
  settings:any = {};
  reportText:string = ""
  reportType: {
    active:number,
    options:{
      text:string,
      icon:string,
      sortFunction?:Function
    }[]
  } = {
    active:0,
    options:[
      {
        text:'Player',
        icon:'user',
      },
      {
        text:'Game',
        icon:'game',
      },
      {
        text:'Application',
        icon:'web',
      }
    ]
  }

  sendingReport:boolean = false;
  reportSendSuccess:boolean = false;
  reportSendError:boolean = false;

  mounted(){
    this.settings = {
      cookies:this.$store.state.Utils.cookies
    }
  }

  changeCookieSetting(){
    let newVal = true;
    if(this.$store.state.Utils.cookies){
      newVal = false;
    }
    this.$store.commit('setCookiePermission', newVal)
  }

  sendReport(){
    this.sendingReport = true;
    this.reportSendSuccess = false;
    this.reportSendError = false;

    if(this.reportText.trim().length == 0){
      this.reportSendError = true;
      this.sendingReport = false;
    }else{
      db.collection('reports').doc().set({
        user:this.$store.state.Auth.user.id,
        type:this.reportType.options[this.reportType.active].text,
        text:this.reportText
      }).then(()=>{
        this.sendingReport = false;
        this.reportSendSuccess = true;
      }).catch(()=>{
        this.sendingReport = false;
        this.reportSendError = true;
      })
    }
  }
}
