import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Solid = _resolveComponent("Solid")!

  return (_openBlock(), _createElementBlock("div", {
    class: "room_chest",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCheck && _ctx.handleCheck(...args)))
  }, [
    _createVNode(_component_Solid, {
      x: -0.25,
      y: 0,
      height: 0.2,
      width: 0.05,
      depth: 0.05,
      color: "gold"
    }, null, 8, ["x", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: -0.175,
      y: -0,
      height: 0.05,
      width: 0.15,
      depth: 0.05,
      color: "gold"
    }, null, 8, ["x", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: -0.175,
      y: -0,
      z: 0.15,
      height: 0.05,
      width: 0.15,
      depth: 0.05,
      color: "gold"
    }, null, 8, ["x", "z", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: -0.1,
      y: 0,
      height: 0.2,
      width: 0.05,
      depth: 0.05,
      color: "gold"
    }, null, 8, ["x", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0.05,
      y: 0,
      z: 0.1,
      height: 0.05,
      width: 0.35,
      depth: 0.025,
      color: "gold"
    }, null, 8, ["x", "z", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0.1,
      y: 0,
      z: 0.05,
      height: 0.1,
      width: 0.05,
      depth: 0.025,
      color: "gold"
    }, null, 8, ["x", "z", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0.2,
      y: 0,
      z: 0.03,
      height: 0.12,
      width: 0.05,
      depth: 0.025,
      color: "gold"
    }, null, 8, ["x", "z", "height", "width", "depth"])
  ]))
}