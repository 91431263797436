import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-15864faa")
const _hoisted_1 = { class: "iconContainer" }
const _hoisted_2 = {
  key: 0,
  class: "icon"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 0,
  class: "_children"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ripple = _resolveComponent("Ripple")!
  const _component_MovingIcon = _resolveComponent("MovingIcon")!
  const _component_NavigationItem = _resolveComponent("NavigationItem", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({navItem:true, active: _ctx.$route.name == _ctx.route.name}),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.navigateTo(_ctx.route)), ["stop"]))
  }, [
    _createVNode(_component_Ripple),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.route.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_MovingIcon, {
              ref: "icon",
              name: _ctx.route.icon,
              type: _ctx.route.type
            }, null, 8, ["name", "type"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.route.displayName) + " ", 1),
      (_ctx.route.children && _ctx.route.children.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.route.children, (child) => {
              return (_openBlock(), _createBlock(_component_NavigationItem, { route: child }, null, 8, ["route"]))
            }), 256))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}