
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class IconButton extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) transparent:boolean;
  @Prop({}) colored:boolean;
  @Prop({}) clear:boolean;
  @Prop({}) grey:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) autoplay:boolean;
  @Prop({}) success:boolean; 
  @Prop({}) fail:boolean; 
  @Prop({}) test:string
  @Prop({}) type:string
  @Prop({}) hex:string
  @Prop({}) popout:{
    text:string,
    function:Function
  }[]

  hidden:boolean = false;
  popoutVisible:boolean = false;

  mounted(){
    this.assignBaseProperties();
  }

  assignBaseProperties(){
    this.hidden = this.appearOnClick || false;
  }

  clicked(){
    if(this.appearOnClick){
      this.hidden = false;
    }else{
      if(this.playOnClick){
        this.play()
      }
    }

    if(this.popout){
      this.popoutVisible = true;
    }

    if(this.route){
      this.$router.push({path:this.route})
    }
  }

  close(){
    this.popoutVisible = false;
  }

  play(){
    if(this.playOnClick){
      let icon = this.$refs.icon as typeof MovingIcon;
      icon.play();
    }
  }

  trigger(row:{
    text:string,
    function:Function
  }){
    row.function();
    this.close();
  }

}
