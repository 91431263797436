
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import PayPalButton from "@/components/elements/PayPalButton.vue"
import Countdown from "@/components/elements/Countdown.vue"
import Account from "@/components/elements/Account.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import randomGen from "./randomGenerator"  
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import MovingIcon from "movingicons"
import LoginButton from "@/components/elements/LoginButton.vue"
import TextInput from "@/components/elements/TextInput.vue"

import { Game, getGameData } from "@/firebase/api/games"
import { createLobby, getConnectedState, getPermissionState, Lobby, getLobby, joinLobby, enterLobby } from "@/firebase/api/lobby"

@Options({  
  components: {
    Header,
    IconButton,
    Countdown,
    Account,
    MovingIcon,
    LoadSpinner,
    LazyImage,
    PayPalButton,
    LoginButton,
    TextInput
  },
})
export default class LobbyGenerator extends Base {
  game:Game|boolean = false;
  isConnected:boolean = false;
  lobbyDoesntExist:boolean = false; // really shouldn't ever become true, but we check anyway
  showJoinInputs:boolean = false;

  isPermitted:{
    joined:boolean,
    banned:boolean
  } = {
    joined:false,
    banned:false
  };
  lobby:Lobby;

  mounted(){
    this.init();
  }

  async init(){
    await this.getGame();
    await this.getLobby();

    if(this.lobby) await this.checkPermimssion();
    if(!this.isPermitted.banned || !this.isPermitted.joined) {
      await this.checkIfJoined()
      if(!this.isConnected) {
        await this.join()
      };
    };
  }

  async getGame(){
    this.game = await getGameData(this.$route.params.id as string) as Game;
  }

  updateName(nameEvent:InputEvent){
    let nameTarget = nameEvent?.target as HTMLInputElement;
    let name = nameTarget.value;
    this.$store.commit("setGuest",name);
  }
  
  async getLobby(){
    this.game = this.game as Game;
    let code = this.$route.params.code as string;
    let lobby = await getLobby(this.game.id, code)

    if(lobby){
      this.lobby = lobby;
    }else{
      this.lobbyDoesntExist = true;
    }
  }

  async checkPermimssion(){
    this.game = this.game as Game;
    if(!this.game.id) return;
    if(this.lobby && this.lobby.meta.code){
      this.isPermitted = await getPermissionState(this.lobby.meta.code, this.$store.state.Auth.user.id, this.$store.state.Auth.user.name)
    }
  }

  async checkIfJoined(){
    this.game = this.game as Game;
    if(!this.game.id) return;
    if(this.lobby && this.lobby.meta.code){
      this.isConnected = await getConnectedState(this.lobby.meta.code, this.$store.state.Auth.user.id, this.$store.state.Auth.user.name)
    }
  }

  async join(){
    if(!this.$store.state.Auth.user.name){
        this.showJoinInputs = true;
    }else if(!this.isConnected && (!this.isPermitted.banned && !this.isPermitted.joined) && this.lobby){
      if(this.$store.state.Auth.user.id || this.$store.state.Auth.user.name){
        await joinLobby(this.lobby.meta.code,this.$store.state.Auth.user.id,this.$store.state.Auth.user.name);
        this.game = this.game as Game;
        this.$router.replace({path:`/app/lobby/${this.game.id}/${this.lobby.meta.code}`});
      }else{
        this.$router.replace({path:'/app/join'});
      }
    }else if(this.isPermitted.joined && !this.isPermitted.banned){
      this.game = this.game as Game;
      this.$router.replace({path:`/app/lobby/${this.game.id}/${this.lobby.meta.code}`});
    }
  }

  navToBrowse(){
    this.$router.replace({path:'/app'})
  }
  navToGame(){
    this.game = this.game as Game;
    this.$router.replace({path:`/app/store/${this.game.id}`})
  }
}
