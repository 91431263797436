
import { Options, Vue } from 'vue-class-component';
import TextInput from "@/components/elements/TextInput.vue"
import TextArea from "@/components/elements/TextArea.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import GamePreview from "@/components/elements/GamePreview.vue"
import PageControls from "@/components/elements/PageControls.vue"
import IconButton from "@/components/elements/IconButton.vue"
import PopupInput from "@/components/elements/PopupInput.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import Checkbox from "@/components/elements/Checkbox.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import Confirm from "@/components/elements/Confirm.vue"
import FileUploader from "@/components/elements/FileUploader.vue"

import Room, { RoomProperties } from "@/components/room/Room.vue"

import MovingIcon from "movingicons"
import {db} from "@/firebase"
import {allCardsWhere, Card, updateCardWithID, getGame, getGameData, Game, newQuestion, Question, getQuestion, addCard, removeCard, getRatingData, F, newAttribution, Attribution, getAttribution} from "@/firebase/api/games"
import { log } from 'firebase-functions/logger';

@Options({  
  components: {
    TextInput,
    TextArea,
    Dropdown,
    GamePreview,
    PageControls,
    IconButton,
    PopupInput,
    LazyImage,
    MovingIcon,
    Confirm,
    LoadSpinner,
    Checkbox,
    FileUploader,
    Room
  },
})
export default class DeveloperGameEdit extends Vue {

  gameID:string = '';
  homeImage:string = 'https://picsum.photos/200/300';
  game:any;
  loading:boolean = true;
  saving:{
    [data:string]:{
      [field:string]:boolean
    }
  } = {}
  removeRequested:boolean = false;
  removeInvoked:boolean = false;
  passwordExit:boolean = false;
  freeGame:boolean = false;
  
  gettingPlaythroughData:boolean = true;
  playthroughData:any = false;

  gettingPurchaseData:boolean = true;
  purchaseData:any = false;

  gettingRatingData:boolean = true;
  ratingData:any = false;

  fileUploadStatus:number = 0;
  fileUploadPerc:number = 0;

  deletingAttr:string[] = [];
  addingAttr:number[] = [];

  gameRoom:RoomProperties = {
    tiles:{
      n:{
        door:{
          locked:true,
          opened:false,
          facing:"e"
        }
      },
      m:{
        chest:{
          locked:true,
          opened:false,
          facing:"e"
        }
      }
    }
  }

  mounted(){
    this.gameID = this.$route.params.id as string;
    this.getGameFromFirebase()
    this.getPages();
  }

  getPurchaseData(){
    this.gettingPurchaseData = true;
    db.collection('purchases').where('gameId','==',this.$route.params.id).get().then(data=>{
      if(!data.empty){
        this.purchaseData = {
          number:data.docs.length
        }
      }
      this.gettingPurchaseData = false;
    })
  }

  getPlaythroughData(){
    db.collection('playthroughs').where('gameId','==',this.$route.params.id).get().then(data=>{
      if(!data.empty){
        this.playthroughData = {
          number:0,
          fastestTime:-1,
          lastPlayed:0,

          totalDuration:[],
          avDuration:0,
          
          totalHints:[],
          avHints:0,
          
          totalLobbySize:[],
          avLobbySize:0,
        }
        data.docs.forEach(doc => {
          if(doc.exists){
            this.playthroughData.number++;
            let d = doc.data()

            this.playthroughData.totalDuration.push(d.time);
            this.playthroughData.totalHints.push(d.hints || 0);
            this.playthroughData.totalLobbySize.push(d.lobbySize || 1);

            if(d.started > this.playthroughData.lastPlayed){
              this.playthroughData.lastPlayed = d.started
            }
            if(this.playthroughData.fastestTime == -1 || d.time < this.playthroughData.fastestTime){
              this.playthroughData.fastestTime = d.time
            }
          }
        })

        this.playthroughData.avDuration = this.median(this.playthroughData.totalDuration);
        this.playthroughData.avHints = this.median(this.playthroughData.totalHints);
        this.playthroughData.avLobbySize = this.median(this.playthroughData.totalLobbySize);
      }
      this.gettingPlaythroughData = false;
    }).catch(e=>{
      throw Error(e);
      this.gettingPlaythroughData = false;
    })
  }

  median(values:number[]){
    if(values.length ===0) throw new Error("No inputs");

    values.sort((a:any,b:any)=>{
      return a-b;
    });

    let half = Math.floor(values.length / 2);
    
    if (values.length % 2)
      return values[half];
    
    return (values[half - 1] + values[half]) / 2.0;
  }

  returnToBrowse(){
    this.$router.push({path:'/developer/games/'})
  }

  passwordExitToggle(game:Game){
    this.passwordExit = !this.passwordExit;
    this.updateGameField(game,{
      doorPasswordEnabled:this.passwordExit
    })
  }

  freeGameToggle(game:Game){
    this.updateGameField(game,{
      free:!this.$store.state.GameEdit.game.free
    })
  }

  beforeUnmount(){
    this.$store.commit('clearGameEdit');
  }

  getGameFromFirebase(){
    let id = this.$route.params.id as string;
    if(id){
      getGame(id).then(async (data)=>{
        let d = await getGame(this.$route.params.id as string);
        if(d.exists && d.data()){
          this.game = d;
          let gameData = await getGameData(this.$route.params.id as string) as Game;
          this.$store.commit('gameEdit_setGame',gameData);
          getRatingData(this.game.id).then((rating)=>{
            this.game.rating = rating;
          })
        }else{
          throw "Gamed does not exist"
        }
      }).catch(()=>{
        console.error("COULD NOT RETRIEVE GAME");
      })
    }
  }

  getPages(){
    allCardsWhere({
      row:"parent",
      comparitor: "==",
      value:this.gameID
    },[
      {
        collection:'questions',
        where:{
          row:"cardid",
          comparitor: "==",
          value:'id'
        }
      },
      {
        collection:'attributions',
        where:{
          row:"cardid",
          comparitor: "==",
          value:'id'
        }
      }
    ],{
      row:'index',
      order:"asc"
    }).then((data)=>{
      this.loading = false;
      this.$store.commit('gameEdit_setCards',data);
      this.shouldNavigateToPage();
    }).catch(()=>{
      console.error("COULD NOT RETRIEVE CARDS");
    })
  }
   
  shouldNavigateToPage(){
    if(this.$route.params.page){
      this.$store.commit('setCardFocus',this.$route.params.page);
    }else{
      this.$store.commit('setCardFocus','meta');
    }
  }

  changeImageURL(card:Card,newURL:string){
    updateCardWithID(`${card.id}`).then(doc=>{
      if(doc.exists){
        doc.ref.update({image:newURL}).then(()=>{
          card.image=newURL;
        })
      }
      else{
        alert("cannot save, card does not exist - idk why tbh");
      }
    })  
  }

  newGameImage(game:Game,newValue:string){
    this.updateGameField(game,{
      image: newValue,
      imageDimensions:{
        width:0,
        height:0
      }
    });
  }

  updateGameField(game:Game,newValue:Object){
    if(this.game){
      this.triggerSaving(game,newValue)
      this.game.ref.update(newValue).then(()=>{
        for(let [index,field] of Object.entries(newValue)){
          // @ts-ignore
          game[index] = field;
        }
        this.saved(game,newValue)
      })
    }
  }
  updateCardField(card:Card,newValue:Object){
    updateCardWithID(`${card.id}`).then(doc=>{
      if(doc.exists){
        this.triggerSaving(card,newValue)
        doc.ref.update(newValue).then(()=>{
          for(let [index,field] of Object.entries(newValue)){
            // @ts-ignore
            card[index] = field;
          }
          this.saved(card,newValue)
        })
      }
      else{
        alert("cannot save, card does not exist - idk why tbh");
      }
    })
  }

  triggerSaving(focus:any,newValue:Object){
    if(!this.saving[focus]) this.saving[focus] = {};
    for(let [index,field] of Object.entries(newValue)){
      // @ts-ignore
      this.saving[focus][index] = true;
    }
  }
  saved(focus:any,newValue:Object){
    for(let [index,field] of Object.entries(newValue)){
      // @ts-ignore
      delete this.saving[focus][index];
    }
  }

  newAttribution(card:Card){
    this.addingAttr.push(1);
    newAttribution({
      cardid:card.id,
      resource:'',
      author:'',
      license:'',
      changes:0
    }).then((attr)=>{
      let data = attr.data()
      if(data){
        if(!card.attributions) card.attributions = [];
        card.attributions.push({
          id:attr.id,
          ...data
        } as Attribution);
        this.addingAttr.pop();
      }
    }).catch(err=>{
      console.error(err);
    })
  }

  updateAttribution(attr:Attribution,newValue:Object){
    getAttribution(attr.id)
    .then(doc=>{
      if(doc.exists){
        this.triggerSaving(attr,newValue)
        doc.ref.update(newValue).then(()=>{
          for(let [index,field] of Object.entries(newValue)){
            // @ts-ignore
            attr[index] = field;
          }
          this.saved(attr,newValue)
        })
      }else{
        alert("cannot save, attribution does not exist - idk why tbh");
      }
    })
    .catch(err=>{
      console.error(err);
    })
  }

  deleteAttribution(card:any, index:string){
    if(card.attributions){
      let id = card.attributions[index]?.id;
      this.deletingAttr.push(id);
      getAttribution(id)
      .then(doc=>{
        if(doc.exists){
          doc.ref.delete().then(doc => {
            card.attributions?.splice(index,1);
            for(let i=0; i<this.deletingAttr.length; i++){
              if(this.deletingAttr[i] == id){
                this.deletingAttr.splice(i,1);
                i=i-1;
              }
            }
          })
        }else{
          alert("cannot delete, attribution does not exist - idk why tbh");
        }
      })
      .catch(err=>{
        console.error(err);
      })
    }
  }

  addQuestion(card:Card){
    newQuestion({
      cardid:card.id,
      question: '',
      acceptedAnswers: []
    }).then((question)=>{
      let data = question.data()
      if(data){
        if(!card.questions) card.questions = [];
        card.questions.push({
          id:question.id,
          ...data
        } as Question)
      }
    }).catch(err=>{
      console.error(err);
    })
  }
  updateQuestion(question:Question,newValue:Object){
    getQuestion(question.id)
    .then(doc=>{
      if(doc.exists){
        this.triggerSaving(question,newValue)
        doc.ref.update(newValue).then(()=>{
          for(let [index,field] of Object.entries(newValue)){
            // @ts-ignore
            question[index] = field;
          }
          this.saved(question,newValue)
        })
      }else{
        alert("cannot save, question does not exist - idk why tbh");
      }
    })
    .catch(err=>{
      console.error(err);
    })
  }
  removeQuestion(cardIndex:number, questionIndex:number){
    let card = this.$store.state.GameEdit.cards.data[cardIndex];
    let question = card.questions[questionIndex];
    getQuestion(question.id)
    .then(doc=>{
      if(doc.exists){
        doc.ref.delete().then(doc => {
          card.questions.splice(questionIndex,1)
        })
      }else{
        alert("cannot save, question does not exist - idk why tbh");
      }
    })
    .catch(err=>{
      console.error(err);
    })
  }

  addCard(){
    let highestIndex:number = -1;
    for(let card of (this.$store.state.GameEdit.cards.data as Card[])){
      highestIndex = Math.max(highestIndex,card.index);
    }
    this.$store.commit('creatingCardLoad',true)
    addCard({
      index:highestIndex+1,
      parent:this.gameID
    }).then(doc=>{
      doc.get().then(doc=>{
        this.$store.commit('creatingCardLoad',false)
        this.$store.commit('addCard',{id:doc.id,...doc.data()});
        this.$store.commit('setCardFocus',highestIndex+1);
      })
    }).catch(e=>{
      alert("Error creating card - see console");
      console.error("ERROR:",e);
      this.$store.commit('creatingCardLoad',false)
    })
  }

  requestRemoveCard(){
    this.removeRequested = true;
  }
  approveRemoveCard(card:Card){
    this.removeInvoked = true;
    removeCard(card).then((outcome)=>{
      this.$store.commit('removeCard',card)
      this.removeInvoked = false;
      this.removeRequested = false;
    }).catch(err=>{
      alert("Card delete error - see logs");
      console.error(err);
    })
  }
  rejectRemoveCard(){
    this.removeRequested = false;
  }

  reorderCards(){
    this.$store.commit('reorder',!this.$store.state.GameEdit.reordering)
  }

  reviewGame(){
    this.$router.push({path:`/developer/games/preview/${this.gameID}`})
  }

  uploaderPreview(mode:"input"|"upload",previewsrc:string){
    // this.uploader = (this.$refs.fileUploader as FileUploader);
    // this.imagePreview = previewsrc;
    // this.mode = mode;
    // this.$emit('preview',this.imagePreview)
  }

  uploaded(card:Card, files:F[]){
    let u:{
      name:string,
      location:string
    }[] = [];
    if(card.uploads){
      u = card.uploads;
    }

    for(let file of files){
      u.push({
        name:file.name,
        location:file.location || ""
      })
    }

    this.updateCardField(card,{
      uploads: u
    })
  }

}
