
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import {db, auth} from "./firebase"
import S3 from "@/aws"
import axios from "axios"
import { Stream } from "stream";
import avatars from "@/assets/avatars/avatars"
import {setCookie, getCookie} from "@/scripts/cookie"
import { v4 } from "uuid";
import CookieCheck from "@/components/elements/CookieCheck.vue"

@Options({
  components:{
    CookieCheck
  }
})
export default class App extends Base {
  mounted(){

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.$store.commit('setPWAPrompt', e)
    })

    let cookieState = getCookie('_c')
    if(cookieState){
      this.$store.commit('setCookiePermission', cookieState)
    }

    this.$store.commit('userAwait');
    auth.onAuthStateChanged(async (userBasic) => {
      if (userBasic) {
        let photo="", avatar="";
        if(userBasic.photoURL && userBasic.uid){
          photo = userBasic.photoURL;
          this.upload(userBasic.photoURL, userBasic.uid, (per:number)=>{}).then((up)=>{
            photo = up.location
            this.$store.commit('setUser',user);
          });
        }else{
          avatar = avatars[Math.round(Math.random() * avatars.length-1)]
        }
        let user = {
            id: userBasic?.uid as string|undefined,
            name:userBasic?.displayName as string|undefined,
            email:userBasic?.email as string|undefined,
            picture:photo,
            avatar:avatar,
            developer:-1
        }
        this.$store.commit('setUser',user);

        // check developer status
        let isDev = await db.collection('developers').doc(user.email).get();
        if(isDev.exists){
          this.$store.commit('setDev',true);
        }else{
          this.$store.commit('setDev',false);
        }
      }else{
        // LOOK FOR COOKIE
        let avatar = avatars[Math.round(Math.random() * avatars.length-1)]
        let u = `{"id":"${v4()}","name":"","signedOut":true,"avatar":"${avatar}"}`

        let c = getCookie("guest")
        if(!c){
          setCookie("guest", u, 1)
        }else{
          u = c;
        }

        this.$store.commit('setUser',JSON.parse(u))
      }
    });
  }

  async upload(imgURL:string, uid:string, perc:(per:number)=>void){
    return new Promise<{
      location:string,
      data:any
    }>((resolve,reject)=>{
      // axios({
      //   url: imgURL, //your url
      //   method: 'GET',
      //   responseType: 'blob', // important
      // }).then((response) => {
      //   S3.upload({
      //     key: uid;
      //     data: response.data;
      //     contentType: 'jfif';
      //   },perc).then((data:{
      //     location:string,
      //     data:any
      //   })=>{
      //     resolve(data)
      //   }).catch(e=>{
      //     reject(e)
      //   })
      // })
    })
  }
}

