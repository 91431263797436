import baseapi from "./baseapi"
import firebase, {realtime} from "@/firebase"
import {User} from "./auth"
import { createPlaythrough, Playthrough } from "./playthrough";
import store from '@/store'

export interface Player{
    id:string,
    name:string,
    imageNum:number,
    loggedIn:boolean,
    joined:number,
    host:boolean,
    dcd:boolean,
    lastPing:number,
    focus?:{
        cardId:string,
        context:string,
        input?:string,
        result?:boolean
    },
    avatar:string,
    picture:string
}
export interface Lobby{
    meta:{
        code:string,
        gameId:string,
        created:number,
        host:string,
    },
    permitted?:{
        [identifier:string]:{
            banned:boolean
        }
    }
    players?:{
        [identifier:string]:Player
    },
    playthrough?:Playthrough,
    messages?:{
        id:string,
        name:string,
        avatar:string,
        message:string
    }[]
} 

function makeLobbyCode(){
    let result = '';
    let characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < 5; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export async function createLobby(gameId:string, user:User){
    let code = makeLobbyCode()
    await realtime.ref('/lobby/'+code+'/meta/').set({
        gameId:gameId,
        host:user.id,
        code,
    })
    store.commit('gameView_rate', -1);
    await createPlaythrough(code, gameId);
    let id:string = user.id as string;
    await setHost(code,id)
    await setCreated(code)
    await joinLobby(code,id,(user.name as string))
    return getLobby(gameId, code);
}
export async function setHost(code:string,id:string){
    realtime.ref('/lobby/'+code+'/meta/host').set(id)
}
export async function setCreated(code:string){
    await realtime.ref('/lobby/'+code+'/meta/created').set(new Date().getTime())
}
export async function joinLobby(code:string,id?:string,name?:string){
    let key = id?id:name;
    await realtime.ref('/lobby/'+code+'/permitted/'+key).set({
        banned:false
    })
    store.commit('gameView_rate', -1);
}
export async function kick(code:string,id?:string,name?:string){
    let key = id?id:name;
    await realtime.ref('/lobby/'+code+'/permitted/'+key).set({
        banned:true
    })
    store.commit('gameView_rate', -1);
}
export async function enterLobby(code:string,user:User,host?:boolean){
    let data:any = {
        ...user,
        joined:new Date().getTime(),
        host:host,
    }
    await realtime.ref('/lobby/'+code+'/players/'+user.id).set(data)
}

export function watchLobby(lobby:Lobby){
    let lobbyListener = realtime.ref(`lobby/${lobby.meta.code}/`).on('value', (snapshot) => {
        let _ = snapshot.val();
        store.commit('gameView_setLobby',_);
    });
    store.commit('gameView_setLobbyListener', lobbyListener)
    store.commit('gameView_setLobby',lobby);
    store.commit('gameView_pingLobby',lobby);
}

export function detatchLobby(lobby:Lobby){
    realtime.ref(`lobby/${lobby.meta.code}/`).off('value', store.state.GameView.lobbyListener);
    store.commit('gameView_rate', -1);
}

export async function getLobby(gameId:string, code:string){
    let lobbySnapshot = await realtime.ref('/lobby/'+code).get();
    if(lobbySnapshot.exists()){
        let v = lobbySnapshot.val()
        if(v && v.meta && v.meta.gameId == gameId){
            return v
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export async function getGameByLobbyCode(code:string){

    let lobbyRef = realtime.ref('lobby/' + code);
    let lobbySnapshot = await lobbyRef.once('value');

    if(lobbySnapshot.exists()){
        return lobbySnapshot.val();
    }else{
        return false;
    }
}

export async function getConnectedState(code:string,id:string|undefined,name:string|undefined){
    let identifier = id || name;
    let isConnected:boolean = false;
    let ref = 'lobby/' + code + '/players/' + identifier;
    
    if(identifier){
        await realtime.ref(ref).once('value').then((snapshot) => {
            isConnected = snapshot.exists();
        });
    }
    return isConnected;
}

export async function getPermissionState(code:string,id:string|undefined,name:string|undefined){
    let identifier = id || name;
    let isPermitted:{
        joined:boolean,
        banned:boolean,
    } = {
        joined:false,
        banned:false
    };

    if(identifier){
        let ref = 'lobby/' + code + '/permitted/' + identifier;
        await realtime.ref(ref).once('value').then((snapshot) => {
            if(snapshot.exists()){
                isPermitted.joined = true;
                isPermitted.banned = snapshot.val().banned;
            }
        });
    }

    return isPermitted;
}

export async function pingLobby(code:string,identifier:string){
    let player = `/lobby/${code}/players/${identifier}/`
    let updates:any = {
        lastPing:new Date().getTime()
    }
    let p = firebase.database().ref(player)
    if(p){
        p.update(updates);
    }
}