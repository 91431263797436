import firebase, { db } from "@/firebase"
import DocumentReference = firebase.firestore.DocumentReference
import CollectionReference = firebase.firestore.CollectionReference

export type WhereFilterOp = 
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in'
    | 'array-contains-any'
    | 'not-in';

// Manipulator
class GlobalManipulator{
    collection=(collection:string)=>new Collection(collection);
}


class CollectionBase{
    reference:CollectionReference;
    constructor(collection:CollectionReference){
        this.reference = collection;
    }
}
class Collection extends CollectionBase{
    get:Retriever;
    mw:any[] = []; // ()=>Promise<boolean>

    constructor(name:string){
        super(firebase.firestore().collection(name))
        this.get=new Retriever(this.reference);
    }

    doc=(id:string)=>this.reference.doc(id).get();
    remove=()=>new Remover(this.reference);
    update=()=>new Updater(this.reference);

    add=<T>(data:T):Promise<DocumentReference<firebase.firestore.DocumentData>>=>{
        return this.reference.add(data)
    }
}




class Retriever extends CollectionBase{
    documentID:string;
    document:any;

    constructor(collection:CollectionReference,document?:any){
        super(collection);
        this.reference = collection;
        this.document = document;
    }

    all=<T>()=>this.reference.get().then((snapshot:any)=>{
        let arr = snapshot.docs.map((doc:any) => {
            return {
                id:doc.id,
                ...doc.data()
            }
        }) as T[]
        return arr;
    }).catch(()=>{
        return [] as T[];
    })
    
    allWhere=<T>(checks:{
        row:string,comparitor:WhereFilterOp,value:string
    }[],nested?:{
        fieldName:string,
        where:{
            row:string,comparitor:WhereFilterOp,value:string
        }
    })=>{
        let query;
        for(let check of checks){
            query = (query||this.reference).where(check.row,check.comparitor,check.value)
        }

        if(query){
            return query.get().then((snapshot:any)=>{
                let arr = snapshot.docs.map((doc:any) => {
                    return {
                        id:doc.id,
                        ...doc.data()
                    }
                }) as T[]
                return arr;
            }).catch(()=>{
                return [] as T[];
            })
        }else{
            return [] as T[];
        }
    }

    // similar api
    where=<T>(check:{
        row:string,comparitor:WhereFilterOp,value:string|number|boolean
    },nested?:{
        collection:string,
        where:{
            row:string,comparitor:WhereFilterOp,value:string
        }
    }[],orderby?:{
        row:string,
        order:"desc"|"asc"
    })=>{
        return new Promise<T[]>(async(resolve,reject)=>{

            let query = this.reference.where(check.row,check.comparitor,check.value);
            if(orderby) {
                query = query.orderBy(orderby.row,orderby.order)
            }
            let snapshot = await query.get()

            let a:any[] = []; 
            for(let doc of snapshot.docs){
                if(nested){
                    let val:any = {
                        id:doc.id,
                        ...doc.data()
                    };

                    for(let n of nested){
                        let nestedkey:string = n.collection;
                        val[nestedkey] = await globalManip.collection(n.collection).get.where({
                            row:n.where.row,
                            comparitor:n.where.comparitor,
                            value:(doc as any)[n.where.value]
                        })
                    }

                    a.push(val);
                }else{
                    a.push({
                        id:doc.id,
                        ...doc.data()
                    })
                }
            }
            resolve(a as T[])
            
            //     let arr = snapshot.docs.map(async (doc:any) => {
            //         if(nested){
            //             let nestedkey = nested.collection;
            //             return {
            //                 id:doc.id,
            //                 [nestedkey]:await globalManip.collection(nested.collection).get.where({
            //                     row:nested.where.row,
            //                     comparitor:nested.where.comparitor,
            //                     value:doc[nested.where.value]
            //                 }),
            //                 ...doc.data()
            //             }
                        
            //         }else{
            //             return {
            //                 id:doc.id,
            //                 ...doc.data()
            //             }
            //         }
            //     }) as T[]
            // }).catch(err=>{
            //     resolve([])
            // })
        })
        
    }
}

class Remover extends CollectionBase{
    constructor(collection:CollectionReference){
        super(collection);
        this.reference = collection;
    }
}

class Updater extends CollectionBase{
    constructor(collection:CollectionReference){
        super(collection);
        this.reference = collection;
    }


}



const globalManip = new GlobalManipulator;
export default globalManip;