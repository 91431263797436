import baseapi from "./baseapi"
import {WhereFilterOp} from "./baseapi"
import firebase,{db, realtime} from "@/firebase"
import { v4 } from "uuid"
import { Lobby } from "./lobby"
import { User } from "./auth"
import { Card } from "./games"

export interface Playthrough{
    id:string,
    countdownStarted?:number,
    started:number,
    stopped?:number,
    gameId:string,
    timeElapsed:number,
    currentCard:number,
    atdoor:boolean,
    paused?:number,
    pauseSum:number,
    skippedTime:number,
    finished:number,
    hints:number,
    skips:number,
    cardStatus:{
        [id:string]:{
            id?:string,
            complete?:boolean,
            correct?:boolean,
            incorrect?:boolean,
            passedTime?:number,
            hinted_one?:boolean,
            hinted_two?:boolean,
            skipped?:boolean,
            distanceSkipped?:boolean
        }
    }
} 
export const PSEUDO:Playthrough = {
    id:'',
    started:new Date().getTime(),
    gameId:'',
    timeElapsed:1000,
    currentCard:0,
    atdoor:false,
    finished:0,
    pauseSum:0,
    skippedTime:0,
    hints:0,
    skips:0,
    cardStatus:{}
}
// BASE CHARACTERISTICS OF GAME WHEN CREATED
export const PLAYTHROUGH = {
    
}

// CREATE DESTROY
export async function createPlaythrough(code:string, gameId:string){
    await realtime.ref('/lobby/'+code+'/playthrough').set({
        id:v4(),
        started:0,
        gameId:gameId,
        timeElapsed:0,
        currentCard:0,
        atdoor:false,
        pauseSum:0,
        skippedTime:0,
        finished:0,
        hints:0,
    })
}

// COUNTDOWNS
export async function startPlaythroughCountdown(lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough').update({
        countdownStarted: new Date().getTime()
    })
}
export async function stopPlaythroughCountdown(lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/countdownStarted').remove()
}

// TIME CONTROLS
export async function startPlaythrough(lobby:Lobby){
    let started = await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/started').get()
    if(started.val() == 0){
        let started =  new Date().getTime()
        let id = lobby.playthrough?.id || v4();
        let game = await db.collection("playthroughs").doc(id).get()
        let lobbySize = 0;

        if(lobby.players){
            lobbySize = Object.entries(lobby.players).length;
        }

        game.ref.set({
            gameId:lobby.meta.gameId,
            meta:lobby.meta,
            started:started,
            finished:false,
            stopped:false,
            lobbySize,
        }, {merge:true})

        await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough').update({
            started
        })
    }
}
export async function stopPlaythrough(lobby:Lobby){
    let stopped = new Date().getTime()
    let id = lobby.playthrough?.id || v4();
    let game = await db.collection("playthroughs").doc(id).get()
    let lobbySize = 0;

    if(lobby.players){
        lobbySize = Object.entries(lobby.players).length;
    }
    
    game.ref.set({
        gameId:lobby.meta.gameId,
        meta:lobby.meta,
        progress:lobby.playthrough?.currentCard,
        finished:lobby.playthrough?.finished,
        skips:lobby.playthrough?.skips || 0,
        hints:lobby.playthrough?.hints || 0,
        pauseSum:lobby.playthrough?.pauseSum,
        started:lobby.playthrough?.started,
        lobbySize,
        stopped
    }, {merge:true})

    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough').update({
        stopped,
    })
}
export async function pausePlaythrough(lobby:Lobby){
    let paused = new Date().getTime()
    let id = lobby.playthrough?.id || v4();
    let game = await db.collection("playthroughs").doc(id).get()
    let lobbySize = 0;

    if(lobby.players){
        lobbySize = Object.entries(lobby.players).length;
    }
    
    game.ref.set({
        gameId:lobby.meta.gameId,
        meta:lobby.meta,
        progress:lobby.playthrough?.currentCard,
        finished:lobby.playthrough?.finished,
        skips:lobby.playthrough?.skips || 0,
        hints:lobby.playthrough?.hints || 0,
        pauseSum:lobby.playthrough?.pauseSum,
        started:lobby.playthrough?.started,
        lobbySize,
        paused
    }, {merge:true})

    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough').update({
        paused: paused
    })
}
export async function resumePlaythrough(lobby:Lobby){
    if(lobby.playthrough?.paused){
        let pauseDelta = new Date().getTime() - lobby.playthrough.paused
        let previousPauseDelta = (lobby.playthrough.pauseSum || 0) + pauseDelta;
        await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/').update({
            paused:0,
            pauseSum:previousPauseDelta
        })
    }
}
export async function addSkippedTime(lobby:Lobby, additionalTime:number){
    if(lobby.playthrough){
        let previousSkippedTime = (lobby.playthrough.skippedTime || 0) + additionalTime;
        await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/').update({
            skippedTime:previousSkippedTime
        })
    }
}

// SET CARD STATUS
export async function setCardStatus(lobby:Lobby, status:Playthrough['cardStatus'][0]){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/cardStatus/'+status.id).update(status)
}

export async function unlockNextCard(lobby:Lobby){

    if(lobby.playthrough?.cardStatus){
        let nextCardToOpen = Object.entries(lobby.playthrough?.cardStatus).length;
    
        await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/').update({
            currentCard: nextCardToOpen
        })
    }
}
export async function goToDoor(lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/').update({
        atdoor: true
    })
}

// HINTS
export async function tallyHint(lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/').update({
        hints: firebase.database.ServerValue.increment(1)
    })
}
export async function tallySkip(lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough/').update({
        skips: firebase.database.ServerValue.increment(1)
    })
}

// WIN LOSE
export async function finishPlaythrough(lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/playthrough').update({
        finished: new Date().getTime()
    })
}
// TIME SUBMISSION
export async function savePlaythrough(lobby:Lobby){
    let time = 0;
    if(lobby.playthrough?.finished && lobby.playthrough?.started){
        time = lobby.playthrough?.finished - lobby.playthrough?.started;
        if(lobby.playthrough?.pauseSum){
            time - lobby.playthrough?.pauseSum
        }
    }
    
    let players = -1;
    if(lobby.players) players = Object.entries(lobby.players).length

    let id = lobby.playthrough?.id || v4();
    let game = await db.collection("playthroughs").doc(id).get()
    
    game.ref.set({
        gameId:lobby.meta.gameId,
        meta:lobby.meta,
        progress:lobby.playthrough?.currentCard || 0,
        finished:lobby.playthrough?.finished || 0,
        stopped:lobby.playthrough?.stopped || 0,
        hints:lobby.playthrough?.hints || 0,
        pauseSum:lobby.playthrough?.pauseSum || 0,
        started:lobby.playthrough?.started || 0,
        time:time || 0,
        lobbySize:players || 0
    }, {merge:true})
}

// SAVE PLAYER HISTORY
export async function saveHistory(lobby:Lobby,complete?:boolean){
    let players = [];
    let playerIDs = [];
    if(lobby.playthrough){
        let history = await db.collection("history").doc(lobby.playthrough.id).get()
        if((!history || !history.exists)){
            if(lobby.players){
                for(let [id,p] of Object.entries(lobby.players)){
                    let host = false;
                    if(id == lobby.meta.host){
                        host = true;
                    }
                    playerIDs.push(id)
                    players.push({
                        id,
                        host,
                        name:p.name||"",
                        avatar:p.avatar||"",
                        picture:p.picture||""
                    })
                }
            }
            
            let time = 0;
            if(lobby.playthrough.finished && lobby.playthrough.started){
                time = lobby.playthrough.finished - lobby.playthrough.started - lobby.playthrough.pauseSum
            }
            history.ref.set({
                playerIDs,
                players,
                gameId:lobby.meta.gameId,
                playthrough:lobby.playthrough.id,
                time,
                complete:complete||false
            }, {merge:true})
        }
    }
}

export async function updateHistoryCompleteState(lobby:Lobby,complete:boolean){
    if(lobby.playthrough){
        let history = await db.collection("history").doc(lobby.playthrough.id).get()
        history.ref.set({
            complete:complete||false
        }, {merge:true})
    }
}

// LOAD PLAYER HISTORY
export async function getGameHistory(lobby:Lobby,user:User):Promise<firebase.firestore.DocumentData[] | undefined>{
    return new Promise((resolve,reject) => {
        db.collection("history").where('user','==',user.id).where('gameId','==',lobby.meta.gameId).get().then((snap)=>{
            if(snap && !snap.empty){
                let d:firebase.firestore.DocumentData[] = [];
                snap.docs.map(doc => {d.push(doc.data)})
                resolve(d)
            }else{
                resolve(undefined)
            }
        }).catch(e=>{
            reject(e)
        })
    })
}

export async function getAllHistory(user:User):Promise<firebase.firestore.DocumentData[]|undefined>{
    return new Promise((resolve,reject) => {
        db.collection("history").where('playerIDs','array-contains',user.id).get().then(async (snap)=>{
            if(snap && !snap.empty){
                let d:firebase.firestore.DocumentData[] = [];
                let len = snap.docs.length;
                let i=0;
                snap.docs.forEach(async doc => {
                    let data = doc.data()
                    let s = await db.collection("playthroughs").doc(data.playthrough).get()
                    let a = s.data();

                    if(s.exists && a) {
                        let g = await db.collection("games").doc(a.gameId).get();
                        let gd = g.data();
                        if(gd){
                            a = {
                                ...data,
                                ...a,
                                title:gd.title,
                                image:gd.image
                            }
                        }
                        d.push(a);
                    };

                    i++;
                    if(i == len){
                        d.sort((a,b) => (a.started > b.started) ? -1 : ((b.started > a.started) ? 1 : 0))
                        resolve(d);
                    }
                })
            }else{
                resolve(undefined)
            }
        }).catch(e=>{
            reject(e)
        })
    })
}

export async function getAllHistoryIncomplete(user:User):Promise<firebase.firestore.DocumentData[]|undefined>{
    return new Promise((resolve,reject) => {
        db.collection("history").where('playerIDs','array-contains',user.id).where('complete','==',false).get().then(async (snap)=>{
            if(snap && !snap.empty){
                let d:firebase.firestore.DocumentData[] = [];
                let len = snap.docs.length;
                let i=0;
                snap.docs.forEach(async doc => {

                    let data = doc.data()
                    if(!data.ignored || !data.ignored.includes(user.id)){
                        
                        let s = await db.collection("playthroughs").doc(data.playthrough).get()
                        let a = s.data();

                        if(s.exists && a) {
                            let g = await db.collection("games").doc(a.gameId).get();
                            let gd = g.data();

                            if(gd){
                                a = {
                                    ...data,
                                    ...a,
                                    title:gd.title,
                                    image:gd.image
                                }
                            }
                            d.push(a);
                        };
                    }

                    i++;
                    if(i == len){
                        d.sort((a,b) => (a.started > b.started) ? -1 : ((b.started > a.started) ? 1 : 0))
                        resolve(d);
                    }
                })
            }else{
                resolve(undefined)
            }
        }).catch(e=>{
            reject(e)
        })
    })
}

export async function ignoreIncompleteHistory(user:User){
    db.collection("history").where('playerIDs','array-contains',user.id).where('complete','==',false).get().then(async (snap)=>{
        if(snap && !snap.empty){
            snap.docs.forEach(async doc =>{
                let ignored:any[] = [];
                if(doc.data() && doc.data().ignored){
                    ignored += doc.data().ignored
                }
                if(!ignored.includes(user.id)){
                    ignored.push(user.id)
                }
                doc.ref.set({
                    ignored
                },{merge:true})
            })
        }
    })
}

// PLAYER FOCUS
export async function setPlaythroughFocus(user:User,lobby:Lobby,cardId?:string|null, context?:string|null, value?:string|null, result?:boolean|null){
    let update:any = {}
    if(cardId !== undefined) update.cardId = cardId
    if(context !== undefined) update.context = context
    if(value !== undefined) update.value = value
    if(result !== undefined) update.result = result
    await realtime.ref('/lobby/'+lobby.meta.code+'/players/'+user.id+'/focus/').update(update)
}
export async function clearPlaythroughFocus(user:User,lobby:Lobby){
    await realtime.ref('/lobby/'+lobby.meta.code+'/players/'+user.id+'/focus/').remove()
}