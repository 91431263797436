export default class Audio { 
  path:string;
  autoplay:boolean;
  seconds:number;

  constructor(path:string, autoplay:boolean, seconds:number){
    this.path = path || "";
    this.autoplay = autoplay || false;
    this.seconds = seconds || 10;
    this.spawn();
  }

  spawn(){
    let a = document.createElement('audio');
    a.autoplay = this.autoplay;
    a.src = this.path;
    a.style.display = "none";

    document.body.appendChild(a)
    setTimeout(()=>{
      a.remove();
    },this.seconds * 1000)
  }
}