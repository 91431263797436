// user-module.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {Lobby} from "@/firebase/api/lobby"
import {Card, Game} from "@/firebase/api/games"
import Router from "@/router"
import Store from "@/store"
import {Playthrough, PSEUDO} from "@/firebase/api/playthrough"
import { pingLobby } from "@/firebase/api/lobby"

export interface Cards{
    focus?:number|string,
    data?:Card[]
}
export interface Door{
    description:string,
    correct:boolean,
    incorrect:boolean,
    hinted_one:boolean,
    hinted_two:boolean,
    hint_one:string,
    hint_two:string,
    passedTime:number,
}

@Module
export default class GameView extends VuexModule {

    game:Game|boolean = false;
    lobby:any = false;
    door:Door|boolean = false;
    cards:Cards = {};
    currentFocus:Card|boolean|string = false;
    playthrough:Playthrough = PSEUDO;
    devView:boolean = false;
    entryCleared:boolean = false;
    lobbyListener:Function;
    activityWindows:boolean = true;
    fakiePresent:boolean = false;
    rating:number = -1;

    @Mutation
    setDevView(state:boolean){
        this.devView = state;
    }

    @Mutation
    gameView_rate(rating:number){
        this.rating = rating;
    }


    @Mutation
    gameView_setGame(game:Game){
        this.game = game;
    }

    @Mutation
    gameView_setLobby(lobby:Lobby){
        this.lobby = lobby;
    }

    @Mutation
    gameView_resetPlaythrough(){
        this.playthrough = PSEUDO;
        if(!this.lobby){
            this.lobby = {
                meta:{
                    code:"PRVW",
                    gameId:"1234",
                    created:new Date().getTime(),
                    host:'You',
                },
            }
        }
        if(!this.lobby.playthrough){
            this.lobby.playthrough = PSEUDO;
        }
    }
    
    @Mutation
    gameView_addFakePlayer(){
        this.fakiePresent = true;
    }

    @Mutation
    gameView_removeFakePlayer(){
        this.fakiePresent = false;
    }

    @Mutation
    gameView_setPlaythrough(playthrough:Playthrough){
        if(!this.lobby) this.lobby = {playthrough:PSEUDO};
        this.lobby.playthrough = playthrough;
    }

    @Mutation
    gameView_pingLobby(){
        clearTimeout(timeoutTrigger);
        triggerLobbyPing(this.lobby.meta.code, Store.state.Auth.user.id || Store.state.Auth.user.name);
    }
    
    @Mutation
    gameView_leaveLobby(){
        this.lobby = false;
        clearTimeout(timeoutTrigger);
    }

    @Mutation
    gameView_setCards(cards:Card[]){
        if(this.cards){
            this.cards.data = cards;
        }
    }

    @Mutation
    gameView_setCardFocus(focus:number|string){
        let newFocusSet:boolean = false;
        if(focus == 'door'){
            this.currentFocus = 'door';
            newFocusSet = true;
            Router.push({ params: {page:focus}})
        }else if(this.cards){
            this.cards.focus = focus;
            Router.push({ params: {page:focus}})
            if(this.cards.data){
                for(let card of this.cards.data){
                    if(card.index == focus){
                        this.currentFocus = card;
                        newFocusSet = true;
                        Router.push({ params: {page:focus}})
                    }
                }
            }
        }
        if(!newFocusSet){
            this.currentFocus = 'empty';
        }
    }

    @Mutation
    setCardStatus(data:{
        id:string,
        complete?:boolean,
        correct?:boolean,
        incorrect?:boolean,
        hinted_one?:boolean,
        hinted_two?:boolean,
        skipped?:boolean,
        passedTime?:number,
    }){ 
        if(!this.playthrough.cardStatus[data.id]){
            this.playthrough.cardStatus[data.id] = {correct:false};
        }
        this.playthrough.cardStatus[data.id] = {
            complete:data.complete || this.playthrough.cardStatus[data.id].complete,
            correct:data.correct || this.playthrough.cardStatus[data.id].correct,
            incorrect:data.incorrect || this.playthrough.cardStatus[data.id].incorrect, 
            hinted_one:data.hinted_one || this.playthrough.cardStatus[data.id].hinted_one,
            hinted_two:data.hinted_two || this.playthrough.cardStatus[data.id].hinted_two,
            passedTime:data.passedTime || this.playthrough.cardStatus[data.id].passedTime,
        }
    }

    @Mutation
    unlockNextCard(){
        this.playthrough.currentCard++; 
    }
    
    @Action({ commit: 'gameView_setCardFocus' })
    nextCard(){
        return (this.currentFocus as Card).index + 1;
    }

    @Mutation
    goToDoor(){
        this.playthrough.atdoor = true;
        this.currentFocus = 'door';
        Router.replace({ params: {page:'door'}})
    }

    @Mutation
    gameView_setLobbyListener(listener:Function){
        this.lobbyListener = listener
    }

    
    @Mutation
    gameView_toggleActivityWindows(){
        this.activityWindows = !this.activityWindows;
    }
}

function sortCards(cards:Card[]){
    cards.sort((a, b) => (a.index > b.index) ? 1 : -1)
}

let timeoutTrigger:any = setTimeout(()=>{},1000);
function triggerLobbyPing(code:string,userIdentifier:string){
    if(code){
        pingLobby(code, userIdentifier);
        clearTimeout(timeoutTrigger);

        if(Store.state.GameView.lobby.meta.code == code){
            timeoutTrigger = setTimeout(()=>{triggerLobbyPing(code,userIdentifier)},5000);
        }
    }
}