import baseapi from "./baseapi"
import {WhereFilterOp} from "./baseapi"
import firebase, {auth, db} from "@/firebase"

export interface Game{
    id:string,
    title:string,
    shortdescription:string,
    description:string,
    minutes:number,
    location?:string,
    difficulty:0|1|2|3,
    maxsize:number|20,
    image:string,
    rating:number,
    doorPasswordClue:string,
    doorPasswordEnabled:boolean,
    doorPassword:string,
    deleting?:boolean,
    owner?:boolean,
    free?:boolean,
    patching?:boolean,
    price?:number,
    features:{
        maps:boolean,
        web:boolean,
        room:boolean
    }
} 

export const TUTORIAL:Game = {
    id:'tutorial',
    title:'Tutorial',
    shortdescription:'Learn the basics on how to play gochase.me',
    description:'Learn the basics on how to play gochase.me',
    minutes:1000,
    difficulty:0,
    maxsize:20,
    image:'',
    rating:1,
    doorPasswordClue:'string',
    doorPasswordEnabled:true,
    doorPassword:'1234',
    free:true,
    patching:false,
    price:0,
    features:{
        maps:true,
        web:true,
        room:true
    }
}
// BASE CHARACTERISTICS OF GAME WHEN CREATED
export const BASE = {
    title:'',
    shortdescription:'',
    description:'',
    minutes:60,
    location:'',
    difficulty:0,
    maxsize:20,
    image:'',
    rating:5,
    doorPasswordClue:'',
    doorPasswordEnabled:false,
    doorPassword:'',
    features:{
        maps:false,
        web:false,
        room:false
    }
}

export interface Question{
    id:string,
    question:string,
    acceptedValues:string,
    answer?:string,
    incorrect?:boolean
}

export interface Attribution{
    id:string,
    resource:string,
    author:string,
    license:string,
    changes:1|0
}

export interface F{
    name:string,
    fileObject:File,
    type:string,
    class:string,
    fullType:string,
    data:any,
    contentEncoding: string,
    uploadPerc?:number,
    uploadError?:string,
    preview?:string,
    location?:string,
}

export interface Card{
    id:string,
    index:number,
    parent:string,
    image:string,
    videos:string,
    title:string,
    description:string,
    hint_one:string,
    hint_two:string,
    hint?:string,
    uploads?:{
        name:string,
        location:string
    }[],
    attributions?:Attribution[],
    questions:Question[],
} 

export async function deleteGame(id:string):Promise<void>{
    return new Promise((resolve,reject)=>{
        ensureAdmin().then(success=>{
            if(success){
                baseapi.collection('games')
                .doc(id).then(snap=>{
                    if(snap.exists && snap.data()){
                        snap.ref.delete().then(()=>{
                            resolve()
                        }).catch((e)=>{
                            reject(e)
                        })
                    }
                }).catch(e=>{
                    reject(e)
                })
            }else{
                reject("not admin")
            }
        }).catch(e=>{
            throw Error(e)
        })
    })
}

async function ensureAdmin():Promise<boolean>{
    return new Promise((resolve,reject)=>{
        let user = auth.currentUser?.email
        if(user){
            baseapi.collection('developers').doc(user).then(snap=>{
                if(snap.exists){
                    resolve(true)
                }else{
                    resolve(false)
                }
            }).catch(e=>{
                reject(e)
            })
        }
    })
}

export async function allGames(){
    return await baseapi.collection('games').get.all<Game>();
}

export async function allReadyGames(){
    return await baseapi.collection('games').get.where<Game>({
        row:'ready',
        comparitor:'==',
        value:true
    })
}

export async function allCardsWhere(checks:{
        row:string,comparitor:WhereFilterOp,value:string
    },nested?:{
        collection:string,
        where:{
            row:string,comparitor:WhereFilterOp,value:string
        }
    }[],orderby?:{
        row:string,
        order:"desc"|"asc"
    }){
    return await baseapi.collection('cards').get.where(checks,nested,orderby);
}

export async function updateCardWithID(id:string){
    return await baseapi.collection('cards').doc(id)
}

export async function getGame(id:string){
    return baseapi.collection('games').doc(id);
}
export async function getGameData<Game>(id:string){
    let doc:firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = await baseapi.collection('games').doc(id)
    let attributions:any = await baseapi.collection('attributions').get.where({
        row:'cardid', comparitor:'==',value:doc.id
    })
    let game:any = {};
    if(doc.data()){
        game = {
            id:doc.id,
            ...doc.data(),
            attributions:attributions
        }
    }    
    return game as Game;
}

export async function getPurchasedState(gameId:string,userId:string){
    return firebase.firestore().collection('purchases').where('gameId','==',gameId).where('userId','==',userId).get();
}

export async function getUserPurchasedStates(userId:string){
    let snap = await firebase.firestore().collection('purchases').where('userId','==',userId).get();
    return snap.docs.map(doc => doc.data());
}

export async function newAttribution(data:{
    cardid:string,
    resource:string,
    author:string,
    license:string,
    changes:1|0
}){
    let q = await baseapi.collection('attributions').add(data);
    return q.get();
}
export async function getAttribution(id:string){
    return await baseapi.collection('attributions').doc(id)
}


export async function newQuestion(data:{
    cardid:string,
    question:string,
    acceptedAnswers:string[]
}){
    let q = await baseapi.collection('questions').add(data);
    return q.get();
}

export async function getQuestion(id:string){
    return await baseapi.collection('questions').doc(id)
}

export async function addCard(data?:{
    index?:number,
    parent?:string,
    image?:string,
    videos?:string,
    title?:string,
    description?:string,
    hint?:string,
}){
    return await baseapi.collection('cards').add({
        index:0,
        parent:'',
        image:'',
        videos:'',
        title:'',
        description:'',
        hint:'',
        ...data
    })
}

export async function moveCardToIndex(card:Card, desiredIndex:number){
    return new Promise(async (resolve,reject)=>{
        if(desiredIndex < 0) reject("desired index < 0");
        
        let currentIndex = card.index;
        let destinationSpot = await firebase.firestore().collection('cards').where('index','==',desiredIndex).where('parent','==',card.parent).get()
        let currentSpot = await firebase.firestore().collection('cards').where('index','==',currentIndex).where('parent','==',card.parent).get()

        if(destinationSpot.docs.length > 0 && currentSpot.docs.length > 0){
            let occupier = destinationSpot.docs[0]
            await occupier.ref.update({
                index:currentIndex
            })
            await currentSpot.docs[0].ref.update({
                index:desiredIndex
            })

            resolve({
                card,
                previousOccupier:occupier.data(),
                oldIndex:card.index,
                newIndex:desiredIndex
            })
        }else{
            reject("could not move")
        }
    })
}

export async function removeCard(card:Card):Promise<{
    action:string,
    success:boolean,
    card:Card,
    error?:string,
    subQuery?:Object
}>{
    return new Promise(async (resolve,reject)=>{
        let cardRef = await firebase.firestore().collection('cards').doc(card.id)
        if(cardRef){
            cardRef.delete().then(()=>{
                // once removed, we must now clean up indexes
                sortCards(card.parent).then((data)=>{
                    resolve({
                        action:'remove',
                        success:true,
                        card:card,
                        subQuery:data
                    })
                }).catch(e=>{
                    reject({
                        action:'remove',
                        success:false,
                        card:card,
                        error:e
                    })
                })
                
            }).catch(e=>{
                reject({
                    action:'remove',
                    success:false,
                    card:card,
                    error:e
                })
            })
        }else{
            reject("No cards match the provided details")
        }
    })
}

export async function sortCards(gameId:string):Promise<{
        action:'sortCards',
        success:boolean,
        gameId:string,
        error?:string,
    }>{
    return new Promise(async (resolve,reject)=>{
        let allGameCards = await firebase.firestore().collection('cards').where("parent","==",gameId).orderBy('index').get();
        let allGameCardsDocs = allGameCards.docs;

        let focusIndex = 0;
        try{
            for(let cardDoc of allGameCardsDocs){
                let indexDelta = cardDoc.data().index - focusIndex;
                if(indexDelta !== 0){
                    await cardDoc.ref.update({
                        index:focusIndex
                    })
                }
                focusIndex++;
            }
            resolve({
                action:'sortCards',
                success:true,
                gameId:gameId
            })
        }catch(e){
            reject({
                action:'sortCards',
                success:false,
                gameId:gameId,
                error:e
            })
        }
        
    })
}

export async function purchase(gameId:string, userId:string){
    return baseapi.collection('purchases').add({
        userId:userId,gameId:gameId
    })
}

export async function rate(gameId:string, user:string, rating:number){
    let id = `${gameId}_${user}`;
    let game = await db.collection('ratings').doc(id).get()
    game.ref.set({
        gameId,
        user,
        rating
    }, {merge:true})
}

export async function getRatingData(gameId:string){
    return new Promise<{value:number,number:number}>((resolve,reject)=>{
        db.collection('ratings').where('gameId','==',gameId).get().then(data=>{
            if(!data.empty){
                let rN = 0;
                let rT = 0;
                data.docs.forEach((doc:any) => {
                    let d = doc.data();
                    rN++;
                    rT+=d.rating;
                })
                resolve({
                    value:rT/rN,
                    number:rN
                })
            }else{
                resolve({
                    value:-1,
                    number:0
                })
            }
        })
    })
  }