
import { Options, Vue } from 'vue-class-component';

import Ripple from '@/components/ux/Ripple.vue'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import IconButton from "@/components/elements/IconButton.vue"
import CardItem from "@/components/elements/Card.vue"
import Room from "@/components/room/Room.vue"
import LandingHost from "@/components/elements/landing_host.vue"
import LandingInvite from "@/components/elements/landing_invite.vue"
import LandingPlay from "@/components/elements/landing_play.vue"
import { Card } from '@/firebase/api/games';
import MovingIcon from "movingicons"

@Options({  
  components: {
    Header,
    Footer,
    IconButton,
    CardItem,
    Room,
    MovingIcon,
    Ripple,
    LandingHost,
    LandingInvite,
    LandingPlay
  },
})
export default class Home extends Vue {

  keyFound:boolean = false;
  chestOpen:boolean = false;
  arrowHidden:boolean = false;
  landing:number = -2;
  invite:number = -2;
  play:number = -2;

  demoCard:Card = {
    id:"1234",
    index:0,
    parent:'',
    image:'tt.jpg',
    videos:"",
    title:"You have arrived!",
    description:'You find yourself within the heart of Tokyo, overwhelmed by the noise and chaos of the busy streets. You look back at the note left by your Father: "From steel, I reach to the heavens. We share our birth year."',
    hint_one:"Go to Wikipedia to find the answer",
    hint_two:"",
    hint:"Go to Wikipedia to find the answer",
    questions:[
      {
        id:'1',
        question:"What year was your father born?",
        acceptedValues:"1958",
        answer:"1958",
        incorrect:false
      }
    ],
  } 

  roomData = {
    tiles:{
      n:{
        door:{
          facing:"s",
          locked:!this.keyFound,
          opened:false,
        }
      },
      m:{
        
      },
      nw:{},
      s:{},
      e:{
        chest:{
          facing:"w",
          locked:false,
          opened:this.chestOpen,
          contents:[{
            key:true
          }],
          onOpen:()=>{
            setTimeout(()=>{
              this.roomData.tiles.n.door.locked = false;
            },1000)
          }
        }
      }
    }
  }

  mounted(){
    document.addEventListener('scroll', this.scrolled);
    setTimeout(()=>{
      this.chestOpen=true;
    },1500);
  }
  unmounted(){
    document.removeEventListener('scroll', this.scrolled)
  }

  scrolled(){
    let d = this.$refs.header as HTMLElement;
    let oneTop = document.getElementById('one')?.offsetTop;
    let twoTop = document.getElementById('two')?.offsetTop;
    let threeTop = document.getElementById('three')?.offsetTop;

    if(document.documentElement.scrollTop > (window.innerHeight /2)){
      d.classList.add('shadowed')
    }else{
      d.classList.remove('shadowed')
    }

    if(document.documentElement.scrollTop > 10){
      this.arrowHidden = true;
    }else{
      this.arrowHidden = false;
    }

    if(oneTop && this.landing<-1 && document.documentElement.scrollTop+(window.innerHeight/2) > oneTop) this.landing=-1;
    if(twoTop && this.invite<-1 && document.documentElement.scrollTop+(window.innerHeight/2) > twoTop) this.invite=-1;
    if(threeTop && this.play<-1 && document.documentElement.scrollTop+(window.innerHeight/2) > threeTop) this.play=-1;

  }


  scrolldown(){
    document.documentElement.scrollTo({
      top:window.outerHeight/1.33,
      behavior:"smooth"
    })
  }
}
