// user-module.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { User } from "@/firebase/api/auth"
import {getCookie,setCookie} from "@/scripts/cookie"
import { v4 } from 'uuid';
import Store from "@/store"

@Module
export default class AuthModule extends VuexModule {

    avatars:string[] = ['alice','brigette','chad','elvis','george','john','kelly','madonna','miranda','paul','ringo','sarah'];

    user:User = {
        unset:true,
        guest:true,
        signedOut:true,
    }

    awaiter:{
        promise?:Promise<any>,
        resolve:Function,
        reject:Function
    };

    @Mutation
    async userAwait(){
        let r1:Function, r2:Function;
        let p = new Promise((resolve,reject)=>{
            r1=resolve,r2=reject;
            this.awaiter = {
                resolve:r1,
                reject:r2
            }
        })
        this.awaiter.promise = p;
    }

    @Mutation
    async setUser(user:User){
        this.user = {
            unset:false,
            ...user
        };
        if(this.awaiter.resolve) this.awaiter.resolve();
    }

    @Mutation
    async setDev(isDev:boolean){
        (this.user as User).developer = isDev;
    }

    @Mutation
    setGuest(name:string){
        if(Store.state.Utils.cookies){
            let c:{
                name:string,
                id:string,
                signedOut:boolean,
                avatar:string,
                guest:boolean
            } = JSON.parse(getCookie('guest') as string);
            if(!c){
                c = {
                    name:name,
                    id:this.user.id || v4(),
                    signedOut:true,
                    avatar:this.user.avatar|| this.avatars[Math.round(Math.random()*this.avatars.length)],
                    guest:true
                }
            }
            c.name = name;
            setCookie("guest",JSON.stringify(c),1);
        }
        this.user.guest = true;
        this.user.name = name;
    }
    
}