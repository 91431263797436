import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.styles && _ctx.fixedTransform)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
    solid:true,
    floor:_ctx.name=='floor'
  }),
        style: _normalizeStyle({
    ..._ctx.fixedTransform,
  })
      }, [
        _createElementVNode("div", {
          class: "transformer",
          ref: "transformer",
          style: _normalizeStyle({
      ...this.transform,
      ..._ctx.mutatableTransform
    })
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({
        wall:true,
        south:true
      }),
            style: _normalizeStyle({
        height:`${_ctx.height*100}px`,
        top:`${(-_ctx.height*50)+(_ctx.depth*100)}px`,
        transform:`rotateX(-90deg) translateY(-${_ctx.height*50}px)`,
        background:_ctx.color
      })
          }, null, 4),
          _createElementVNode("div", {
            class: _normalizeClass({
        wall:true,
        north:true
      }),
            style: _normalizeStyle({
        height:`${_ctx.height*100}px`,
        top:`${-_ctx.height*50}px`,
        transform:`rotateX(-90deg) translateY(-${_ctx.height*50}px)`,
        background:_ctx.color
      })
          }, null, 4),
          _createElementVNode("div", {
            class: _normalizeClass({
        wall:true,
        top:true,
      }),
            style: _normalizeStyle({
        transform:`rotateY(-90deg) translateX(${_ctx.height*100}px) rotateY(90deg)`,
        background:_ctx.color
      })
          }, null, 4),
          _createElementVNode("div", {
            class: _normalizeClass({
        wall:true,
        bottom:true
      }),
            style: _normalizeStyle({
        transform:`rotateY(-90deg) rotateY(90deg)`,
        background:_ctx.color
      })
          }, null, 4),
          _createElementVNode("div", {
            class: _normalizeClass({
        wall:true,
        west:true
      }),
            style: _normalizeStyle({
        width:`${_ctx.height*100}px`,
        background:_ctx.color
      })
          }, null, 4),
          _createElementVNode("div", {
            class: _normalizeClass({
        wall:true,
        east:true
      }),
            style: _normalizeStyle({
        width:`${_ctx.height*100}px`,
        background:_ctx.color
      })
          }, null, 4)
        ], 4)
      ], 6))
    : _createCommentVNode("", true)
}