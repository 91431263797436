
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import IconButton from "@/components/elements/IconButton.vue"

@Options({
  components:{
    MovingIcon,
    Ripple,
    IconButton
  },
  emits:[]
})
export default class TutorialPopup extends Base { 
  
  @Prop({}) header:string
  @Prop({}) helloThere:string
  @Prop({}) settings:string
  @Prop({}) subheader:string
  @Prop({}) body:string
  @Prop({}) nextText:string
  @Prop({}) link:string
  @Prop({}) linkText:string
  @Prop({}) stage:number
  @Prop({}) max:number
  @Prop({}) tick:boolean

  mounted(){

  }
}
