
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"

import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import Navigation from '@/components/layout/Navigation.vue'
import MovingIcon from "movingicons"

@Options({  
  components: {
    Header,
    IconButton,
    MovingIcon,
    Navigation,
  },
})
export default class PolicyHome extends Base {
 
}
