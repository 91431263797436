
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import IconButton from "../elements/IconButton.vue"
import Account from "../elements/Account.vue"
 
@Options({
  components:{
    IconButton,
    Account
  },
  emits:[]
})
export default class Header extends Base { 

  toBrowse(){
    this.$router.push({path:'/app/'})
  }
  
}
