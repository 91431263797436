
import Base, { Options, Prop } from '@/components/BaseComponent.vue';

import Header from '@/components/layout/Header.vue'
import Account from "@/components/elements/Account.vue"
import IconButton from "@/components/elements/IconButton.vue"
import GamePreview from "@/components/elements/GamePreview.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import TextInput from "@/components/elements/TextInput.vue"
import HistoryPopup from "@/components/elements/HistoryPopup.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import MovingIcon from "movingicons"
import { Game, getGame } from '@/firebase/api/games';
import { getGameByLobbyCode, Lobby } from '@/firebase/api/lobby';
import { getAllHistoryIncomplete } from '@/firebase/api/playthrough';

@Options({  
  components: { 
    Header,
    IconButton,
    Account,
    TextInput,
    GamePreview,
    MovingIcon,
    LoadSpinner,
    HistoryPopup
  },
})
export default class Join extends Base {

  accountOptions:{
    active:number,
    options:{
      text:string,
      icon:string,
      sortFunction?:Function
    }[]
  } = {
    active:0,
    options:[
      {
        text:'Guest',
        icon:'join',
      },
      {
        text:'Liam Widdop',
        icon:'join',
      }
    ]
  }

  loggedIn:boolean=false;
  game:boolean|Game=false;
  searching:any = false;
  historyFields:any[] = [];
  lobby:any;
  loading:boolean = true;

  mounted(){
    if(this.$store.state.GameView.lobby?.meta?.code){
      this.searchByCode(this.$store.state.GameView.lobby.meta.code);
    }
    setTimeout(()=>{
      this.loading = false;
    },250)
  }

  updateName(nameEvent:InputEvent){
    let nameTarget = nameEvent?.target as HTMLInputElement;
    let name = nameTarget.value;
    this.$store.commit("setGuest",name);
  }

  async search(code:InputEvent){
    let input = code?.target as HTMLInputElement;
    let codeVal = input.value.trim().toUpperCase()
    this.searchByCode(codeVal)
  }

  async searchByCode(code:string){
    this.game = false;
    this.searching = true;
    this.lobby = await getGameByLobbyCode(code);
    if(this.lobby !== false && this.lobby.meta){
      this.lobby = this.lobby as any;
      let g = await getGame(this.lobby.meta.gameId);
      this.game = g.data() as Game;
    }
    this.searching = false;
  }

  openGame(){
    let id = Math.round(Math.random()*1000);
    this.$router.push({ path:`/app/join/${this.lobby.meta.gameId}/${this.lobby.meta.code}`});
  }
}
