import tween from '@tweenjs/tween.js'
export interface TweenerInterface{
    [millisecond:number]:{
        [transform:string]:string
    }
}
export default function Tweener(value:any,sequence:TweenerInterface){
    let steps = Object.entries(sequence);
    for(let i=0;i<steps.length;i++){
        setTimeout(()=>{
            let deltaTime = 500
            if(steps[i+1]) {
                deltaTime = parseInt(steps[i+1][0]) - parseInt(steps[i][0]);
            }

            new tween.Tween({value})
            .to({value:steps[i][1]}, deltaTime)
            .easing(tween.Easing.Quadratic.InOut)
            .onUpdate((update)=>{
                value = update.value
            }) 
            .start()
        },parseInt(steps[i][0]))
    }
}