
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import PayPalButton from "@/components/elements/PayPalButton.vue"
import Countdown from "@/components/elements/Countdown.vue"
import Account from "@/components/elements/Account.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import randomGen from "./randomGenerator"  
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import MovingIcon from "movingicons"
import LoginButton from "@/components/elements/LoginButton.vue"

import { Game, getGameData, getPurchasedState } from "@/firebase/api/games"
import { createLobby } from "@/firebase/api/lobby"

@Options({  
  components: {
    Header,
    IconButton,
    Countdown,
    Account,
    MovingIcon,
    LoadSpinner,
    LazyImage,
    PayPalButton,
    LoginButton
  },
})
export default class LobbyGenerator extends Base {
  game:Game|boolean = false;
  checkingGameExists:boolean = true;
  checkingOwnership:boolean = false;
  isOwner:boolean = false;

  mounted(){
    this.init();
  }

  async init(){
    await this.getGame();
    await this.checkIfOwner();
    await this.createLobby();
  }

  async getGame(){
    this.game = await getGameData(this.$route.params.id as string) as Game;
    this.checkingGameExists = false;
  }

  async checkIfOwner(){
    this.checkingOwnership = true;
    this.game = this.game as Game;
    if(!this.game.id) return;
    let purchases = await getPurchasedState(this.game.id,this.$store.state.Auth.user.id);
    this.checkingOwnership = false;
    this.isOwner = !purchases.empty;
    
    if(this.game.free){
      this.isOwner = true;
    }
  }

  async createLobby(){
    this.game = this.game as Game;
    if(!this.game.id) return;
    if(!this.isOwner) return;
    createLobby(this.game.id, this.$store.state.Auth.user).then(async (lobby)=>{
      if(lobby){
        this.$router.replace({path:`/app/lobby/${lobby.meta.gameId}/${lobby.meta.code}`})
      }else{
        alert("No lobby data was created");
      }
    });
  }

  navToBrowse(){
    this.$router.replace({path:'/app'})
  }
  navToGame(){
    this.game = this.game as Game;
    this.$router.replace({path:`/app/store/${this.game.id}`})
  }
}
