import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4ef6db00")
const _hoisted_1 = { class: "loadingBar" }
const _hoisted_2 = { class: "circleHalf right" }
const _hoisted_3 = { class: "circleHalf left" }
const _hoisted_4 = { class: "icon" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovingIcon = _resolveComponent("MovingIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    percentageSpinner:true,
    fail: _ctx.fail
  })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "bar",
          style: _normalizeStyle(`transform:rotate(${-180 + (Math.min(180,360 * (_ctx.percentage)))}deg)`)
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.percentage > 0.5)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "bar",
              style: _normalizeStyle(`transform:rotate(${360 * (_ctx.percentage)}deg)`)
            }, null, 4))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MovingIcon, {
        name: _ctx.percentage<1?'upload':'tick',
        autoplay: true,
        loop: true
      }, null, 8, ["name"])
    ])
  ], 2))
}