// user-module.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {eraseCookie, setCookie} from "@/scripts/cookie"

@Module
export default class UtilsModule extends VuexModule {

    cookies:boolean|undefined = undefined;
    PWAPrompt:any = undefined;

    @Mutation
    async setCookiePermission(condition:boolean){
        this.cookies = condition;
        if(this.cookies){
            setCookie('_c','true',30);
        }else{
            eraseCookie('_c');
        }
    }

    @Mutation
    setPWAPrompt(prompt:any){
        this.PWAPrompt = prompt;
    }

    @Mutation
    triggerPWAPrompt(open:boolean){
        if(this.PWAPrompt){
            this.PWAPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.PWAPrompt.userChoice.then((choiceResult:any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }else{
            alert("Sorry, it appears PWA is not currently active.");
        }
    }

}