import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-722bd968")
const _hoisted_1 = {
  key: 0,
  class: "roomLimiter"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Solid = _resolveComponent("Solid")!
  const _component_Door = _resolveComponent("Door")!
  const _component_Chest = _resolveComponent("Chest")!

  return (_openBlock(), _createElementBlock("div", {
    class: "roomContainer",
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.beginDrag && _ctx.beginDrag(...args))),
    onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.beginDrag && _ctx.beginDrag(...args))),
    onTouchmove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.drag && _ctx.drag(...args)))
  }, [
    (_ctx.properties)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass({
        room:true
      }),
            style: _normalizeStyle({
        transform: 
        `rotateX(${
          _ctx.rotation.x - _ctx.deltaRotation.x
        }deg) rotateZ(${
          _ctx.rotation.z - _ctx.deltaRotation.z
        }deg) translateZ(-50px)`
      })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties.tiles, (tile, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({solid:true,[index]:true})
              }, [
                _createVNode(_component_Solid, {
                  x: 0,
                  y: 0,
                  z: -0.1,
                  height: 0.1,
                  width: 1,
                  depth: 1,
                  color: "#fff"
                }, null, 8, ["z", "height"]),
                (tile.door)
                  ? (_openBlock(), _createBlock(_component_Door, {
                      key: 0,
                      facing: tile.door.facing,
                      unlocked: !tile.door.locked,
                      opened: tile.door.opened,
                      fixOpen: _ctx.escaped,
                      onClick: tile.door.click,
                      onOpen: ($event: any) => (_ctx.open(tile.door))
                    }, null, 8, ["facing", "unlocked", "opened", "fixOpen", "onClick", "onOpen"]))
                  : _createCommentVNode("", true),
                (tile.chest)
                  ? (_openBlock(), _createBlock(_component_Chest, {
                      key: 1,
                      facing: tile.chest.facing,
                      unlocked: !tile.chest.locked,
                      opened: tile.chest.opened,
                      fixOpen: _ctx.escaped,
                      onClick: tile.chest.click,
                      onOpen: ($event: any) => (_ctx.open(tile.chest))
                    }, null, 8, ["facing", "unlocked", "opened", "fixOpen", "onClick", "onOpen"]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 256))
          ], 4)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}