import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Solid = _resolveComponent("Solid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({lock:true,vanish:this.vanish}),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.lockCheck && _ctx.lockCheck(...args)), ["stop"])),
    style: _normalizeStyle({
    transform: `rotateY(${_ctx.lockTransform?.rotateZ}deg) rotateX(${_ctx.lockTransform?.rotateX}deg) translateZ(5px) translateY(50px) translateX(10px)`,
    ['transform-origin']:`50%  305px 50px`,
  })
  }, [
    _createVNode(_component_Solid, {
      x: -0.1,
      y: -0.435,
      z: 0.05,
      height: 0.15,
      width: 0.2,
      depth: 0.1,
      color: "black"
    }, null, 8, ["x", "y", "z", "height", "width", "depth"]),
    _createElementVNode("div", {
      class: "boltGroup",
      style: _normalizeStyle({
      transform: `rotateY(${_ctx.boltTransform?.rotateZ}deg) `,
      ['transform-origin']:`50% 100% 20px`,
    })
    }, [
      _createVNode(_component_Solid, {
        x: -0.17,
        y: -0.425,
        z: 0.2,
        height: 0.1,
        width: 0.05,
        depth: 0.05,
        color: "black"
      }, null, 8, ["x", "y", "z", "height", "width", "depth"]),
      _createVNode(_component_Solid, {
        x: -0.05,
        y: -0.425,
        z: 0.12,
        height: 0.15,
        width: 0.05,
        depth: 0.05,
        color: "black"
      }, null, 8, ["x", "y", "z", "height", "width", "depth"]),
      _createVNode(_component_Solid, {
        x: -0.11,
        y: -0.425,
        z: 0.275,
        height: 0.05,
        width: 0.15,
        depth: 0.05,
        color: "black"
      }, null, 8, ["x", "y", "z", "height", "width", "depth"])
    ], 4)
  ], 6))
}