
import { Options, Vue } from 'vue-class-component';
import IconButton from "@/components/elements/IconButton.vue"
import TextInput from "@/components/elements/TextInput.vue"
import MovingIcon from "movingicons"

@Options({  
  components: {
    IconButton,
    MovingIcon,
    TextInput
  },
})
export default class Cookie extends Vue {

}
