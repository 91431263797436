
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import { Player } from "@/firebase/api/lobby";
import MovingIcon from "movingicons"
import { setPlaythroughFocus, clearPlaythroughFocus } from "@/firebase/api/playthrough"
import Store from "@/store"

@Options({
  components:{
    MovingIcon
  },
  emits:[]
})
export default class ActivityWindow extends Base { 
  @Prop({}) player:Player
  position:{
    left:string,
    top:string
  }={
    left:'',
    top:''
  }
  focusEl:HTMLElement;
  hide:boolean = true;
  dataFocus:string = '';

  @Watch('$store.state.GameView.currentFocus.id')
  @Watch('$route',{deep:true})
  @Watch('player')
  focusUpdated(){
    if(this.player.focus){
      let dataFocus = "";
      
      if(this.player.focus.cardId == this.$store.state.GameView.currentFocus.id){
        dataFocus = `${this.player.focus.cardId}_${this.player.focus.context}`;
      }else if(this.player.focus.cardId == 'door' && this.$store.state.GameView.currentFocus == 'door'){
        dataFocus = `${this.player.focus.cardId}_${this.player.focus.context}`;
      }else{
        dataFocus = `${this.player.focus.cardId}`;
      }
      
      let f = document.querySelector(`[data-focus="${dataFocus}"]`)
      if (f) {
        this.hide = false;
        this.focusEl = f as HTMLElement;
        this.dataFocus = `aw_${dataFocus}`;
        this.moveTo();
        setTimeout(this.moveTo,100) // ensures if element randomly jump, restores position
      }else{
        this.hide = true;
      }
    }else{
      this.hide = true;
    }
  }

  moveTo(){
    if(this.focusEl){
      let r = this.focusEl.getBoundingClientRect();
      this.position = {
        left:r.right-17+"px",
        top:r.top-17+"px"
      }
      let numAtLocation = this.getNumAtLocation();
    }
  }
  getNumAtLocation(){
    let focusees = document.querySelectorAll(`[data-focus="${this.dataFocus}"]`)
    for(let i=0; i<focusees.length; i++){
      let el = focusees[i] as HTMLElement;
      el.style.transform = `translate3d(-${i*20}px,0px,0px)`
    }
    return focusees.length;
  }

  mounted(){
    this.focusUpdated();
    document.addEventListener('scroll', this.moveTo)
  }
  unmounted(){
    document.removeEventListener('scroll', this.moveTo)
  }
}

export function focus(cardId?:string|null, context?:string|null, value?:string|null, result?:boolean|null){
  setPlaythroughFocus(Store.state.Auth.user, Store.state.GameView.lobby, cardId, context, value, result)
}

export function clearFocus(){
  clearPlaythroughFocus(Store.state.Auth.user, Store.state.GameView.lobby)
}
