import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8057dd72")
const _hoisted_1 = {
  key: 0,
  class: "_label"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_4 = ["type", "placeholder", "readonly", "maxlength"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovingIcon = _resolveComponent("MovingIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label || _ctx.limit)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass({saveIcon:true,saving: _ctx.saving})
          }, [
            _createVNode(_component_MovingIcon, {
              name: "loader_dots",
              autoplay: true,
              loop: true
            })
          ], 2),
          _createTextVNode(" " + _toDisplayString(_ctx.label) + " ", 1),
          (_ctx.limit)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.length) + "/" + _toDisplayString(_ctx.limit) + ")", 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
    textarea:true,
    large: _ctx.large,
    colored: _ctx.colored,
    readonly: _ctx.readonly,
    icon: _ctx.icon
  }),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)))
    }, [
      (_ctx.textarea && !_ctx.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Aa"))
        : _createCommentVNode("", true),
      ((!_ctx.label && !_ctx.limit && _ctx.saving))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass({saveIconAtop:true,saving: _ctx.saving})
          }, [
            _createVNode(_component_MovingIcon, {
              name: "loader_dots",
              autoplay: true,
              loop: true
            })
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("textarea", {
        type: _ctx.type || 'text',
        ref: "textarea",
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
        onKeypress: [
          _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enterPressed && _ctx.enterPressed(...args)), ["enter"])),
          _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.keyTrigger && _ctx.keyTrigger(...args)))
        ],
        onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.keyTrigger && _ctx.keyTrigger(...args))),
        onKeyup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.keyTrigger && _ctx.keyTrigger(...args))),
        maxlength: _ctx.limit
      }, null, 40, _hoisted_4)
    ], 2)
  ], 64))
}