
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import LobbyAccount from "@/components/elements/LobbyAccount.vue"

@Options({
  components:{
    MovingIcon,
    LobbyAccount,
    Ripple
  },
  emits:[]
})
export default class LobbyWindow extends Base {
  
  avatars:string[] = [
    'alice',
    'brigette',
    'chad',
    'elvis',
    'george',
    'john',
    'kelly',
    'madonna',
    'miranda',
    'paul',
    'ringo',
    'sarah'
  ]

  @Prop({}) display:boolean
  @Prop({}) joining:boolean
  @Prop({}) host:boolean
  @Prop({}) me:boolean
  @Prop({}) small:boolean

  loading:boolean = true;

}
