
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Solid from "./Solid.vue" 
import Door from "./Door.vue" 
import Chest from "./Chest.vue" 
import tween from '@tweenjs/tween.js'

interface BaseItemInterface{
  facing:'n'|'e'|'s'|'w',
  click?:Function,
}
interface BasicInterface extends BaseItemInterface{
  locked:boolean,
  opened:boolean,
}
interface Tile{
  door?:BasicInterface,
  chest?:BasicInterface
}

interface coords{
  x:number,
  y:number,
}

export interface RoomProperties{
  tiles:{
    'nw'?:Tile,
    'n'?:Tile,
    'ne'?:Tile,
    'w'?:Tile,
    'm'?:Tile,
    'e'?:Tile,
    'sw'?:Tile,
    's'?:Tile,
    'se'?:Tile
  }
}

@Options({
  components:{
    Solid,
    Door,
    Chest
  },
  emits:['escaped']
})
export default class Room extends Base { 

  @Prop({}) properties:RoomProperties;
  @Prop({}) rotatable:boolean;
  @Prop({}) slowRotate:boolean;
  @Prop({}) escaped:number;

  mouseDown:{
    active:boolean,
    coords:coords
  }={
    active:false,
    coords:{x:0,y:0}
  }

  rotation:{
    x:number,
    z:number
  }={
    x:75,
    z:45
  }
  deltaRotation:{
    x:number,
    z:number
  }={
    x:0,
    z:0
  }

  magnification:number = 1;

  mounted(){
    document.addEventListener('mouseup',this.endDrag);
    document.addEventListener('touchend',this.endDrag);
    document.addEventListener('mousemove',this.drag);
    window.addEventListener('resize',this.magnify);

    this.magnify();

    if(this.slowRotate){
      this.tween();
    }
    requestAnimationFrame(this.animate);
  }

  beforeUnmount(){
    document.removeEventListener('mouseup',this.endDrag)
    document.removeEventListener('touchend',this.endDrag)
    document.removeEventListener('mousemove',this.drag)
    window.addEventListener('resize',this.magnify);
  }

  magnify(){
    this.magnification = Math.min(1, window.outerWidth / 1000)
  }


  beginDrag(e:any){
    if(this.rotatable && !this.slowRotate){
      if(e.touches){
        e = e.touches[0]
      }
      
      this.mouseDown = {
        active:true,
        coords:{
          x: e.pageX,
          y: e.pageY,
        }
      }
      document.body.classList.add('noSelect');
    }
  }

  endDrag(e:any){
    this.mouseDown.active = false;
    this.rotation.x = Math.max(0,Math.min(80,this.rotation.x - this.deltaRotation.x));
    this.rotation.z -= this.deltaRotation.z;
    this.deltaRotation = {x:0,z:0}
    document.body.classList.remove('noSelect');
  }

  drag(e:any){
    if(e.touches){
      e = e.touches[0]
    }
    if(this.mouseDown.active){
      this.deltaRotation = {
        x: (e.pageY - this.mouseDown.coords.y)/(this.magnification*5),
        z: (e.pageX - this.mouseDown.coords.x)/(this.magnification*5),
      }
    }
  }


  // COMPONENT FUNCTIONS
  open(item:any){
    item.opened = !item.opened;
    if(item.onOpen){
      item.onOpen();
    }
    this.$emit('escaped')
  }


  tweenTo:number = -25;
  tween(){
    let rate = 5000;
    new tween.Tween({value:this.rotation.z})
      .to({value:this.tweenTo}, rate)
      .easing(tween.Easing.Quadratic.InOut)
      .onUpdate((update)=>{
        this.rotation.z = update.value            
      })
      .onComplete(()=>{
        if(this.tweenTo == -25){
          this.tweenTo = 25
        }else{
          this.tweenTo = -25
        }  
        this.tween();
      }) 
      .start()
  }

  animate(time:number) {
    requestAnimationFrame(this.animate);
    tween.update(time);
  }
}
