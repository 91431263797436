
import { Options, Vue } from 'vue-class-component';

@Options({  
  components: {
  },
})
export default class DeveloperHome extends Vue {
  mounted(){
    this.$router.replace({path:'/developer/games'})
  }
}
