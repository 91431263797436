import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createBlock(_component_IconButton, {
    appearOnClick: true,
    large: true,
    loop: true,
    text: _ctx.text||'Login to purchase',
    colored: true,
    hex: "#FFFFFF",
    success: _ctx.facebookLoginSuccess,
    fail: _ctx.facebookLoginFail,
    icon: _ctx.loginCheck?'loader_spinner':_ctx.facebookLoginSuccess?'tick':_ctx.facebookLoginFail?'cross':'',
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.login('facebook')))
  }, null, 8, ["text", "success", "fail", "icon"]))
}