
import BaseComponent, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
 
@Options({
  components:{
  },
  emits:[]
})
export default class Base extends BaseComponent { 
  @Prop({}) name:string;
  @Prop({}) facing:string;
  @Prop({}) unlocked:boolean;
  @Prop({default:false}) opened:number|boolean;
  @Prop({default:false}) fixOpen:number;

  el:HTMLElement;

  mounted(){
    this.el = this.$el as HTMLElement;
    this.el.classList.add(`rf_${this.facing || 's'}`)
  }
}
