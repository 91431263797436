
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import TextInput from "@/components/elements/TextInput.vue"
import TextArea from "@/components/elements/TextArea.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import GamePreview from "@/components/elements/GamePreview.vue"
import PageControls from "@/components/elements/PageControls.vue"
import Checkbox from "@/components/elements/Checkbox.vue"
import IconButton from "@/components/elements/IconButton.vue"
import PopupInput from "@/components/elements/PopupInput.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import Card from "@/components/elements/Card.vue"
import CardDoor from "@/components/elements/CardDoor.vue"
import Countdown from "@/components/elements/Countdown.vue"
import CardList from "@/components/elements/CardList.vue"
import Confirm from "@/components/elements/Confirm.vue"
import LobbyWindow from "@/components/elements/LobbyWindow.vue"
import ActivityWindow from "@/components/elements/ActivityWindow.vue"
import Audio from "@/components/elements/Audio"
import TutorialPopup from "@/components/elements/TutorialPopup.vue"

import MovingIcon from "movingicons"
import firebase, {db} from "@/firebase"
import {Game, getGame, allCardsWhere, rate} from "@/firebase/api/games"
import {User} from "@/firebase/api/auth"
import { pausePlaythrough, resumePlaythrough, saveHistory, stopPlaythrough, updateHistoryCompleteState } from "@/firebase/api/playthrough"
import { detatchLobby } from "@/firebase/api/lobby"

@Options({  
  components: {
    TextInput,
    TextArea,
    Dropdown,
    GamePreview,
    PageControls,
    IconButton,
    Checkbox,
    PopupInput,
    LazyImage,
    MovingIcon,
    Confirm,
    Card,
    CardDoor,
    CardList,
    LoadSpinner,
    Countdown,
    LobbyWindow,
    ActivityWindow,
    TutorialPopup
  },
})
export default class GameView extends Base {

  loading:boolean = true;
  error:string = '';
  emojiCountdown:any = false;

  isTutorial:boolean = false;
  tutorialPhase:number = 0;

  mounted(){
    this.init();
    this.emojiSpawner();
    this.alterHeader();
  }

  tutorialCardBack(number:number){
    this.$store.commit('gameView_setCardFocus',number)
  }

  @Watch('$store.state.GameView.currentFocus')
  updateTutorialStep(){
    if(this.isTutorial){
      if(this.$store.state.GameView.currentFocus == 'door'){
        this.tutorialPhase=9
      }else if(this.$store.state.GameView.currentFocus.index){
        if(this.$store.state.GameView.currentFocus.index == 1){
          this.tutorialPhase=0
        }
        if(this.$store.state.GameView.currentFocus.index == 2){
          this.tutorialPhase=4
        }
        if(this.$store.state.GameView.currentFocus.index == 3){
          this.tutorialPhase=5
        }
      }
    }
  }

  alterHeader(){
    let nav = document.getElementsByClassName('_nav')[0] as HTMLElement
    let header = document.getElementsByClassName('_header')[0] as HTMLElement;

    if(nav && header){
      nav.style.display = "none";
      header.style.paddingLeft = "1.5em";
      header.classList.add("smallRight");
    }
  }

  async init(){
    // OBJECTIVE = ensure lobby and game is set by lobby view
    // if not, redirect and ensure logic handled there
    let shouldRedirect = true;
    if(this.$store.state.GameView.lobby?.meta){
      // lobby set
      let gameId = this.$store.state.GameView.lobby.meta.gameId;
      let code = this.$store.state.GameView.lobby.meta.code;
      if(gameId == this.$route.params.id && code == this.$route.params.code){
        // matches url
        if(this.$store.state.GameView.lobby.playthrough?.started > 0){
          // playthrough set and started
          shouldRedirect = false;
          this.getPages();
        }
      }
    }

    if(shouldRedirect){
      this.$router.replace({
        path:`/app/lobby/${this.$route.params.id}/${this.$route.params.code}`
      })
    }
  }

  beforeUnmount(){
    this.setDevView(false);

    let nav = document.getElementsByClassName('_nav')[0] as HTMLElement
    let header = document.getElementsByClassName('_header')[0] as HTMLElement;

    if(nav && header){
      nav.style.display = '';
      header.style.paddingLeft = '';
      header.classList.remove("smallRight");
    }
  }

  getGameFromURL(){
    let id = this.$route.params.id as string;
    if(id){
      getGame(id).then((data:any)=>{
        this.$store.commit('gameView_setGame',{id:data.id,...data.data()});
        if(id == "tutorial"){
          this.isTutorial = true;
          this.tutorialPhase = 0;
        }
      }).catch((e)=>{
        this.error = e;
      })
    }
  }

  emojiSpawner(){
    if(this.$store.state.GameView.lobby.playthrough?.stopped){
      let e:string[] = ['😞','😭','😔','🙃','😂','😅','😐','😑'];
      let em = e[Math.floor(Math.random()*8)]
      this.spawnEmoji(em)
    }
    this.emojiCountdown = setTimeout(this.emojiSpawner,Math.random()*1250)
  }
  spawnEmoji(em:string){
    let d = document.createElement('div');
    d.className = "_e_";
    d.innerHTML = em;
    d.style.left = `${Math.random()*100}%`
    d.style.transform = `rotate(${Math.random()*360}deg)`
    document.body.appendChild(d)
    setTimeout(()=>{
      d.remove()
    },5000)
  }

  unmounted(){
    if(this.emojiCountdown){
      clearTimeout(this.emojiCountdown)
    }
  }

  getPages(){
    allCardsWhere({
      row:"parent",
      comparitor: "==",
      value:this.$store.state.GameView.lobby.meta.gameId
    },[
      {
        collection:'questions',
        where:{
          row:"cardid",
          comparitor: "==",
          value:'id'
        }
      },
      {
        collection:'attributions',
        where:{
          row:"cardid",
          comparitor: "==",
          value:'id'
        }
      }
    ],{
      row:'index',
      order:"asc"
    }).then((data)=>{
      this.loading = false;
      this.$store.commit('gameView_setCards',data);
      this.shouldNavigateToPage();
      if(this.$store.state.GameView.lobby.meta.gameId == "tutorial"){
        this.isTutorial = true;
        this.tutorialPhase = 0;
      }
    }).catch(()=>{
      console.error("COULD NOT RETRIEVE CARDS");
    })
  }

  shouldNavigateToPage(){
    this.$store.commit('gameView_setCardFocus',this.$route.params.page);
  }

  toggleDevView(){
    let state = this.$store.state.GameView.devView;
    this.$store.commit('setDevView',!state);
  }

  setDevView(state:boolean){
    this.$store.commit('setDevView',state);
  }

  toCurrent(){
    this.$store.commit('gameView_setCardFocus', this.$store.state.GameView.currentCard);
  }

  returnToLobby(){
    this.$router.push({
      path:`/app/lobby/${this.$store.state.GameView.lobby.meta.gameId}/${this.$store.state.GameView.lobby.meta.code}`
    })
    if(this.emojiCountdown){
      clearTimeout(this.emojiCountdown)
    }
  }

  leaveLobby(){
    if(this.$store.state.Auth.user && this.$store.state.Auth.user.name){
      let instance = `/lobby/${this.$store.state.GameView.lobby.meta.code}/players/${this.$store.state.Auth.user.id || this.$store.state.Auth.user.name}`;
      firebase.database().ref(instance).remove();
      detatchLobby(this.$store.state.GameView.lobby);
      this.$store.commit('gameView_leaveLobby');
    }
  }

  play(){
    new Audio("/sounds/play.wav",true,3);
    updateHistoryCompleteState(this.$store.state.GameView.lobby, false);
    resumePlaythrough(this.$store.state.GameView.lobby);
  }
  pause(){
    new Audio("/sounds/pause.wav",true,3);
    updateHistoryCompleteState(this.$store.state.GameView.lobby, false);
    pausePlaythrough(this.$store.state.GameView.lobby);
  }
  stop(){
    new Audio("/sounds/fail.wav",true,3);
    updateHistoryCompleteState(this.$store.state.GameView.lobby, true);
    stopPlaythrough(this.$store.state.GameView.lobby);
  }

  rate(rating:number){
    this.$store.commit('gameView_rate', rating);
    rate(this.$store.state.GameView.lobby.meta.gameId, this.$store.state.Auth.user.id, rating);
  }

  @Watch('$route')
  routeChange(to:any,from:any){
    if(to.name !== "Game"){
      this.leaveLobby();
    }
  }

  @Watch('$store.state.GameView.lobby.playthrough.permitted',{deep:true})
  watchKicked(){
    let id = this.$store.state.Auth?.user?.id;
    let p = this.$store.state.GameView?.lobby?.permitted;
    if(p){
      let isP = this.$store.state.GameView?.lobby?.permitted[id]
      if(isP){
        if(isP.banned){
          this.$router.replace({path:'/app'})
          setTimeout(()=>{
            alert("You were kicked");
          },250)
        }
      }
    }
  }

}
