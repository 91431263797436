
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons" 

@Options({
  components:{
    MovingIcon
  },
  emits:[]
})
export default class LoadSpinner extends Base { 
  @Prop({}) text:string
}
