
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"

import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import Navigation from '@/components/layout/Navigation.vue'
import MovingIcon from "movingicons"

@Options({  
  components: {
    Header,
    IconButton,
    MovingIcon,
    Navigation,
  },
})
export default class Policy extends Base {
  transitionName:string = 'slide-left';
  navOpen:boolean = false;

  @Watch('$route', {deep:true})
  updateTransition(to:any, from:any) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
  }
}
