
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import IconButton from "./IconButton.vue"
import Ripple from "@/components/ux/Ripple.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import {moveCardToIndex,Card,addCard} from "@/firebase/api/games"
@Options({
  components:{
    MovingIcon,
    IconButton,
    Ripple,
    LoadSpinner
  },
  emits:[]
})
export default class PageControls extends Base { 
  @Prop({default:false}) gameeditmeta:boolean;
  @Prop({default:0}) start:number;

  moveToIndex(card:Card,index:number){
    moveCardToIndex(card,index).then((data)=>{
      this.$store.commit('moveCardToIndex',{card,index})
    }).catch((err)=>{
      throw Error(err);
    })
  }

  moveTo(index:number){
    let limit = this.$store.state.GameEdit.cards.data.length;
    if(index < limit && index > -1 ){
      this.$store.commit('setCardFocus',index);
    }else if(index == -3){
      this.$store.commit('setCardFocus','stats');
    }else if(index == -2){
      this.$store.commit('setCardFocus','room');
    }else if(index == -1){
      this.$store.commit('setCardFocus','meta');
    }else if(index == -4){
      this.$store.commit('setCardFocus','door')
    }else if(index == -5){
      this.$store.commit('setCardFocus','attributions');
    }
  }

  addCard(){
    let highestIndex:number = -1;
    for(let card of (this.$store.state.GameEdit.cards.data as Card[])){
      highestIndex = Math.max(highestIndex,card.index);
    }
    this.$store.commit('creatingCardLoad',true)
    addCard({
      index:highestIndex+1,
      parent:this.$store.state.GameEdit.game.id
    }).then(doc=>{
      doc.get().then(doc=>{
        this.$store.commit('creatingCardLoad',false)
        this.$store.commit('addCard',{id:doc.id,...doc.data()});
        this.$store.commit('setCardFocus',highestIndex+1);
      })
    }).catch(e=>{
      this.$store.commit('creatingCardLoad',false)
      alert("Error creating card - see console");
      console.error(e);
    })
  }
}
