
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue";
import IconButton from "@/components/elements/IconButton.vue";
import GamePreview from "@/components/elements/GamePreview.vue";
import { getAllHistoryIncomplete, ignoreIncompleteHistory } from '@/firebase/api/playthrough';

@Options({
  components:{
    IconButton,
    GamePreview
  },
})
export default class HistoryPopup extends Base { 
  
  historyFields:any[] = [];

  mounted(){
    this.getHistory();
  }

  async getHistory(){
    await this.$store.state.Auth.awaiter.promise
    getAllHistoryIncomplete(this.$store.state.Auth.user).then(data=>{
      if(data){
        this.historyFields = data
      }
    })
  }

  closeHistory(){
    ignoreIncompleteHistory(this.$store.state.Auth.user).then(data=>{
      this.historyFields = [];
    })
  }
}
