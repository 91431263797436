import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-695ac1c5")
const _hoisted_1 = { class: "text" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ripple = _resolveComponent("Ripple")!
  const _component_MovingIcon = _resolveComponent("MovingIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "file",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadFile()))
  }, [
    _createVNode(_component_Ripple),
    (_ctx.file.location)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({
      _img:true,
      fileType:true
    }),
          style: _normalizeStyle(`background-image: url(${_ctx.file.location}); background-size: 16px`)
        }, [
          _createVNode(_component_MovingIcon, {
            name: 
        _ctx.file.type == 'psd'?'psd'
        :_ctx.file.type.startsWith('doc')?'docx'
        :_ctx.file.type.startsWith('ppt')?'pptx'
        :_ctx.file.type.startsWith('json')?'json'
        :_ctx.file.type.startsWith('pdf')?'pdf'
        :_ctx.file.type.startsWith('html')?'html'
        :_ctx.file.type.startsWith('mp3')?'start'
        :_ctx.file.type.startsWith('wav')?'start'
        :_ctx.file.type.startsWith('mp4')?'start'
        :''
      ,
            type: "svg"
          }, null, 8, ["name"])
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.file.name), 1)
  ]))
}