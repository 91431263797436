
import { Options, Vue } from 'vue-class-component';
import TextInput from "@/components/elements/TextInput.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import GamePreview from "@/components/elements/GamePreview.vue"
import PageControls from "@/components/elements/PageControls.vue"
import IconButton from "@/components/elements/IconButton.vue"
import PopupInput from "@/components/elements/PopupInput.vue"
import {db} from "@/firebase"

import MovingIcon from "movingicons"
import {BASE, Game, allGames, deleteGame} from "@/firebase/api/games"

@Options({  
  components: {
    TextInput,
    Dropdown,
    GamePreview,
    PageControls,
    IconButton,
    PopupInput,
    MovingIcon
  },
})
export default class DeveloperGamesBrowse extends Vue {

  games:Game[] = [];

  gameCreated:boolean = false;
  gameFail:boolean = false;
  popupDisplay:boolean = false;

  sortOptions:{
    active:number,
    options:{
      text:string,
      icon:string,
      sortFunction?:Function
    }[]
  } = {
    active:0,
    options:[
      {
        text:"Newest",
        icon:"up"
      },
      {
        text:"Oldest",
        icon:"down"
      },
      {
        text:"Reviews",
        icon:"star"
      },
      {
        text:"Popularity",
        icon:"star"
      }
    ]
  }

  mounted(){
    this.fetchGames();
  }

  async fetchGames(){
    this.games = await allGames();
  }

  newGame(){
    this.gameCreated = false;
    this.gameFail = false;
    this.popupDisplay = true;
  }

  create(inputs:{
    name:string,
    placeholder:string,
    value?:string
  }[]){
    
    let popupDisplay = this.$refs.newGamePopup as PopupInput;
    popupDisplay.load();

    let baseProps = BASE;
    for(let input of inputs){
      // @ts-ignore
      baseProps[input.name] = input.value;
    }

    db.collection('games').add(baseProps).then((game:any)=>{
      popupDisplay.succeed();
      setTimeout(()=>{
        this.$router.push({path:`/developer/games/${game.id}`});
      },750)
    }).catch((e)=>{
      popupDisplay.fail();
    })
  }

  del(game:Game, index:number){
    game.deleting=true;
    deleteGame(game.id).then(()=>{
      this.games.splice(index,1);
      game.deleting=false;
    }).catch(e=>{
      game.deleting=false;
      alert("Error deleting game");
      throw Error(e)
    })
  }

  editGame(id:string){
    this.$router.push({path:`/developer/games/${id}`});
  }

}
