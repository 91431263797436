
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import TextInput from "@/components/elements/TextInput.vue"
import TextArea from "@/components/elements/TextArea.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import PageControls from "@/components/elements/PageControls.vue"
import Checkbox from "@/components/elements/Checkbox.vue"
import IconButton from "@/components/elements/IconButton.vue"
import PopupInput from "@/components/elements/PopupInput.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import CardElement from "@/components/elements/Card.vue"
import CardDoor from "@/components/elements/CardDoor.vue"
import Countdown from "@/components/elements/Countdown.vue"
import CardList from "@/components/elements/CardList.vue"
import Confirm from "@/components/elements/Confirm.vue"
import LobbyWindow from "@/components/elements/LobbyWindow.vue"
import ActivityWindow from "@/components/elements/ActivityWindow.vue"

import MovingIcon from "movingicons"
import firebase, {db} from "@/firebase"
import {Game, Card, getGame, allCardsWhere, rate} from "@/firebase/api/games"
import {User} from "@/firebase/api/auth"
import { Playthrough, PSEUDO, pausePlaythrough, resumePlaythrough, stopPlaythrough } from "@/firebase/api/playthrough"
import { detatchLobby } from "@/firebase/api/lobby"
import { Cards } from "@/store/GameView"

@Options({  
  components: {
    TextInput,
    TextArea,
    Dropdown,
    PageControls,
    IconButton,
    Checkbox,
    PopupInput,
    LazyImage,
    MovingIcon,
    Confirm,
    CardElement,
    CardDoor,
    CardList,
    LoadSpinner,
    Countdown,
    LobbyWindow,
    ActivityWindow
  },
})
export default class GamePreview extends Base {

  loading:boolean = true;
  error:string = '';
  emojiCountdown:any = false;
  game: any = {};
  cards:Cards = {};
  cardsData:Card[] = [];
  currentFocus:number|string = 0;

  mounted(){
    this.init();
  }

  async init(){
    this.$store.commit('gameView_resetPlaythrough');
    this.$store.commit('gameView_setCardFocus',0)
    await this.getGame();
    await this.getCards();
    // this.openPage();
  }

  async getGame(){
    let id = this.$route.params.id as string;
    let data = await getGame(id);
    if(data.exists){
      let d = data.data()
      if(d) this.game = {
        id:data.id,
        ...d
      }
    }
  }

  async getCards(){
    allCardsWhere({
      row:"parent",
      comparitor: "==",
      value:this.game.id
    },[
      {
        collection:'questions',
        where:{
          row:"cardid",
          comparitor: "==",
          value:'id'
        }
      },
      {
        collection:'attributions',
        where:{
          row:"cardid",
          comparitor: "==",
          value:'id'
        }
      }
    ],{
      row:'index',
      order:"asc"
    }).then((data)=>{
      this.loading = false;
      this.$store.commit("gameView_setCards",data)
    }).catch(()=>{
      console.error("COULD NOT RETRIEVE CARDS");
    })
  }

  returnToCreator(){
    let page = this.$route.params.page || 'meta';
    let id = this.$route.params.id;
    this.$router.push({path:`/developer/games/${id}/${page}`});
  }
  
  @Watch('$route.params.page')
  openPage(p?:number){
    let page = p || this.$route.params.page;
  }

}
