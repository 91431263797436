import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fbd1ce96")
const _hoisted_1 = {
  key: 0,
  class: "_header grid_top"
}
const _hoisted_2 = { class: "_headerBack" }
const _hoisted_3 = { class: "_headerText" }
const _hoisted_4 = {
  key: 1,
  class: "_loadPrompt"
}
const _hoisted_5 = {
  key: 2,
  class: "_main game"
}
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "list"
}
const _hoisted_9 = {
  key: 3,
  id: "game_lobby",
  class: "_nav"
}
const _hoisted_10 = { class: "_code" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_LoadSpinner = _resolveComponent("LoadSpinner")!
  const _component_CardElement = _resolveComponent("CardElement")!
  const _component_CardDoor = _resolveComponent("CardDoor")!
  const _component_CardList = _resolveComponent("CardList")!
  const _component_LobbyWindow = _resolveComponent("LobbyWindow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.game.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_IconButton, {
              icon: "back",
              white: true,
              onClick: _ctx.returnToCreator
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.game.title), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.game.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_LoadSpinner, { text: "Loading game" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.game.id && _ctx.$store.state.GameView.lobby.playthrough)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.$store.state.GameView.cards && _ctx.$store.state.GameView.cards.data)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.$store.state.GameView.cards.data, (card) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_CardElement, {
                    card: card,
                    preview: true
                  }, null, 8, ["card"])
                ]))
              }), 256))
            : _createCommentVNode("", true),
          (_ctx.$store.state.GameView.currentFocus == 'door')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_CardDoor)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$store.state.GameView.cards && _ctx.$store.state.GameView.cards.data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_CardList, {
                  cards: _ctx.$store.state.GameView.cards.data,
                  cardStatus: _ctx.$store.state.GameView.lobby.playthrough.cardStatus
                }, null, 8, ["cards", "cardStatus"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.$store.state.GameView.lobby 
    && _ctx.$store.state.GameView.lobby.playthrough 
    && !_ctx.$store.state.GameView.lobby?.playthrough?.paused
    && !_ctx.$store.state.GameView.lobby?.playthrough?.stopped)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$store.state.GameView.lobby.meta.code), 1),
                (_ctx.$store.state.GameView.lobby)
                  ? (_openBlock(), _createBlock(_component_LobbyWindow, {
                      key: 0,
                      small: true
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}