
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import randomGen from "@/views/app/randomGenerator"  
import TextInput from "./TextInput.vue"
import IconButton from "./IconButton.vue"
import Confirm from "./Confirm.vue"
import LazyImage from "./LazyImage.vue"
import Audio from "./Audio"
import File from "./File.vue"
import {Card} from "@/firebase/api/games"
import { setCardStatus, unlockNextCard, goToDoor, tallyHint, tallySkip, addSkippedTime } from "@/firebase/api/playthrough"
import {focus, clearFocus} from "@/components/elements/ActivityWindow.vue"

@Options({
  components:{
    MovingIcon,
    TextInput,
    IconButton,
    Confirm,
    LazyImage,
    File
  },
  emits:[]
})
export default class CardItem extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) demo:boolean;
  @Prop({}) test:string
  @Prop({}) small:boolean
  @Prop({}) preview:boolean

  @Prop({}) highlightText:boolean
  @Prop({}) unhideImage:boolean
  @Prop({}) hideAllButFiles:boolean

  @Prop({}) card:Card

  submitted:boolean = false;
  correct:boolean = false;
  complete:boolean = false;
  incorrect:boolean = false;
  hinted:boolean = false;
  skipped:boolean = false;
  distanceSkipped:boolean = false;
  hintRequested:boolean = false;
  skipRequested:boolean = false;
  distanceSkipRequested:boolean = false;
  questions:string[] = [];

  demoHint:boolean = false;
  demoAnswered:boolean = false;
  demoWrong:boolean = false;

  randomGen=randomGen;
  goToDoor=goToDoor;
  focus=(...args:any[])=>{
    if(!this.demo){
      focus(...args)
    }
  };
  clearFocus=(...args:any[])=>{
    if(!this.demo){
      clearFocus()
    }
  };

  mounted(){
    if(!this.demo){
      this.ensurePlaythroughStore();
      focus(this.card.id,null,null,null);
    }
  }

  ensurePlaythroughStore(){
    this.$store.commit('setCardStatus',{
      id:this.card.id,
      complete:false,
      correct:false,
      incorrect:false,
      hinted:false,
      skipped:false
    })
  }

  submit(){
    this.submitted = true;
    this.incorrect = false;
    this.correct = false;

    let oneFailed:boolean = false;
    let questionIndex = 0;
    for(let question of this.card.questions){
      let accepted:string[] = question.acceptedValues.split(';').map((acceptedValue:string) => {
        return acceptedValue.trim().toLowerCase();
      })
      if(!question.answer || !accepted.includes(question.answer.toLowerCase().trim())){
        oneFailed = true;
        question.incorrect = true;
      }
      questionIndex++;
    }

    if(oneFailed == false){
      this.success();
    }else{
      this.fail();
      focus(undefined, undefined, undefined, false)
    }
  }

  success(){
    this.submitted = false;
    
    if(this.demo){
      this.demoWrong = false;
      this.demoAnswered = true;
      return;
    }

    new Audio("/sounds/success.wav",true,3)

    if(this.preview){
      this.$store.commit('setCardStatus',{
        id:this.card.id,
        complete:true,
        correct:true,
        incorrect:false,
      })
      if(this.$store.state.GameView.lobby.playthrough.currentCard > this.$store.state.GameView.cards.data.length-1){
        this.$store.commit('goToDoor');
      }else{
        this.$store.commit('unlockNextCard');
      }
    }else{
      setCardStatus(this.$store.state.GameView.lobby, {
        id:this.card.id,
        incorrect:false,
        correct:true,
        complete:true,
        passedTime:new Date().getTime()
      })
      unlockNextCard(this.$store.state.GameView.lobby)
      if(this.$store.state.GameView.lobby.playthrough.currentCard > this.$store.state.GameView.cards.data.length-1){
        goToDoor(this.$store.state.GameView.lobby);
      }
    }
  }
  fail(){
    this.submitted = false;

    if(this.demo){
      this.demoWrong = true;
      this.demoAnswered = false;
      return;
    }

    new Audio("/sounds/wrong.wav",true,3)

    setCardStatus(this.$store.state.GameView.lobby,{
      id:this.card.id,
      correct:false,
      incorrect:true,
    })
  }

  requestSkip(){
    this.skipRequested = true;
  }
  denySkip(){
    this.skipRequested = false;
  }
  approveSkip(){
    this.skipRequested = false;
    setCardStatus(this.$store.state.GameView.lobby,{
      id:this.card.id,
      correct:false,
      incorrect:false,
      complete:true,
      skipped:true,
      passedTime:new Date().getTime()
    })
    new Audio("/sounds/skip.wav",true,3)
    tallySkip(this.$store.state.GameView.lobby)
    unlockNextCard(this.$store.state.GameView.lobby)
  }
  approveDistanceSkip(minutes:number){
    let secondsToSkip = minutes * 60 * 1000;
    this.distanceSkipRequested = false;
    this.distanceSkipped = true;
    setCardStatus(this.$store.state.GameView.lobby,{
      id:this.card.id,
      distanceSkipped:true,
    })
    new Audio("/sounds/skip.wav",true,3)
    addSkippedTime(this.$store.state.GameView.lobby, secondsToSkip)
  }

  requestHintOne(){
    this.hintRequested = true;
  }
  denyHint(){
    this.hintRequested = false;
  }
  approveHint(){
    this.hintRequested = false;
    setCardStatus(this.$store.state.GameView.lobby,{
      id:this.card.id,
      correct:false,
      incorrect:false,
      complete:false,
      hinted_one:true
    })
    new Audio("/sounds/hint.wav",true,3)
    tallyHint(this.$store.state.GameView.lobby)
  }

  skipDistance(){
    this.distanceSkipRequested = true;
  }
  mapsHelp(){

  }

  nextCard(){
    if(this.$store.state.GameView.lobby.playthrough.currentCard > this.$store.state.GameView.cards.data.length-1){
      this.$store.commit('goToDoor')
    }else{
      this.$store.dispatch('nextCard')
    }
  }
}
