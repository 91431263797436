
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import Room from "@/components/room/Room.vue"
import { StringifyOptions } from "querystring"
import confetti from "canvas-confetti"

@Options({
  components:{
    MovingIcon,
    Ripple,
    Room
  },
  emits:['update']
})
export default class LandingHost extends Base { 

  @Prop({default:0}) stage:number

  maxStage:number=18;
  lobbyChar:string = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
  lobbyCode:string = '';

  guessOne:string = "";
  guessTwo:string = "";
  guessThree:string = "";
  guessFour:string = "";
  guessFive:string = "";

  escaped:boolean = false;
  typing:boolean = false;
  i = setInterval(()=>{},1000);

  doorRoomData={
      tiles:{
        m:{
          door:{
            facing:"s",
            locked:true,
            opened:false,
          }
        }
      }
    }

  mounted(){
    let charactersLength = this.lobbyChar.length;
    for ( let i = 0; i < 5; i++ ) {
      this.lobbyCode += this.lobbyChar.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.stageUp();
  }

  stageUp(){

    if(this.stage>this.maxStage){
      // this.$emit('update',-1);
    }else if(!this.typing){
      this.$emit('update',this.stage+1);
    }

    let interval = 1000+Math.random()*1000;
    if(this.stage<1){
      interval = 10;
    }
    setTimeout(()=>{
      this.stageUp();
    },interval)
    
    if(this.stage==3){
      this.typeOut("Tuscany")
    }else if(this.stage<3) this.guessOne = "";

    if(this.stage==5){
      this.typeOut("Oltrarno")
    }else if(this.stage<5) this.guessTwo = "";

    if(this.stage==9){
      this.typeOut("The Pagliazza Tower")
    }else if(this.stage<9) this.guessThree = "";

    if(this.stage==13){
      this.typeOut("Arno River")
    }else if(this.stage<13) this.guessFour = "";

    if(this.stage > 14){
      this.doorRoomData.tiles.m.door.locked = false;
      this.doorRoomData.tiles.m.door.opened = true;
      if(this.stage==15){
        let c = this.$refs.c as HTMLCanvasElement;
        let myConfetti = confetti.create(c, {
          resize: true,
        });
        myConfetti({
          particleCount: 100,
          spread: 160
        });
      }
    }else{
      this.doorRoomData.tiles.m.door.locked = true;
      this.doorRoomData.tiles.m.door.opened = false;
    }
  }

  typeOut(string:string){
    let len = string.length;
    let copy = string;
    let index = 0;
    let err = false;
    let v = '';
    this.i = setInterval(()=>{},1000);
    clearInterval(this.i);

    this.typing = true;
    this.i = setInterval(()=>{
      if(err == true){
        v = v.slice(0,-1);
        err = false;
      }else{
        if(Math.random()>0.1){
          v+=string[index];
          index++;
        }else{
          err = true;
          v+=this.lobbyChar[Math.floor(Math.random()*this.lobbyChar.length)].toLowerCase()
        }
      }

      if(this.stage < 5){
        this.guessOne = v;
        if(this.guessOne == string){ clearInterval(this.i); this.typing=false; return; }
      }else if(this.stage >=5 && this.stage <8){
        this.guessTwo = v;
        if(this.guessTwo == string){ clearInterval(this.i); this.typing=false; return; }
      }else if(this.stage >=8 && this.stage <12){
        this.guessThree = v;
        if(this.guessThree == string){ clearInterval(this.i); this.typing=false; return; }
      }else if(this.stage >=12){
        this.guessFour = v;
        if(this.guessFour == string){ clearInterval(this.i); this.typing=false; return; }
      }
    }, Math.random()*200+100)
  }

  @Watch('stage')
  clearOut(){
    if(this.stage==0||this.stage==2||this.stage==7||this.stage==1){
      this.typing = false;
      clearInterval(this.i);
    }
  }
}
