
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import {route} from "../../router"
import NavigationItem from "./NavigationItem.vue"
import Account from "../elements/Account.vue"
import Confirm from "../elements/Confirm.vue"
import IconButton from "../elements/IconButton.vue"
import NavigationMeta from '@/components/layout/NavigationMeta.vue'

 
@Options({
  components:{
    NavigationItem,
    Account,
    Confirm,
    IconButton,
    NavigationMeta
  },
  emits:['close']
})
export default class Navigation extends Base { 

  @Prop({}) open:boolean;

  Routes:route[] = [{
    displayName:"Home",
    name:"Browse",
    icon:"home",
    type:"svg",
    path:"/app",
  },
  {
    displayName: "Join a game",
    name: "Join",
    icon: "join",
    path: "/app/join"
  },
  {
    displayName: "Match history",
    name: "History",
    icon: "history",
    path: "/app/history"
  },
  {
    displayName: "Account",
    name: "Account",
    icon: "user",
    type:"svg",
    path: "/account"
  },
  {
    displayName: "Partners",
    name: "Partners",
    icon: "partners",
    type:"svg",
    path: "/settings/partners"
  },
  {
    displayName: "Settings",
    name: "Settings",
    icon: "more_vert",
    type:"svg",
    path: "/settings"
  }
  ]

  GameRoutes:route[] = [{
    displayName:"Leave",
    name:"Leave game",
    icon:"back",
    path:"/app/lobby/959/22",
  },{
    displayName:"Help",
    name:"How to play",
    path:"/app/tutorial",
  }]

  DevRoutes:route[] = [
    // {
    //   displayName:"Home (",
    //   name:"Home",
    //   icon:"home",
    //   type:"svg",
    //   path:"/developer",
    // },
  {
    displayName:"Games",
    name:"Games",
    icon:"layout",
    type:"svg",
    path:"/developer/games",
  },
  // {
  //   displayName:"Users",
  //   name:"Users",
  //   icon:"user",
  //   type:"svg",
  //   path:"/developer/users",
  // }
  ]

  gameView:boolean = true;

  mounted(){

  }

  routeTo(path:string){
    this.$router.push(path)
  }
}
