
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"

@Options({
  components:{
    MovingIcon,
    Ripple,
  },
  emits:['update']
})
export default class LandingHost extends Base { 

  @Prop({default:-1}) stage:number

  maxStage:number=14;
  lobbyChar:string = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
  lobbyCode:string = '';

  mounted(){
    let charactersLength = this.lobbyChar.length;
    for ( let i = 0; i < 5; i++ ) {
      this.lobbyCode += this.lobbyChar.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.stageUp();
  }

  stageUp(){
    this.$emit('update',this.stage+1);
    let interval = 1500+Math.random()*1000;
    if(this.stage < 0){
      interval = 10;
    }
    setTimeout(()=>{
      this.stageUp();
    },interval)
  }
}
