
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import {getUser, User} from "@/firebase/api/auth"
import { kick, setHost } from "@/firebase/api/lobby"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class Account extends Base {

  @Prop({}) small:boolean
  @Prop({}) player:any
  @Prop({}) index:string
  
  open:boolean = false;
  
  close(){
    this.open = false;
  }

  kick(){
    if(this.player.id=="fakie"){
      this.$store.commit("gameView_removeFakePlayer")
    }else if(this.$store.state.Auth.user.id == this.$store.state.GameView.lobby.meta.host){
      kick(this.$store.state.GameView.lobby.meta.code,this.player.id)
      this.close();
    }
  }
  promote(){
    if(this.$store.state.Auth.user.id == this.$store.state.GameView.lobby.meta.host){
      setHost(this.$store.state.GameView.lobby.meta.code, this.player.id)
      this.close();
    }
  }

}
