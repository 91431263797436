import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/auth';

// DEV DETAILS
let config:Object  = {
    apiKey: process.env.VUE_APP_FIREBASE_API,
    authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}-default-rtdb.${process.env.VUE_APP_FIREBASE_REGION}.firebasedatabase.app`,
};


// ENVIRONMENT
const app = firebase.initializeApp(config);
const db = app.firestore();
const realtime = app.database();
const auth = app.auth();
const functions = app.functions("australia-southeast1");
console.log("Established firebase functions at region australia-southeast1");

// if(process.env.NODE_ENV == 'development'){
//     functions.useEmulator("localhost", 5001);
//     db.useEmulator("localhost", 5008);
//     realtime.useEmulator("localhost", 9000)
// }

// EXPORTS
export default firebase
export { 
    app,db,auth,realtime
}