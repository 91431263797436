
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Solid from "./Solid.vue" 
import Tweener from "@/components/room/tweener.ts"
 
@Options({
  components:{
    Solid
  }
})
export default class ChestLock extends Base { 

  @Prop({}) locked:boolean;
  vanish:boolean = true;

  boltTransform:{
    rotateZ:number
  } = {
    rotateZ:0
  };

  lockTransform:{
    rotateZ:number
    rotateX:number
  } = {
    rotateZ:0,
    rotateX:0
  };


  boltOpen:any = {
    0:{
      rotateZ:45
    },
  }
  lockOpen:any = {
    0:{
      rotateZ:-25,
    },
  }

  lockShake:any = {
    0:{
      rotateZ:0
    },
    50:{
      rotateZ:-15
    },
    160:{
      rotateZ:15
    },
    280:{
      rotateZ:0
    }
  }

  mounted(){
    this.lockCheck();
  }

  @Watch('locked',{immediate:true})
  lockCheck(){
    setTimeout(()=>{
      if(!this.locked){
        Tweener(this.boltTransform,this.boltOpen)
        setTimeout(()=>{
          Tweener(this.lockTransform,this.lockOpen)
          setTimeout(()=>{
            this.vanish = true;
          },800)
        },200)
      }else{
        this.vanish = false;
        Tweener(this.lockTransform,this.lockShake)
      }
    },50)
  }

  lockReset(){
    this.boltTransform= {
      rotateZ:0
    };
    this.lockTransform = {
      rotateZ:0,
      rotateX:0
    };
    this.lockCheck();
    this.vanish = false;
  }

}
