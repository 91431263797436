export default [
    'alice',
    'brigette',
    'chad',
    'elvis',
    'george',
    'kelly',
    'john',
    'madonna',
    'miranda',
    'paul',
    'ringo',
    'sarah',
]