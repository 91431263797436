
import {createApp} from "vue"
import {Vue, VueConstructor, Options, createDecorator} from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator'

export {Vue, Options, Prop, Watch, createApp};
export default class Base extends Vue{ 
    
}
 

export function VisibilityToggle<T extends VueConstructor>(constructor: T) {
  return class extends constructor {
    // @ts-ignore
    _lastChanged:number;

    mounted(){
        this.$el.addEventListener('click',this._open)
        this.$el.removeEventListener('click',this._close)
        document.addEventListener('click',this._close)
    }
    beforeDestroy(){
        this.$el.removeEventListener('click',this._open)
        this.$el.removeEventListener('click',this._close)
        document.removeEventListener('click',this._close)
    }

    _open(){
        if(!this.$el.classList.contains('_open')){
            this._lastChanged = new Date().getTime();
            this.$el.classList.add('_open')
        }
    }
    _close(){
        let currentTime = new Date().getTime();
        if(currentTime - this._lastChanged > 100){
            this._lastChanged = currentTime;
            this.$el.classList.remove('_open')
        }
    }
  };
}
