
import Base, { Options, Prop, Watch, createApp } from "@/components/BaseComponent.vue"
import { InputHTMLAttributes } from "@vue/runtime-dom"
import MovingIcon from "movingicons"
import IconButton from "./IconButton.vue"
import TextInput from "./TextInput.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import PercentageSpinner from "@/components/elements/PercentageSpinner.vue"
import FileUploader from "./FileUploader.vue"

@Options({ 
  components:{ 
    MovingIcon,
    LazyImage,
    IconButton,
    TextInput, 
    FileUploader,
    PercentageSpinner
  },
  emits:['newImageURL','close','preview']
})
export default class ImageSelector extends Base { 

  @Prop({}) header:string

  imagePreview:string = ''
  mode:string = '';
  uploading:boolean = false;
  uploader:FileUploader;
  uploadPercentage=0;

  upload(){
    // if(this.mode=="upload"){
    //   this.uploading = true;
    //   this.uploader.upload().then((data:any)=>{
    //     this.imagePreview = '';
    //     this.mode = '';
    //     this.$emit('newImageURL', data.location);
    //     setTimeout(()=>{
    //       this.uploading = false;
    //     },750)
    //   })
    // }else{
    //   this.$emit('newImageURL', this.imagePreview);
    //   this.imagePreview = '';
    // }
  }

  status:number = 0; // 0 = nothing, -1 = load, 1 = success, 2 = fail
  load(){
    this.status = -1;
  }
  succeed(){
    this.status = 1;
  }
  fail(){
    this.status = 2;
  }


  imageChange(){

  }
  uploaderPreview(mode:"input"|"upload",previewsrc:string){
    this.uploader = (this.$refs.fileUploader as FileUploader);
    this.imagePreview = previewsrc;
    this.mode = mode;
    this.$emit('preview',this.imagePreview)
  }

  uploaded(file:any[]){
    this.$emit('newImageURL',file[0].location)
  }

  uploaderClear(){
    this.$emit('preview','')
    this.imagePreview = '';
    this.mode = '';
  }
}
