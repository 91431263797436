import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3c1038e0")
const _hoisted_1 = {
  key: 0,
  class: "t"
}
const _hoisted_2 = {
  key: 1,
  class: "content"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ripple = _resolveComponent("Ripple")!
  const _component_MovingIcon = _resolveComponent("MovingIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args))),
    class: _normalizeClass({
    toggleWindow:true,
    clickable:true,
    noSelect:true,
  })
  }, [
    _createVNode(_component_Ripple),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({i:true,on: _ctx.on})
    }, [
      _createVNode(_component_MovingIcon, {
        name: "chevron_down",
        ref: "icon"
      }, null, 512)
    ], 2),
    (_ctx.on)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}