
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"

@Options({
  components:{
    MovingIcon
  },
  emits:['input','change','focus','blur','enter']
})
export default class TextInput extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) type:string;
  @Prop({}) label:string;
  @Prop({}) placeholder:string;
  @Prop({default:''}) default:string;
  @Prop({}) readonly:boolean;
  @Prop({}) saving:boolean;
  @Prop({}) limit:number;

  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) success:boolean; 
  @Prop({}) alert:boolean; 
  @Prop({}) test:string

  @Prop({}) typing:string

  input:HTMLTextAreaElement|boolean = false;
  length:number = 0;
  hasmounted:boolean = false;

  mounted(){
    this.input = this.$refs.input as HTMLTextAreaElement;
    this.input.value = this.default;
    this.shouldPlaceholderBeVisible();
    this.hasmounted = true;
  }

  enterPressed(){
    this.$emit('enter')
  }
  
  @Watch('default')
  shouldPlaceholderBeVisible(){
    if(!this.hasmounted) return;
    this.length = (this.input as HTMLTextAreaElement).value.length;
  }

  change(value:InputEvent){
    let v:any = (this.input as HTMLTextAreaElement).value;

    if(this.typing == "number"){
      v = parseFloat(v)
    }

    this.$emit('input',value)
    this.$emit('change', v);
  }
}
