
import { Options, Vue } from 'vue-class-component';
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import IconButton from "@/components/elements/IconButton.vue"

@Options({  
  components: {
    MovingIcon,
    Ripple,
    IconButton
  },
})
export default class DeveloperHome extends Vue {
  mounted(){
    this.$router.replace({path:'/developer/games'})
  }
}
