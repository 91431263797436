
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
 
@Options({
  components:{
  },
  emits:[]
})
export default class Surface extends Base { 

  @Prop({}) name!:string

  @Prop({}) height:number
  @Prop({}) width:number
  @Prop({}) depth:number
  
  @Prop({}) y:number
  @Prop({}) x:number
  @Prop({}) z:number

  @Prop({}) color:string
  @Prop({}) transform:any = {}

  properties:{
    height:number,
    width:number,
    depth:number,
    x:number|string,
    y:number|string,
    z:number|string,
    color:string
  } = {
    height:1,
    width:1,
    depth:1,
    x:0,
    y:0,
    z:0,
    color:"white"
  }

  styles:any = false

  fixedTransform:any = false
  mutatableTransform:any = {};
  transformer:HTMLElement = document.documentElement;

  mounted(){
    this.instantiate();
    this.transformer = this.$refs.transformer as HTMLElement;
  }

  @Watch('height') 
  @Watch('width') 
  @Watch('depth')
  @Watch('y')
  @Watch('x')
  @Watch('z')
  @Watch('color')
  instantiate(){
    this.properties = {
      height: this.height || 1,
      width: this.width || 1,
      depth: this.depth || 1,
      x: this.x ? `${this.x*100}px` : "0px",
      y: this.y ? `${this.y*100}px` : "0px",
      z: this.z ? `${this.z*100}px` : "0px",
      color: this.color == 'highlight'? '#6817ff' :this.color || "white"
    }
    if(this.name == 'floor'){
      this.properties.height = 0.25;
      this.properties.z = `-100px`;
    }
    this.setFixedTransform();
    this.updateTransformerDimensions();
  }

  setFixedTransform(){
    this.fixedTransform = {
      left:this.properties.x,
      top: this.properties.y,
      transform: `rotateY(-90deg) translateX(${this.properties.z}) rotateY(90deg)`,
    }
    this.styles = {
      vertWallsOne:{
        height:`${100*this.properties.height}px`,
        width:`${100*this.properties.width}px`,
        top:`${(100*this.properties.width)/2}px`
      },
      horWalls:{
        transform:`rotateY(-90deg) translateX(${(100*(this.properties.height+1))/2}px) rotateY(90deg)`
      },
      horWallsInv:{
        transform:`rotateY(-90deg) translateX(${(-100*(this.properties.height+1))/2}px) rotateY(90deg)`
      },
      vertWallsTwo:{
        height:`${100*this.properties.height}px`,
        width:`${100*this.properties.depth}px`,
        left:`${(100*this.properties.depth)/2}px`,
      },
    }
  }

  updateTransformerDimensions(){
    this.mutatableTransform = {
      width:(this.properties.width*100)+'px',
      height:(this.properties.depth*100)+'px',
    }
  }
}
