
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import {purchase,Game} from "@/firebase/api/games"
import firebase from "@/firebase"

declare var paypal:any;

@Options({
  components:{
    MovingIcon,
    Ripple,
  },
  emits:['purchased']
})
export default class PayPalButton extends Base { 
  @Prop({}) game:Game
  @Prop({}) description:string

  paypalWrapper:HTMLElement;
  payChecking:boolean = false;
  paidFor:boolean = false;
  paymentFailed:boolean = false;

  mounted(){
    this.paypalWrapper = this.$refs.paypal as HTMLElement;
    this.init();
  }

  init(){
    paypal.Buttons({
      style: {
        layout: 'vertical',
        shape:  'rect',
        label: 'paypal',
      },
      createOrder: (data:any, actions:any) => {
        return actions.order.create({
          purchase_units: [
            {
              reference_id: this.game.id,
              description: "Gochase.me game purchase",
              custom_id: `game_${this.game.id}`,
              soft_descriptor: `gochaseme_game`,
              amount: {
                currency_code: "USD",
                value: this.game.price || "4.98",
                breakdown: {
                  item_total: {
                    currency_code: "USD",
                    value: this.game.price || "4.98",
                  }
                }
              },
              items: [
                {
                  name: `Game ${this.game.id}`,
                  description: `Title ${this.game.title}`,
                  sku: `g_${this.game.id}`,
                  unit_amount: {
                      currency_code: "USD",
                      value: this.game.price || "4.98",
                  },
                  quantity: "1"
                },
              ],
            }
          ]
        });
      },
      onApprove:(data:any, actions:any)=>{
        actions.order.capture().then(()=>{
          purchase(this.game.id,this.$store.state.Auth.user.id).then((doc:any)=>{
            this.paidFor = true;
            this.$emit('purchased');
          })
        })
      }
    }).render(this.paypalWrapper)
  }
}
