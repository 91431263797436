
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import PayPalButton from "@/components/elements/PayPalButton.vue"
import Countdown from "@/components/elements/Countdown.vue"
import Account from "@/components/elements/Account.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import randomGen from "./randomGenerator"  
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import MovingIcon from "movingicons"
import LoginButton from "@/components/elements/LoginButton.vue"
import ToggleWindow from "@/components/elements/ToggleWindow.vue"
import LobbyWindow from "@/components/elements/LobbyWindow.vue"
import TutorialPopup from "@/components/elements/TutorialPopup.vue"
import Audio from "@/components/elements/Audio"

import { Game, getGameData, getGame, allCardsWhere, getRatingData } from "@/firebase/api/games"
import { getConnectedState, getLobby, getPermissionState, enterLobby, watchLobby, detatchLobby } from "@/firebase/api/lobby"
import firebase, {db, realtime} from "@/firebase"
import { startPlaythrough, startPlaythroughCountdown, stopPlaythroughCountdown, savePlaythrough, saveHistory } from "@/firebase/api/playthrough"

@Options({  
  components: {
    Header,
    IconButton,
    Countdown,
    Account,
    MovingIcon,
    LoadSpinner,
    LazyImage,
    PayPalButton,
    LoginButton,
    ToggleWindow,
    LobbyWindow,
    TutorialPopup
  },
})
export default class Lobby extends Base {
  difficulties:string[] = ['easy','med','hard','imp']
  lobbyLoading:boolean = true;
  tutorialPhase:number=-1;
  tutorialLength:number=8;

  connected:boolean = false;
  lobbyPermission:{
    joined:boolean,
    banned:boolean
  } = {
    joined:false,
    banned:false
  };

  game:Game|boolean = false;
  purchased:any = false;
  startedTime:string = "";
  finishedTime:string = "";
  stoppedTime:string = "";
  
  paramsGameId:string = '';
  paramsLobbyCode:string = '';
  lobby:any = {};
  navigatingForJoin:boolean = false;

  mounted(){
    this.clearStoreState();
    this.init();
    this.watchUnload();
    this.getStartedTime();
    this.getFinishedTime();
    this.getStoppedTime();
    this.autoEnter();
  }

  autoEnter(){
    if(this.$store.state.GameView?.lobby?.playthrough?.started > 0){
      this.enterPlaythrough()
    }
  }

  addFakie(){
    this.$store.commit('gameView_addFakePlayer');
  }

  timeLeft(duration:number){   
    let hrs:any = ~~(duration / 3600);
    let mins:any = ~~((duration % 3600) / 60);
    let secs:any = ~~duration % 60;
    let ret:any = "";

    if (hrs > 0) ret += hrs + " hours";
    
    if(mins && !secs) ret += " and ";
    else if(hrs && mins && secs) ret += ", ";
    if(mins > 0 || hrs > 0) ret += mins + " minute";
    if(mins > 1){
      ret += "s "
    }else{
      ret += " "
    }

    if(mins && secs) ret += "and ";
    ret += secs +" seconds";
    return ret;
  }
  
  @Watch('$store.state.GameView.lobby.playthrough.started')
  getStartedTime(){
    this.startedTime = this.getTime(this.$store?.state?.GameView?.lobby?.playthrough?.started);
  }
  
  @Watch('$store.state.GameView.lobby.playthrough.finished')
  getFinishedTime(){
    this.finishedTime = this.getTime(this.$store?.state?.GameView?.lobby?.playthrough?.finished);
  }
  
  @Watch('$store.state.GameView.lobby.playthrough.stopped')
  getStoppedTime(){
    this.stoppedTime = this.getTime(this.$store?.state?.GameView?.lobby?.playthrough?.stopped);
  }

  @Watch('$store.state.GameView.lobby.playthrough.permitted',{deep:true})
  watchKicked(){
    let id = this.$store.state.Auth?.user?.id;
    let p = this.$store.state.GameView?.lobby?.permitted;
    if(p){
      let isP = this.$store.state.GameView?.lobby?.permitted[id]
      if(isP){
        if(isP.banned){
          this.$router.replace({path:'/app'})
          setTimeout(()=>{
            alert("You were kicked");
          },250)
        }
      }
    }
  }

  getTime(val:number){
    let ret = "";
    if(val){
      let D:Date = new Date(val);
      let d:string|number = D.getDate() < 10? `0${D.getDate()}`:D.getDate();
      let M:string|number = D.getMonth()+1 < 10? `0${D.getMonth()+1}`:D.getMonth()+1;
      let Y:string|number = D.getFullYear() < 10? `0${D.getFullYear()}`:D.getFullYear();
      let h:string|number = D.getHours();
      let m:string|number = D.getMinutes() < 10? `0${D.getMinutes()}`:D.getMinutes();
      let am:string = "am";
      let deltaMins = Math.round((new Date().getTime() - val)/(60*1000))
      if(h >= 12){
        if(h > 12){
          h = h-12;
        }
        am = "pm"
      }
      ret += h+":"+m+" "+am+" ("+deltaMins+" minutes ago)"
    }
    return ret;
  };

  async clearStoreState(){
    this.$store.commit('gameView_setGame',undefined);
  }

  async init(){
    this.paramsGameId = this.$route.params.id as string;
    this.paramsLobbyCode = this.$route.params.code as string;
    this.setDevView(false);

    await this.getLobby();
    if(this.lobby?.meta && this.lobby.meta.gameId && this.lobby.meta?.code){
      await this.checkPermitted(); // we check here so people cant be banned and join straight back in with URL
      await this.checkConnected(); // we ensure that the user isnt already connected - double up would be bad

      if(!this.lobbyPermission.banned && this.$store.state.Auth.user.id && this.$store.state.Auth.user.name){
        await enterLobby(this.lobby.meta?.code,this.$store.state.Auth.user,false);
        watchLobby(this.lobby);
        this.lobbyPermission.joined = true;
        this.getGameFromURL();
      }else{
        this.navigatingForJoin = true;
        this.$store.commit('gameView_setLobby',this.lobby)
        this.$router.replace({path:`/app/join/${this.lobby.meta.gameId}/${this.lobby.meta?.code}`})
      }
    }
    this.lobbyLoading = false;
  }

  async getLobby(){
    let lobbyReturned = await getLobby(this.paramsGameId,this.paramsLobbyCode);
    if(lobbyReturned){
      this.lobby = lobbyReturned;
    }
  }

  // JOINING PROCESS
  // 1. check permission (i.e. not banned)
  // 2. check not already connected - prevent double player control
  // 3. if both checks okay, then join lobby
  async checkPermitted(){
    if(this.$store.state.Auth.user){
      this.lobbyPermission = await getPermissionState(this.lobby.meta?.code, this.$store.state.Auth.user.id, this.$store.state.Auth.user.name)
    }else{
      this.lobbyPermission.joined = false;
    }
  }
  async checkConnected(){
    this.connected = await getConnectedState(this.lobby.meta?.code, this.$store.state.Auth.user.id, this.$store.state.Auth.user.name)
  }

  leaveLobby(){
    if(this.$store.state.Auth.user && this.$store.state.Auth.user.name && this.lobby.meta?.code){
      let instance = `/lobby/${this.lobby.meta.code}/players/${this.$store.state.Auth.user.id || this.$store.state.Auth.user.name}`;
      detatchLobby(this.lobby);
      firebase.database().ref(instance).remove();
    }
  }
  watchUnload(){
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      this.leaveLobby();
    });
  }

  async getGameFromURL(){
    let id = this.$route.params.id as string;
    if(id){
      let data = await getGameData(id)
      if(data){
        getRatingData(id).then((rating)=>{
          // @ts-ignore
          let d = {...data,rating}
          this.$store.commit('gameView_setGame',{id,...d});
          if(id == "tutorial"){
            this.tutorialPhase = 0;
          }
        })
      }else{
        throw "Game does not exist"
      }
    }
  }

  startCountdown(){
    new Audio("/sounds/play.wav",true,3)
    let host:string = this.$store.state.GameView.lobby.meta.host;
    if(this.$store.state.GameView.lobby?.playthrough?.countdownStarted){
      stopPlaythroughCountdown(this.lobby)
    }else{
      if(this.$store.state.Auth.user.id == host){
        startPlaythroughCountdown(this.lobby);
      }
    }
  }
  async triggerPlaythroughStart(){
      let host:string = this.$store.state.GameView.lobby.meta.host;
      if(this.$store.state.Auth.user.id == host){
        await startPlaythrough(this.lobby);
      }
      if(this.$store.state.Auth.user && !this.$store.state.Auth.user.guest){
        saveHistory(this.$store.state.GameView.lobby)
      }
  }

  @Watch('$store.state.GameView.lobby.playthrough.started')
  watchForStartChanges(to?:number, from?:number){
    if(from === 0 && to){
      this.enterPlaythrough();
    }
  }


  enterPlaythrough(to?:number, from?:number){
    new Audio("/sounds/begin.wav",true,3)
    this.$router.push({
      path:`/app/game/${this.lobby.meta.gameId}/${this.lobby.meta.code}`
    })
  }

  setDevView(state:boolean){
    this.$store.commit('setDevView',state);
  }

  @Watch('$route')
  routeChange(to:any,from:any){
    if(to.name !== "Game" && to.name !== "Lobby" && !this.navigatingForJoin){
      this.$store.commit('gameView_leaveLobby');
      this.leaveLobby();
    }
  }
  
}
