
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import PayPalButton from "@/components/elements/PayPalButton.vue"
import Countdown from "@/components/elements/Countdown.vue"
import Account from "@/components/elements/Account.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import randomGen from "./randomGenerator"  
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import MovingIcon from "movingicons"
import LoginButton from "@/components/elements/LoginButton.vue"

import { Game, getGameData, getPurchasedState, getRatingData } from "@/firebase/api/games"

@Options({  
  components: {
    Header,
    IconButton,
    Countdown,
    Account,
    MovingIcon,
    LoadSpinner,
    LazyImage,
    PayPalButton,
    LoginButton
  },
})
export default class GameStore extends Base {
  difficulties:string[] = ['easy','med','hard','imp']

  game:Game|boolean = false;
  purchased:any = false;
  rating:any = false;

  storeLoading:boolean = true;
  purchaseRequired:boolean = false;
  isGuest:boolean = false;

  mounted(){
    this.init();
  }

  async init(){
    await this.getGame();
    await this.checkPurchased();
  }

  async getGame(){
    this.game = await getGameData(this.$route.params.id as string) as Game;
    getRatingData(this.game.id).then((rating)=>{
      this.rating = rating;
    })
  }

  async checkPurchased(){
    this.game = this.game as Game; 
    this.purchaseRequired = false;

    await this.$store.state.Auth.awaiter.promise;
    if(this.game.free == false){
      if(!this.$store.state.Auth.user.guest && !this.$store.state.Auth.user.signedOut){
        this.purchased = await getPurchasedState(this.game.id,this.$store.state.Auth.user.id);
        if(this.purchased.empty){
          this.purchaseRequired = true;
        }
      }else{
        this.purchaseRequired = true;
      }
    }
    
    this.storeLoading = false;
    if(!this.purchaseRequired && !this.game.patching){
      this.$router.replace({path:`/app/lobby/${this.game.id}`})
    }
  }
}
