import {Router,  createRouter, createWebHistory, RouteRecordRaw, RouteRecordNormalized, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

// Account routes
import AccountWrapper from '../views/account/AccountWrapper.vue'
import Account from '../views/account/Account.vue'

// Landing page routes
import PageNotFound from '../views/branding/PageNotFound.vue'
import TrademarkAustralia from '../views/branding/TrademarkAustralia.vue'
import LandingPage from '../views/branding/LandingPage.vue'

// App routes
import AppWrapper from '../views/app/AppWrapper.vue'
import Browse from '../views/app/Browse.vue'
import History from '../views/app/History.vue'
import Join from '../views/app/Join.vue'
import Lobby from '../views/app/Lobby.vue'
import LobbyGenerator from '../views/app/LobbyGenerator.vue'
import LobbyJoiner from '../views/app/LobbyJoiner.vue'
import Game from '../views/app/Game.vue'
import Tutorial from '../views/app/Game.vue'
import GameStore from '../views/app/GameStore.vue'

// UTILS
import PolicyWrapper from '../views/utils/PolicyWrapper.vue'
import PolicyHome from '../views/utils/PolicyHome.vue'
import Meta from '../views/utils/Meta.vue'
import Cookie from '../views/utils/Cookie.vue'
import Privacy from '../views/utils/Privacy.vue'
import Terms from '../views/utils/Terms.vue'
import Pwa from '../views/utils/Pwa.vue'
import SettingsWrapper from '../views/utils/SettingsWrapper.vue'
import SettingsAll from '../views/utils/SettingsAll.vue'
import Partners from '../views/utils/Partners.vue'

// Dev routes
import DeveloperWrapper from "../views/developer/DeveloperWrapper.vue"
import DevLogin from "../views/developer/Login.vue"
import DevHome from "../views/developer/Home.vue"
import DevUsers from "../views/developer/Users.vue"
import DevGamesWrapper from "../views/developer/GamesWrapper.vue"
import DevGamesBrowse from "../views/developer/GamesBrowse.vue"
import DevGamesEdit from "../views/developer/GamesEdit.vue"
import DevGamesPreview from "../views/developer/GamesPreview.vue"

const routes: Array<RouteRecordRaw> = [
  // ACCOUNT ROUTES
  {
    path: '/account',
    name: 'gcm - Account',
    component: AccountWrapper,
    children: [
      {
        path: '',
        name:'Account',
        component: Account,
      },
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsWrapper,
    children: [
      {
        path: '',
        name:'Settings',
        component: SettingsAll,
      },
      {
        path: 'partners',
        name:'Partners',
        component: Partners,
      },
    ]
  },

  // DEV ROUTES
  {
    path: '/developer',
    name: "Developer",
    component:DeveloperWrapper,
    children:[
      {
        path:'/',
        name:"Dev Home",
        component: DevHome
      },
      {
        path:'login',
        name:"Dev Login",
        component: DevLogin
      },
      {
        path:'games',
        name:'Dev Games Wrapper',
        component:DevGamesWrapper,
        children:[
          {
            path:'',
            name:'Dev Games',
            component:DevGamesBrowse
          },
          {
            path:'preview/:id/:page?',
            name:'Dev Games Preview',
            component:DevGamesPreview
          },
          {
            path:':id/:page?',
            name:'Dev Games Edit',
            component:DevGamesEdit
          }
        ]
      },
      {
        path:'users',
        name:'Dev Users',
        component: DevUsers
      },
      {
        path:'',
        name:'Dev Home',
        component: DevHome
      },
    ],
  },

  // LANDING PAGE
  {
    path: '/',
    component: LandingPage
  },
  // LANDING PAGE
  {
    path: '/policy',
    name: 'Policy',
    component: PolicyWrapper,
    children:[
      {
        path:'',
        name: 'PolicyHome',
        component: PolicyHome
      },
      {
        path:'meta',
        name: 'Meta',
        component: Meta
      },
      {
        path:'cookie',
        name: 'Cookie',
        component: Cookie
      },
      {
        path:'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path:'terms',
        name: 'Terms',
        component: Terms
      },
      {
        path:'pwa',
        name: 'Pwa',
        component: Pwa
      },
    ]
  },

  // APP ROUTES
  {
    path: '/app',
    name:'App',
    component: AppWrapper,
    children:[
      {
        path:'lobby/:id',
        name: 'LobbyGenerator',
        component: LobbyGenerator
      },
      {
        path:'lobby/:id/:code',
        name: 'Lobby',
        component: Lobby
      },
      {
        path:'',
        name: 'Browse',
        component: Browse
      },
      {
        path:'join',
        name: 'Join',
        component: Join
      },
      {
        path:'join/:id/:code',
        name: 'LobbyJoiner',
        component: LobbyJoiner
      },
      {
        path:'history',
        name: 'History',
        component: History
      },
      {
        path:'store/:id',
        name: 'GameStore',
        component: GameStore
      },
      {
        path:'game/:id/:code/:page?',
        name: 'Game',
        component: Game
      },
    ]
  },

  {
    path:'/trademark/australia',
    component:TrademarkAustralia
  },

  // 404
  { path: '/:pathMatch(.*)*', component: PageNotFound }
]

export interface route{
  path: string,
  displayName?:string,
  icon?:string,
  type?:string,
  name:string,
  children?:route[]
}
export const RouteManager = new class RouteManagerClass{
  getPaths(name:string){
    let routesToReturn:route[] = [];
    for(let route of routes){
      if((route.name as string).toLowerCase() == name.toLowerCase()){
        let routes:route = {
          name:route.name as string || '',
          path:route.path,
          children: this.getPathchildren(route.children)
        }
        routesToReturn.push(routes)
      }
    }
    return routesToReturn;
  }

  getPathchildren(childrenArray:RouteRecordRaw[] | undefined):route[]{
    if(childrenArray == undefined) return [];

    let children:route[] = []
    for(let route of childrenArray){
      children.push({
        name:route.name as string || '',
        path:route.path,
        children: this.getPathchildren(route.children)
      })
    }
    return children;
  }

}

interface RouterExtended extends Router{
  saved:string|boolean,
  isDev:boolean,
}

function createRouterNew(args:any):RouterExtended{
  return {
    saved:false,
    isDev:false,
    ...createRouter(args)
  }
};

const router = createRouterNew({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

router.beforeEach((to:RouteLocationNormalized,from:RouteLocationNormalized,next:NavigationGuardNext) => {
  if(to.fullPath.includes('/developer')){
    router.isDev = true;
  }else router.isDev = false;
  document.title = to.name?`Gochase.me - ${(to.name as string)}`:"Gochase.me";
  next();
});

export {RouterExtended};
export default router