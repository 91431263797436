
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Ripple from "@/components/ux/Ripple.vue"
import MovingIcon from "movingicons"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class ToggleWindow extends Base { 
  @Prop({}) text:string;
  @Prop({}) default:boolean;
  on:boolean = false;

  mounted(){
    this.on = this.default;
  }

  clicked(){
    this.on = !this.on;
  }

}
