
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import {route} from "../../router"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
 
@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class NavigationItem extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) success:boolean; 
  @Prop({}) test:string
  
  navigateTo(route:route){
    let icon = this.$refs.icon as typeof MovingIcon;
    icon.play();
    if(route.path){
      this.$router.push({
        path:route.path
      })
    }
  }
}
