
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import IconButton from "@/components/elements/IconButton.vue"

@Options({
  components:{
    MovingIcon,
    Ripple,
    LazyImage,
    IconButton
  },
  emits:['input','preview','uploading','uploaded','error']
})
export default class File extends Base { 
  @Prop({}) file:{
    name:string,
    type:string,
    location:string
  };

  downloadFile(){
    window.open(this.file.location,"_blank")
  }
}
