
import { Options, Vue } from 'vue-class-component';

import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import LoginButton from "@/components/elements/LoginButton.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import { deleteUser } from '@/firebase/api/auth';
import S3 from "@/aws"

@Options({  
  components: {
    Header,
    IconButton,
    LoadSpinner,
    LoginButton
  },
})
export default class Account extends Vue {
  navOpen:boolean = false;
  loadingUser:boolean = true;

  deleteRequested:boolean = false;
  deleting:boolean = false;
  deleteSuccess:boolean = false;
  deleteError:boolean|string = false;

  mounted(){
    this.getUser();
  }

  async getUser(){
    await this.$store.state.Auth.awaiter.promise
    this.loadingUser = false;
  }

  confirmDelete(){
    this.deleting = true;
    this.deleteSuccess = false;
    this.deleteError = false;

    deleteUser().then(user=>{
      if(user){
        let id = user.uid;
        S3.delete(`${id}.jfif`).then(data=>{
          this.deleting = false;
          this.deleteSuccess = true;
          this.deleteRequested = false;
        }).catch(e=>{
          throw e;
        })
      }
    }).catch(err=>{
      this.deleting = false;
      this.deleteError = err;
      this.deleteRequested = false;
    })
  }
}
