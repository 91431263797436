
import { Options, Vue } from 'vue-class-component';

import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import Navigation from '@/components/layout/Navigation.vue'

@Options({  
  components: {
    Header,
    IconButton,
    Navigation
  },
})
export default class Home extends Vue {
  navOpen:boolean = false;
}
