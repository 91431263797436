
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"

@Options({
  components:{
    MovingIcon,
    Ripple,
  },
  emits:['update']
})
export default class LandingHost extends Base { 

  @Prop({default:-1}) stage:number

  maxStage:number=18;
  lobbyChar:string = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
  lobbyCode:string = '';

  mounted(){
    let charactersLength = this.lobbyChar.length;
    for ( let i = 0; i < 5; i++ ) {
      this.lobbyCode += this.lobbyChar.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.stageUp();
  }

  stageUp(){
    if(this.stage>this.maxStage){
      // this.$emit('update',-1);
    }else{
      this.$emit('update',this.stage+1);
    }
    let interval = 1500+Math.random()*1000;
    if(this.stage<-1){
      interval = 100;
    }
    if(this.stage<0){
      interval = 500;
    }
    if(this.stage>1&&this.stage<9 || this.stage>11){
      interval = Math.random()*500;
    }
    setTimeout(()=>{
      this.stageUp();
    },interval)
  }
}
