
import { Options, Vue } from 'vue-class-component';
import {Watch} from "@/components/BaseComponent.vue"
import firebase from "../../firebase"
import IconButton from "@/components/elements/IconButton.vue"
import Navigation from "@/components/layout/Navigation.vue"
import NavigationMeta from "@/components/layout/NavigationMeta.vue"
import Header from "@/components/layout/Header.vue"
import { RouterExtended } from '@/router';
import MovingIcon from "movingicons"

@Options({  
  components: {
    Navigation,
    NavigationMeta,
    IconButton,
    Header,
    MovingIcon
  },
})
export default class DeveloperWrapper extends Vue {

  previousPath:string = '';
  userStatusCompleted:boolean = false;
  navOpen:boolean = false;

  mounted(){
    this.ensureDeveloper(this.$route);
  }

  @Watch('$route')
  ensureDeveloper(to?:any, from?:any, next?:any){
    let blockables = ['Developer','Dev Users','Dev','Dev Games','Dev Games Wrapper','Dev Games Preview','Dev Games Edit']
    if(to && blockables.includes(to.name)){
      if(this.$store.state.Auth.user.unset || !this.$store.state.Auth.user.developer){
        (this.$router as RouterExtended).saved = this.$route.fullPath;
        this.$router.replace({path:"/developer/login"})
      }
    }
  }

  @Watch('$store.state.Auth.user',{deep:true})
  shouldCompleteMount(){
    this.userStatusCompleted = true;
  }
}
