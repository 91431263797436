import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5bca5b90")
const _hoisted_1 = { class: "appwrapper" }
const _hoisted_2 = { class: "_nav" }
const _hoisted_3 = { class: "_navBubble" }
const _hoisted_4 = { class: "_interface" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Navigation, {
          open: _ctx.navOpen,
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navOpen=true)),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navOpen=false))
        }, null, 8, ["open"])
      ])
    ]),
    (_ctx.navOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "_cover",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navOpen=false))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ])
  ]))
}