// user-module.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {Card, Game} from "@/firebase/api/games"
import Router from "@/router"

export interface Cards{
    focus?:number,
    data?:Card[]
}


@Module
export default class GameEdit extends VuexModule {

    game:Game|boolean = false;
    cards:Cards = {}
    currentFocus:Card|boolean = false
    reordering:boolean = false;
    creatingCardLoading:boolean = false

    @Mutation
    gameEdit_setCards(cards:Card[]){
        if(this.cards){
            this.cards.data = cards;
        }
    }
    @Mutation
    addCard(card:Card){
        if(this.cards){
            this.cards.data?.push(card);
        }
    }

    @Mutation 
    creatingCardLoad(status:boolean){
        this.creatingCardLoading = status;
    }

    @Mutation
    moveCardToIndex(data:{
        card:Card,
        index:number
    }){
        let currentIndex = data.card.index;
        let desiredIndex = data.index;

        if(this.cards && this.cards.data){
            for(let card of this.cards.data){
                if(card.index == desiredIndex){
                    card.index = currentIndex;
                }
            }
            data.card.index = desiredIndex;
            sortCards(this.cards.data)
        }
    }

    @Mutation
    reorder(state:boolean){
        this.reordering = state;
    }

    @Mutation
    gameEdit_setGame(game:Game){
        this.game = game;
    }

    @Mutation
    setCardFocus(focus:number){
        if(this.cards){
            this.cards.focus = focus;
            Router.replace({ params: {page:focus}})
            if(this.cards.data){
                for(let card of this.cards.data){
                    if(card.index == focus){
                        this.currentFocus = card;
                        Router.replace({ params: {page:focus}})
                    }
                }
            }
        }
    }

    @Mutation
    clearGameEdit(){
        this.game = false;
        this.cards = {};
        this.currentFocus = false;
        this.reordering = false;
    }

    @Mutation
    removeCard(card:Card){
        let currentIndex = 0;
        if(this.cards.data){
            for(let i=0; i<this.cards.data.length; i++){
                let arrFocus = this.cards.data[i];
                if(card.index == arrFocus.index){
                    this.cards.data.splice(i,1)
                    i--;
                }else{
                    if(arrFocus.index !== currentIndex){
                        arrFocus.index = currentIndex;
                    }
                    currentIndex++;
                }
            }
        }
    }
    
}

function sortCards(cards:Card[]){
    cards.sort((a, b) => (a.index > b.index) ? 1 : -1)
}