
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator'
import IconButton from "@/components/elements/IconButton.vue"
import firebase,{db} from "@/firebase"
import MovingIcon from "movingicons"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import { RouterExtended } from '@/router';

@Options({  
  components: {
    IconButton,
    MovingIcon,
    LoadSpinner
  },
})
export default class DeveloperLogin extends Vue {

  facebookLoginFail:string|boolean = false;
  facebookLoginSuccess:boolean = false;
  providers:{[index:string]:firebase.auth.AuthProvider} = {};
  user:any = {};
  newEntry:any = {}

  mounted(){
    this.providers['facebook'] = new firebase.auth.FacebookAuthProvider();
    this.providers['google'] = new firebase.auth.GoogleAuthProvider();
  }

  login(provider:'facebook'|'google'){
    this.facebookLoginSuccess = false;
    this.facebookLoginFail = false;
    
    firebase.auth()
    .signInWithPopup(this.providers[provider])
    .then((result) => {
      if(result.credential){
        this.facebookLoginSuccess = true;
      }else{
        throw new Error('Login credentials could not be found.')
      }
    })
    .catch((error) => {
      let errorMessage = error.message;
      this.facebookLoginFail = errorMessage;
    });
  }

  async awaitDevStatus(){
    let u = await this.$store.state.Auth.user;
    let dev = await u.developer;
    this.user = u;
  }


  creatingSuccess:boolean = false;
  creatingFail:boolean = false;
  create(){
    db.collection('test').add({
      value:Math.random()
    }).then((entry:any)=>{
      this.creatingSuccess = true;
      this.newEntry = entry;
    }).catch(err=>{
      this.creatingFail = true;
      console.error("FAILED",err)
    })
  }

  @Watch('$store.state.Auth.user.developer', {deep:true})
  developerRedirect(to:any,from:any){
    if(to == true){
      let route = '/developer/';
      let savedRoute = (this.$router as RouterExtended).saved;
      
      if(savedRoute && !(savedRoute as string).includes('/developer/login')){
        route = (this.$router as RouterExtended).saved as string
      }
      
      this.$router.replace({path:route});
    }
  }
} 
