import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3bb1aa74")
const _hoisted_1 = {
  key: 0,
  class: "contentsGroup"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Solid = _resolveComponent("Solid")!
  const _component_ChestLock = _resolveComponent("ChestLock")!

  return (_openBlock(), _createElementBlock("div", {
    class: "room_chest",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCheck && _ctx.handleCheck(...args)))
  }, [
    _createVNode(_component_Solid, {
      x: -0.40,
      y: -0.25,
      height: 0.3,
      width: 0.1,
      depth: 0.5,
      color: "#fff"
    }, null, 8, ["x", "y", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0.40,
      y: -0.25,
      height: 0.3,
      width: 0.1,
      depth: 0.5,
      color: "#fff"
    }, null, 8, ["x", "y", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0,
      y: -0.45,
      height: 0.3,
      width: 0.9,
      depth: 0.1,
      color: "#fff"
    }, null, 8, ["y", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0,
      y: -0.05,
      height: 0.3,
      width: 0.9,
      depth: 0.1,
      color: "#fff"
    }, null, 8, ["y", "height", "width", "depth"]),
    _createVNode(_component_Solid, {
      x: 0.0,
      y: -0.3,
      height: 0.1,
      width: 0.875,
      depth: 0.3,
      color: "#fff"
    }, null, 8, ["x", "y", "height", "width", "depth"]),
    _createElementVNode("div", {
      class: "doorGroup",
      style: _normalizeStyle({
      ['transform-origin']:'100% 50% 30px',
      transform: `rotateX(${_ctx.doorTransform?.rotateZ}deg)`  
    })
    }, [
      (this.unlocked)
        ? (_openBlock(), _createBlock(_component_Solid, {
            key: 0,
            x: 0,
            y: 0.0,
            z: 0.3,
            height: 0.05,
            width: 0.2,
            depth: 0.05,
            color: "rgb(255, 110, 110)",
            ref: "handle",
            transform: {
        ['transform-origin']:'100% 50% 10px',
        transform:`rotateY(${-_ctx.handleTransform?.rotateY}deg)`
      }
          }, null, 8, ["y", "z", "height", "width", "depth", "transform"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Solid, {
        x: 0,
        z: 0.3,
        y: -0.25,
        height: 0.09,
        width: 0.9,
        depth: 0.5,
        color: "#fff"
      }, null, 8, ["z", "y", "height", "width", "depth"])
    ], 4),
    (_ctx.opened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ChestLock, {
      locked: !this.unlocked
    }, null, 8, ["locked"])
  ]))
}