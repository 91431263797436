
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import IconButton from "./IconButton.vue"
import firebase from "@/firebase"
import { createUser } from "@/firebase/api/auth"
 
@Options({
  components:{
    IconButton
  },
  emits:['login']
})
export default class LoginButton extends Base { 

  @Prop({}) text:string;
  
  providers:{[index:string]:firebase.auth.AuthProvider} = {};
  user:any = {};
  facebookLoginSuccess:boolean = false;
  facebookLoginFail:boolean = false;
  loginCheck:boolean = false;

  mounted(){
    this.providers['facebook'] = new firebase.auth.FacebookAuthProvider();
    this.providers['google'] = new firebase.auth.GoogleAuthProvider();
    this.awaitDevStatus();
  }

  login(provider:'facebook'|'google'){
    this.facebookLoginSuccess = false;
    this.facebookLoginFail = false;
    this.loginCheck = true;
    
    firebase.auth()
    .signInWithPopup(this.providers[provider])
    .then((result) => {
      if(result.user){
        createUser({
          id:result.user.uid,
          name:result.user.displayName||'',
          email:result.user.email||'',
          photo:result.user.photoURL||''
        })
      }
      
      if(result.credential){
        this.facebookLoginSuccess = true;
        this.loginCheck = false;

      }else{
        throw new Error('Login credentials could not be found.')
      }
      this.$emit('login')
    })
    .catch((error) => {
      let errorMessage = error.message;
      this.facebookLoginFail = errorMessage;
      this.loginCheck = false;
    });
  }

  async awaitDevStatus(){
    let u = await this.$store.state.Auth.user;
    let dev = await u.developer;
    this.user = u;
  }
}
