
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"

@Options({
  components:{
    MovingIcon
  },
  emits:['input','change','enter']
})
export default class TextArea extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) type:string;
  @Prop({}) label:string;
  @Prop({}) placeholder:string;
  @Prop({default:''}) default:string;
  @Prop({}) readonly:boolean;
  @Prop({}) saving:boolean;
  @Prop({}) limit:number;

  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) success:boolean; 
  @Prop({}) test:string

  textarea:HTMLTextAreaElement|boolean = false;
  length:number = 0;
  hasmounted:boolean = false;

  mounted(){
    this.textarea = this.$refs.textarea as HTMLTextAreaElement;
    this.textarea.value = this.default;
    this.keyTrigger();
    this.shouldPlaceholderBeVisible();
    this.hasmounted = true;
  }

  enterPressed(){
    this.$emit('enter');
  }

  @Watch('default')
  shouldPlaceholderBeVisible(){
    this.length = (this.textarea as HTMLTextAreaElement).value.length;
  }

  keyTrigger(){
    if(!this.hasmounted) return;
  }

  change(value:InputEvent){
    this.$emit('input',value)
    this.$emit('change', (this.textarea as HTMLTextAreaElement).value);
  }

}
