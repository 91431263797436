
import Base, { Options, Prop, Watch, createApp } from "@/components/BaseComponent.vue"
import { InputHTMLAttributes } from "@vue/runtime-dom"
import MovingIcon from "movingicons"
import IconButton from "./IconButton.vue"
import TextInput from "./TextInput.vue"

@Options({
  components:{
    MovingIcon,
    IconButton,
    TextInput
  },
  emits:['resolve','reject']
})
export default class PopupInput extends Base { 

  @Prop({}) header:string
  @Prop({}) inputs:{
    name:string,
    placeholder:string,
    label:string
  }[]

  returnedInputs:{
    name:string,
    placeholder:string,
    value?:string
  }[] = [];

  mounted(){
    this.returnedInputs = this.inputs;
  }
 
  resolve(){
    this.$emit('resolve', this.returnedInputs);
  }
  reject(){
    this.$emit('reject')
  }

  status:number = 0; // 0 = nothing, -1 = load, 1 = success, 2 = fail
  load(){
    this.status = -1;
  }
  succeed(){
    this.status = 1;
  }
  fail(){
    this.status = 2;
  }
}
