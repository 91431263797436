
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"

@Options({
  components:{
  },
  emits:[]
})
export default class Ripple extends Base { 
  
  parent:HTMLElement;
  parentPosition:string;
  current:HTMLElement;
  wrapper:HTMLElement;
  touchDown:boolean = false;

  mounted(){
    this.getParent();
    this.spawnWrapper();
    this.events();
  }

  getParent(){
        this.parent = (this.$el as HTMLElement).parentElement as HTMLElement;
        
        let styling = getComputedStyle(this.parent)
        this.parentPosition = styling.position;

        if(this.parentPosition == 'static'){
            this.parent.style.position = 'relative';
        }
        this.parent.classList.add('noSelect')
  }
  spawnWrapper(){
      this.wrapper = document.createElement('div');
      this.wrapper.className = "_ripple_wrapper"
      this.parent.appendChild(this.wrapper);
  }

  events(){
      this.parent.addEventListener('mousedown', this.spawn)
      this.parent.addEventListener('touchstart', this.spawn)

      this.parent.addEventListener('mouseleave', this.killCurrent)
      document.addEventListener('mouseup', this.killCurrent)
      document.addEventListener('touchend', this.killCurrent)
  }
  beforeUnmount(){
      this.parent.removeEventListener('mousedown', this.spawn)
      this.parent.removeEventListener('touchstart', this.spawn)

      this.parent.removeEventListener('mouseleave', this.killCurrent)
      document.removeEventListener('mouseup', this.killCurrent)
      document.removeEventListener('touchend', this.killCurrent)
  }

  spawn(e:MouseEvent | TouchEvent){
        e.stopPropagation();
        let event:any = e;

        if(this.touchDown) return;
        
        if((e as TouchEvent).touches){
            event = (e as TouchEvent).touches[0]
            this.touchDown = true;
        }        

        this.current = document.createElement('div');
        this.current.className = "_ripple"

        let maxDimension = Math.max(this.parent.offsetWidth,this.parent.offsetHeight);

        // circle radius = 1/2 max height or width 
        // therefore, make width and height 2*maxDimension

        let top = window.pageYOffset + this.parent.getBoundingClientRect().top;
        let left = window.pageXOffset + this.parent.getBoundingClientRect().left;

        Object.assign(this.current.style,{
            width: `${2*maxDimension}px`,
            height: `${2*maxDimension}px`,
            left: `${event.pageX - left - maxDimension}px`,
            top: `${event.pageY - top - maxDimension}px`,
        })

        this.wrapper.appendChild(this.current);
  }

  killCurrent(){
    if(this.current){
        let prevEl = this.current;
        prevEl.classList.add('removing');
        setTimeout(()=>{
            this.touchDown = false;
        },10)
    setTimeout(()=>{
        prevEl.remove();
    },750)
    }
  }

}
