
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"


@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class Checkbox extends Base { 
  @Prop({}) toggled:boolean;
  @Prop({}) label:string;

  icon:typeof MovingIcon;
  mounted(){
    this.icon = this.$refs.icon as typeof MovingIcon;
  }

  play(){
    setTimeout(()=>{
      this.icon.play(300);
    },0)
  }
}
