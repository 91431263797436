import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-97bf4e38")
const _hoisted_1 = {
  key: 1,
  class: "tick"
}
const _hoisted_2 = {
  key: 2,
  class: "tick"
}
const _hoisted_3 = {
  key: 3,
  class: "t"
}
const _hoisted_4 = { class: "count" }
const _hoisted_5 = { class: "circleHalf right" }
const _hoisted_6 = { class: "circleHalf left" }
const _hoisted_7 = {
  key: 0,
  class: "pauseMenu"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ripple = _resolveComponent("Ripple")!
  const _component_MovingIcon = _resolveComponent("MovingIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({countdownWrapper:true,pausable:_ctx.pausable,open:_ctx.pausable && !_ctx.stoppedAt, stopped: _ctx.stopped, readonly: _ctx.readonly})
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
      class: _normalizeClass({
      countdown:true, counting: _ctx.current>0, complete:_ctx.completed, open:_ctx.pausable, paused: _ctx.paused
    })
    }, [
      (!_ctx.pausable)
        ? (_openBlock(), _createBlock(_component_Ripple, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.completed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_MovingIcon, {
              name: "tick",
              autoplay: true,
              hex: "#FFFFFF"
            })
          ]))
        : (_ctx.current < 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_MovingIcon, {
                name: "start",
                type: "svg",
                hex: "#FFFFFF"
              })
            ]))
          : (_ctx.current > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.timeLeft(_ctx.duration - _ctx.current + (_ctx.delta?_ctx.delta:0))), 1),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.current < _ctx.duration)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "bar",
                        style: _normalizeStyle(`transform:rotate(${-180 + (Math.min(180,360 * (1-(_ctx.current/_ctx.duration))))}deg)`)
                      }, null, 4))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.current < _ctx.duration/2)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "bar",
                        style: _normalizeStyle(`transform:rotate(${-360 * (_ctx.current/_ctx.duration)}deg)`)
                      }, null, 4))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
    ], 2),
    (_ctx.pausable && !_ctx.stoppedAt)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "button",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.pause && _ctx.pause(...args)), ["stop"]))
          }, [
            _createVNode(_component_Ripple),
            (!_ctx.paused)
              ? (_openBlock(), _createBlock(_component_MovingIcon, {
                  key: 0,
                  name: "pause",
                  type: "svg"
                }))
              : _createCommentVNode("", true),
            (_ctx.paused)
              ? (_openBlock(), _createBlock(_component_MovingIcon, {
                  key: 1,
                  name: "start",
                  type: "svg"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "button",
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args)), ["stop"]))
          }, [
            _createVNode(_component_Ripple),
            _createVNode(_component_MovingIcon, {
              name: "stop",
              type: "svg"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}