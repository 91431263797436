import { createStore } from 'vuex'

import Auth from "./Auth"
import Utils from "./Utils"
import GameEdit from "./GameEdit"
import GameView from "./GameView"

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
    Utils,
    GameEdit,
    GameView
  }
})
