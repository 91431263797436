import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-97ed29aa")
const _hoisted_1 = { class: "popupHeader" }
const _hoisted_2 = { class: "inputs" }
const _hoisted_3 = { class: "functions" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "overlay",
    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reject()))
  }, [
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
      class: _normalizeClass({
      popupInput:true,
    })
    }, [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.header), 1),
      _createElementVNode("p", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.returnedInputs, (input, index) => {
          return (_openBlock(), _createBlock(_component_TextInput, {
            readonly: _ctx.status==-1,
            label: input.label,
            placeholder: input.placeholder,
            key: input,
            onInput: ($event: any) => (input.value=$event.target.value),
            onEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resolve()))
          }, null, 8, ["readonly", "label", "placeholder", "onInput"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IconButton, {
          white: true,
          text: "Cancel",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reject()))
        }),
        _createVNode(_component_IconButton, {
          autoplay: true,
          loop: _ctx.status == -1,
          icon: _ctx.status == -1?'loader_spinner':_ctx.status == 1?'tick':_ctx.status == 2?'cross':'',
          success: _ctx.status == 1,
          fail: _ctx.status == 2,
          text: "Submit",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resolve()))
        }, null, 8, ["loop", "icon", "success", "fail"])
      ])
    ])
  ]))
}