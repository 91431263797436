
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import IconButton from "../elements/IconButton.vue"
 
@Options({
  components:{
    IconButton
  },
  emits:[]
})
export default class Header extends Base { 
  
}
