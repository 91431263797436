
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import {route} from "../../router"
 
@Options({
  components:{
  },
  emits:[]
})
export default class Navigation extends Base { 
  routeTo(path:string){
    this.$router.push(path)
  }
}
