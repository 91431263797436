
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import randomGen from "@/views/app/randomGenerator"  
import TextInput from "./TextInput.vue"
import Ripple from "@/components/ux/Ripple.vue"
import IconButton from "./IconButton.vue"
import LazyImage from "./LazyImage.vue"
import Confirm from "./Confirm.vue"
import { Game, getRatingData } from "@/firebase/api/games"

@Options({  
  components:{
    MovingIcon,
    TextInput,
    IconButton,
    Confirm,
    LazyImage,
    Ripple
  },
  emits:[]
})
export default class GamePreview extends Base { 

  @Prop({}) index:number;
  @Prop({}) tutorial:boolean;
  @Prop({}) join:boolean;
  @Prop({}) history:boolean;
  @Prop({}) linear:boolean;
  @Prop({}) game:Game
  @Prop({}) popout:{
    text:string,
    function:Function
  }[]

  rating:{
      value:number,
      number:number
  } = {
    value:0,number:0
  };
  
  randomGen = randomGen;
  difficulties:string[] = ['easy','med','hard','imp']

  mounted(){
    if(this.game && this.game.id){
      getRatingData(this.game.id).then((rating)=>{
        this.rating = rating;
      })
    }
  }

  @Watch('game')
  reMounted(){
    if(this.game && this.game.id){
      getRatingData(this.game.id).then((rating)=>{
        this.rating = rating;
      })
    }
  }

  getTime(val:number){
    let ret = "";
    if(val){
      let D:Date = new Date(val);
      let d:string|number = D.getDate() < 10? `0${D.getDate()}`:D.getDate();
      let M:string|number = D.getMonth()+1 < 10? `0${D.getMonth()+1}`:D.getMonth()+1;
      let Y:string|number = D.getFullYear() < 10? `0${D.getFullYear()}`:D.getFullYear();
      let h:string|number = D.getHours();
      let m:string|number = D.getMinutes() < 10? `0${D.getMinutes()}`:D.getMinutes();
      let am:string = "am";
      let deltaMins = Math.round((new Date().getTime() - val)/(60*1000))
      if(h >= 12){
        if(h > 12){
          h = h-12;
        }

        if(h<10){
          h=`0${h}`
        }
        am = "pm"
      }
      ret += `${d}/${M}/${Y}, at `+h+":"+m+" "+am
    }
    return ret;
  };
}
