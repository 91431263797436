
import { Options, Vue } from 'vue-class-component';
import IconButton from "@/components/elements/IconButton.vue"
import TextInput from "@/components/elements/TextInput.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import MovingIcon from "movingicons"
import { getAllHistory } from '@/firebase/api/playthrough';
import firebase, {db} from "@/firebase"


@Options({  
  components: {
    IconButton,
    MovingIcon,
    TextInput,
    LoadSpinner
  },
})
export default class History extends Vue {
  loading:boolean = true;
  historyFields:any = [];

  // CORE
  mounted(){
    this.init();
  }

  async init(){
    await this.getPlaythroughs();
  }

  async getPlaythroughs(){
    await this.$store.state.Auth.awaiter.promise
    getAllHistory(this.$store.state.Auth.user).then(data=>{
      if(data){
        this.historyFields = data;
      }
      this.loading = false;
    })
  }


  // UTILS

  getTime(val:number){
    let ret = "";
    if(val){
      let D:Date = new Date(val);
      let d:string|number = D.getDate() < 10? `0${D.getDate()}`:D.getDate();
      let M:string|number = D.getMonth()+1 < 10? `0${D.getMonth()+1}`:D.getMonth()+1;
      let Y:string|number = D.getFullYear() < 10? `0${D.getFullYear()}`:D.getFullYear();
      let h:string|number = D.getHours();
      let m:string|number = D.getMinutes() < 10? `0${D.getMinutes()}`:D.getMinutes();
      let am:string = "am";
      let deltaMins = Math.round((new Date().getTime() - val)/(60*1000))
      if(h >= 12){
        if(h > 12){
          h = h-12;
        }

        if(h<10){
          h=`0${h}`
        }
        am = "pm"
      }
      ret += `${d}/${M}/${Y}, at `+h+":"+m+" "+am
    }
    return ret;
  };

  timeLeft(duration:number){   
    let hrs:any = ~~(duration / 3600);
    let mins:any = ~~((duration % 3600) / 60);
    let secs:any = ~~duration % 60;
    let ret:any = "";

    if (hrs > 0) ret += hrs + " hours";
    
    if(mins && !secs) ret += " and ";
    else if(hrs && mins && secs) ret += ", ";
    if(mins > 0 || hrs > 0) ret += mins + " minute";
    if(mins > 1){
      ret += "s "
    }else{
      ret += " "
    }

    if(mins && secs) ret += "and ";
    ret += secs +" seconds";
    return ret;
  }

  // REQUESTS

  getGame(game:any){
    db.collection("games").doc(game.meta.gameId).get().then(snap=>{
      if(snap.exists && snap.data()){
        game.meta.title = snap.data()?.title;
        game.meta.length = snap.data()?.minutes * 60 * 1000;
      }else{
        game.meta.title = false;
      }
    })
    return "Loading...";
  }
  getHost(game:any){
    db.collection("users").doc(game.meta.host).get().then(snap=>{
      if(snap.exists && snap.data()){
        game.meta.hostName = snap.data()?.name
      }else{
        game.meta.hostName = false;
      }
    })
    return "Loading...";
  }
}
