
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import LazyImage from "@/components/elements/LazyImage.vue"
import IconButton from "@/components/elements/IconButton.vue"
import {F} from "@/firebase/api/games"

import S3 from "@/aws"
import { v4 } from "uuid"
const fs = require("fs");

@Options({
  components:{
    MovingIcon,
    Ripple,
    LazyImage,
    IconButton
  },
  emits:['input','preview','uploading','uploaded','error']
})
export default class TextInput extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) image:string;
  @Prop({}) type:string;
  @Prop({}) label:string;
  @Prop({}) placeholder:string;
  @Prop({}) readonly:boolean;
  @Prop({}) noDisplay:boolean;
  @Prop({}) multiple:boolean;
  @Prop({}) files:{
    name:string,
    type:string,
    location:string
  }[];
  @Prop({}) limit:number;
  @Prop({}) acceptedTypes:string;

  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) success:boolean; 
  @Prop({}) test:string

  input:HTMLInputElement;
  length:number = 0;
  loading:boolean = true;
  
  newFiles:F[] = [];

  mounted(){
    this.input = this.$refs.input as any;
  }

  selectFile(){
    this.input.click()
  }

  fileChosen(){
    if (this.input.files && this.input.files.length) {
      for(let [index,f] of Object.entries(this.input.files)){
        let secondaryType = f.name.split('.')[1];
        let file:F = {
          name:f.name,
          fileObject:f,
          type: secondaryType,
          class: f.type.split('/')[0] || 'document',
          fullType: f.type || secondaryType || 'undefined',
          preview: URL.createObjectURL(f),
          contentEncoding:'',
          data:''
        };

        if(this.multiple){
          this.newFiles.push(file)
        }else{
          this.newFiles = [file]
        }
      }
    }
  }
  downloadFile(index:number){
    window.open(this.files[index].location,"_blank")
  }
  removeFile(index:number){
    this.newFiles.splice(index, 1);
  }
  deleteFile(index:number){
    this.files.splice(index, 1);
    this.$emit('uploaded', this.files)
  }

  async uploadAll(){
    let uploaded:any = [];
    for(let i=0; i<this.newFiles.length; i++){
      this.upload(this.newFiles[i]).then(d=>{
        uploaded.push({
          name:this.newFiles[i].name,
          type:this.newFiles[i].type,
          location:d.location
        });

        let joinedFiles:{
          name:string,
          type:string,
          location:string
        }[] = [];
        
        if(this.files) joinedFiles = joinedFiles.concat(this.files);
        if(uploaded) joinedFiles = joinedFiles.concat(uploaded);
        this.$emit('uploaded', joinedFiles)
        this.newFiles = [];
      });
    }
  }

  async upload(file:F):Promise<{
      location: string;
      data: any;
  }>{
    return new Promise((resolve,reject)=>{

      let fileReader = new FileReader();
      fileReader.readAsDataURL(file.fileObject);
      fileReader.onloadend = (d) => {
        if(d.target && d.target.result){
          let contentType = (d.target.result as string).split(",")[0].split('data:')[1].split(';base64')[0];
          let data = (d.target.result as string).split(",")[1];
          file.uploadPerc = 1;
          return S3.upload({
            key: file.name,
            data,
            contentType
          }, (percentage)=>{
            file.uploadPerc = Math.round(percentage * 100);
          }).then(data=>{
            file.uploadPerc = 100;
            resolve(data)
          }).catch(e=>{
            file.uploadError = e;
            console.log("UPLOAD ERROR", e);
            reject(e)
          })
        }
      }
    })  
  }

  addBufferToFile(file:F):Promise<F>{
    return new Promise((resolve,reject)=>{
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file.fileObject);
      fileReader.onload = (e) => {
        if(fileReader.result){
          file.data = (fileReader.result as string).split('base64,')[1]
          resolve(file)
        }else{
          reject()
        }
      }
    })
  }
}
