import baseapi from "./baseapi"
import {WhereFilterOp} from "./baseapi"
import firebase, {db} from "@/firebase"

export interface User{
  id?:string|null;
  name?: string | null;
  email?: string | null;
  developer?: boolean | number;
  picture?:string | null;
  avatar?:string;
  unset?:boolean,
  guest?:boolean,
  signedOut?:boolean
} 

export async function createUser(data:{
  id:string
  name:string,
  email:string,
  photo:string
}){
  await db.collection('users').doc(data.id).set(data);
}

export async function getUser(id:string){
  return await db.collection('users').doc(id).get();
}

export async function deleteUser():Promise<firebase.User|null>{
  return new Promise<firebase.User|null>(async (resolve,reject)=>{
    let u = firebase.auth().currentUser;
    if(u){
      
      let provider = new firebase.auth.FacebookAuthProvider();
      let id = u.uid;
      
      u.reauthenticateWithPopup(provider).then((d) => {
        u = u as firebase.User;
        u.delete().then(() => {
          db.collection('users').doc(id).get().then(d=>{
            if(d.exists){
              d.ref.delete().then(()=>{
                resolve(u)
              }).catch(e=>{
                reject(e)
              })
            }else{
              reject();
            }
          })
        }).catch((error) => {
          console.error(error);
        });
      }).catch((error) => {
        reject(error)
      });
    }else{
      reject("No user is logged in")
    }
  })
}