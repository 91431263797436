
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import randomGen from "@/views/app/randomGenerator"
import Ripple from "@/components/ux/Ripple.vue"  
import { Card } from "@/firebase/api/games";
import {focus, clearFocus} from "@/components/elements/ActivityWindow.vue"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class CardList extends Base { 
  @Prop({}) icon:string;
  @Prop({}) text:string;
  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) success:boolean; 
  @Prop({}) test:string

  @Prop({}) cards:Card[]
  @Prop({}) cardStatus:{
    [id:string]:{
      complete?:boolean,
      correct?:boolean,
      incorrect?:boolean,
      passedTime?:number,
      hinted_one?:boolean,
      hinted_two?:boolean,
    }
  }

  hidden:boolean = false;

  randomGen=randomGen;
  focus=focus;
  clearFocus=clearFocus;

  mounted(){

  }

  navigateToCard(card:Card){
    this.$store.commit('gameView_setCardFocus', card.index);
  }

  navigateToDoor(){
    this.$store.commit('goToDoor')
  }

}
