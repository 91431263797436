
import Base, { Options, Prop, Watch, createApp } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import IconButton from "./IconButton.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"

@Options({
  components:{
    MovingIcon,
    IconButton,
    LoadSpinner
  },
  emits:['resolve','reject']
})
export default class Confirm extends Base { 

  @Prop({}) text:string
  @Prop({default:false}) pending:boolean
 
  resolve(){
    this.$emit('resolve')
  }
  reject(){
    this.$emit('reject')
  }
  
}
