
import { Options, Vue } from 'vue-class-component';

import Ripple from '@/components/ux/Ripple.vue'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import IconButton from "@/components/elements/IconButton.vue"
import CardItem from "@/components/elements/Card.vue"
import Room from "@/components/room/Room.vue"
import { Card } from '@/firebase/api/games';
import MovingIcon from "movingicons"

@Options({  
  components: {
    Header,
    Footer,
    IconButton,
    CardItem,
    Room,
    MovingIcon,
    Ripple
  },
})
export default class Home extends Vue {

  keyFound:boolean = false;
  arrowHidden:boolean = false;

  demoCard:Card = {
    id:"1234",
    index:0,
    parent:'',
    image:'tt.jpg',
    videos:"",
    title:"You have arrived!",
    description:'You find yourself within the heart of Tokyo, overwhelmed by the noise and chaos of the busy streets. You look back at the note left by your Father: "From steel, I reach to the heavens. We share our birth year."',
    hint_one:"Go to Wikipedia to find the answer",
    hint_two:"",
    hint:"Go to Wikipedia to find the answer",
    questions:[
      {
        id:'1',
        question:"What year was your father born?",
        acceptedValues:"1958",
        answer:"1958",
        incorrect:false
      }
    ],
  } 

  roomData = {
    tiles:{
      m:{
        door:{
          facing:"s",
          locked:!this.keyFound,
          opened:false,
        }
      }
    }
  }

  mounted(){
    document.addEventListener('scroll', this.scrolled)
  }
  unmounted(){
    document.removeEventListener('scroll', this.scrolled)
  }

  scrolled(){
    let d = this.$refs.header as HTMLElement;

    if(document.documentElement.scrollTop > (window.innerHeight /2)){
      d.classList.add('shadowed')
    }else{
      d.classList.remove('shadowed')
    }

    if(document.documentElement.scrollTop > 10){
      this.arrowHidden = true;
    }else{
      this.arrowHidden = false;
    }
  }


  scrolldown(){
    document.documentElement.scrollTo({
      top:window.outerHeight/1.33,
      behavior:"smooth"
    })
  }
}
