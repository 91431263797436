
import { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Solid from "./Solid.vue" 
import Lock from "./Lock.vue" 
import Tweener from "@/components/room/tweener.ts"
import Base from "./Base.vue";
 
@Options({
  components:{
    Solid,
    Lock
  },
  emits:['open']
})
export default class Door extends Base {

  @Prop({}) unlocked:boolean; 
  @Prop({}) facing:string; 
  @Prop({}) opened:number; 
  @Prop({}) fixOpen:number; 

  stopAction:boolean = false;
  startedOpen:boolean = false;
  checkedIfStartedOpen:boolean = false;

  handleTransform:{
    rotateY:number
  } = {
    rotateY:0
  };
  doorTransform:{
    rotateZ:number
  } = {
    rotateZ:0
  };


  handleJiggle:any = {
    0:{
      rotateY:0
    },
    100:{
      rotateY:-30
    },
    300:{
      rotateY:0
    },
    400:{
      rotateY:-30
    },
    600:{
      rotateY:0
    }
  }
  handleOpen:any = {
    0:{
      rotateY:0
    },
    10:{
      rotateY:-45
    },
    200:{
      rotateY:0
    }
  }
  handleClose:any = {
    0:{
      rotateY:-45
    },
    10:{
      rotateY:0
    }
  }

  doorOpen:any = {
    0:{
      rotateZ:0
    },
    80:{
      rotateZ:130
    }
  }
  doorClose:any = {
    0:{
      rotateZ:130
    },
    20:{
      rotateZ:0
    }
  }

  mounted(){
    this.startOpen();
    this.openUp();
  }

  handleCheck(){
    if(this.stopAction == true) return;
    this.stopAction = true;

    if(this.unlocked){
      this.$emit('open')
    }else{
      Tweener(this.handleTransform,this.handleJiggle)
    }

    setTimeout(()=>{
      this.stopAction = false;
    },700)
  }

  startOpen(){
    if(this.fixOpen){
      this.doorTransform = {
        rotateZ:130
      }
      this.startedOpen = true;
    }
    this.checkedIfStartedOpen = true;
  }

  @Watch('fixOpen', {immediate:true})
  @Watch('opened', {immediate:true})
  openUp(){
    if(!this.checkedIfStartedOpen) return;
    if((this.opened || this.fixOpen) && !this.startedOpen){
      Tweener(this.handleTransform,this.handleOpen)
      setTimeout(()=>{
        Tweener(this.doorTransform,this.doorOpen)
      },200)
    }else if(!this.startedOpen){
      Tweener(this.handleTransform,this.handleClose)
      Tweener(this.doorTransform,this.doorClose)
    }
  }
  
}
