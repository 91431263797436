
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:[]
})
export default class PercentageSpinner extends Base { 
  @Prop({}) percentage:number;
  @Prop({}) fail:boolean;

}
