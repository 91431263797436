
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import IconButton from "@/components/elements/IconButton.vue"
import ImageSelector from "@/components/elements/ImageSelector.vue"
import Ripple from "@/components/ux/Ripple.vue"

@Options({
  components:{
    MovingIcon,
    ImageSelector,
    Ripple,
    IconButton
  },
  emits:['newImageURL']
})
export default class LazyImage extends Base { 
  @Prop({}) src:string;
  @Prop({}) upload:boolean;
  @Prop({}) hideExpand:boolean;
  @Prop({}) patching:boolean;
  @Prop({}) tutorial:boolean;

  image:string = '';
  noDefault:boolean = false;
  uploadVisible:boolean = false;
  fileInput:any;
  preview:string = '';
  fullScreen:boolean = false;
  loaded:boolean = false;
  position:any={}

  mounted(){
    let img = this.$refs.img as HTMLImageElement;
    this.image = this.src;
    img.src = this.src;
    if(!this.src){
      this.loaded = true;
    }
  }

  rendered() {
    //Render complete
    setTimeout(()=>{
      this.loaded = true;
    },50)
  }

  startRender() {
    //Rendering start
    requestAnimationFrame(this.rendered);
  }

  rendering()  {
    requestAnimationFrame(this.startRender);
  }

  focus(){
    if(this.patching) return;
    if(this.upload){
      this.change()
    }else{
      this.open()
    }
  }
  newTab(){
    window.open(this.image, "_blank")
  }

  change(){
    if(!this.fileInput){
      this.fileInput = this.$refs.fileInput;
    }
    this.uploadVisible = true;
  }

  open(){
    this.fullScreen = true;
  }
  close(){
    this.fullScreen = false;
  }

  showPreview(preview:string){
    this.preview = preview;
  }

  closeUpload(){
    this.preview = '';
    this.uploadVisible = false;
  }

  changeImageURL(url:string){
    this.$emit('newImageURL',url)
    this.image = url;
    setTimeout(()=>{
      this.closeUpload();
    },750)
  }
}
