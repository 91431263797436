
import Base, { Options, Prop } from '@/components/BaseComponent.vue';

import Header from '@/components/layout/Header.vue'
import IconButton from "@/components/elements/IconButton.vue"
import Dropdown from "@/components/elements/Dropdown.vue"
import GamePreview from "@/components/elements/GamePreview.vue"
import TextInput from "@/components/elements/TextInput.vue"
import LoadSpinner from "@/components/elements/LoadSpinner.vue"
import HistoryPopup from "@/components/elements/HistoryPopup.vue"
import { Game, TUTORIAL, allGames, getUserPurchasedStates, allReadyGames } from '@/firebase/api/games';
import firebase, {db} from "@/firebase"

@Options({  
  components: { 
    Header,
    IconButton,
    Dropdown,
    TextInput,
    LoadSpinner,
    HistoryPopup,
    GamePreview
  },
})
export default class Home extends Base {

  numberElements:number = 23;
  elementFocus:number = -1;
  loading:boolean = true;
  games:Game[] = [];
  store:Game[] = [];
  tutorial:Game = TUTORIAL;

  sortOptions:{
    active:number,
    options:{
      text:string,
      icon?:string,
      sortFunction?:Function
    }[]
  } = {
    active:0,
    options:[
      {
        text:"Newest",
      },
      {
        text:"Oldest",
      },
      {
        text:"Reviews",
      }
    ]
  }

  mounted(){
    document.addEventListener('click',this.unfocus);
    this.getGames();
  }
  beforeUnmount(){
    document.removeEventListener('click',this.unfocus)
  }

  focusGame(index:number){
    this.elementFocus = index;
  }

  unfocus(){
    this.elementFocus = -1;
  }

  openGame(game:Game){
    if(!game.patching){
      this.$router.push({ path:`/app/store/${game.id}/`})
    }
  }

  async getGames(){
    let g = await allReadyGames()
    
    await this.$store.state.Auth.awaiter.promise;
    if(!this.$store.state.Auth.user.guest && !this.$store.state.Auth.user.signedOut){
      let pur = await getUserPurchasedStates(this.$store.state.Auth.user.id);
      for(let game of g){
        if(pur.some(state => state.gameId == game.id)){
          game.owner = true;
          this.games.push(game)
        }else{
          this.store.push(game)
        }
      }
    }else{
      this.games = g;
    }
  }

}
