
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import Ripple from "@/components/ux/Ripple.vue"
import MovingIcon from "movingicons"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:['update']
})
export default class Dropdown extends Base { 

  MovingIcon:HTMLElement|typeof MovingIcon;
  open:boolean = false;

  @Prop({}) text:string;
  @Prop({}) label:string;
  @Prop({}) playOnClick:boolean;
  @Prop({default:false}) appearOnClick:boolean;
  @Prop({}) large:boolean;
  @Prop({}) route:string;
  @Prop({}) colored:boolean;
  @Prop({}) white:boolean;
  @Prop({}) loop:boolean;
  @Prop({}) success:boolean; 

  @Prop({}) options:{
    active:number,
    options:{
      text:string,
      icon:string,
      sortFunction?:Function
    }[]
  }

  mounted(){
    this.MovingIcon = this.$refs.icon as HTMLElement;
  }

  clicked(){
    (this.MovingIcon as typeof MovingIcon).play();
    this.open = !this.open;
  }

  close(){
    this.open = false;
  }

  select(active:number){
    this.$emit('update', active);
    this.clicked();
  }

}
