
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import LoginButton from "@/components/elements/LoginButton.vue"
import IconButton from "@/components/elements/IconButton.vue"
import firebase from "@/firebase"

@Options({
  components:{
    MovingIcon,
    Ripple,
    LoginButton,
    IconButton
  },
  emits:[]
})
export default class Account extends Base {
  
  @Prop({}) display:boolean
  @Prop({}) joining:boolean
  @Prop({}) host:boolean
  @Prop({}) me:boolean
  @Prop({}) player:{
    name:string,
    picture:string
  }

  loading:boolean = true;
  open:boolean = false;

  logoutTriggered:boolean = false;
  logoutSuccess:boolean = false;
  logoutError:boolean = false;

  mounted(){

  }

  signout(){
    this.logoutTriggered = true;
    this.logoutSuccess = false;
    this.logoutError = false;

    firebase.auth().signOut().then(d => {
      this.logoutTriggered = false;
    }).catch(e => {
      this.logoutTriggered = false;
      this.logoutError = true;
      throw Error(e)
    })
  }

  @Watch('$store.state.Auth.user')
  userUpdated(){
    this.loading = true;
    if(this.$store.state.Auth.user.name){
      this.loading = false;
    }
  }

}
