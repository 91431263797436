
import Base, { Options, Prop, Watch } from "@/components/BaseComponent.vue"
import MovingIcon from "movingicons"
import Ripple from "@/components/ux/Ripple.vue"
import Audio from "@/components/elements/Audio"

@Options({
  components:{
    MovingIcon,
    Ripple
  },
  emits:['complete', 'stop', 'play', 'pause']
})
export default class Countdown extends Base { 
  @Prop({}) duration:number;
  @Prop({}) pausable:boolean;
  @Prop({}) autoplay:boolean;
  @Prop({}) readonly:boolean;
  @Prop({}) startTime:number;
  @Prop({}) delta:number;
  @Prop({}) forcePause:number;
  @Prop({}) pausedAt:number;
  @Prop({}) stoppedAt:number;
  @Prop({}) stopped:any;
  @Prop({}) sound:true;

  hidden:boolean = false;
  current:number = -1;
  prevCur:number = -1;
  paused:boolean = false;
  completed:boolean = false;
  open:boolean = false;

  lastEventTime:number;
  milliseconds:number = 1000;
  clock:any;

  timeLeft(duration:number){   
      let hrs:any = ~~(duration / 3600);
      let mins:any = ~~((duration % 3600) / 60);
      let secs:any = ~~duration % 60;
      let ret:any = "";

      if (hrs > 0) ret += "" + hrs + ":";
      if(mins > 0 || hrs > 0) ret += mins + ":";

      if(mins < 10 && hrs > 0) mins = "0"+mins;
      if(secs < 10) secs = "0"+secs;

      ret += "" + secs;
      return ret;
  }

  mounted(){
    if(this.autoplay || this.startTime){
      this.start()
    }
  }
  beforeUnmount(){
    clearTimeout(this.clock)
  }

  click(){    
    if(this.current < 0){
      this.start();
    }else if(this.paused){
      this.unpause();
    }else{
      if(this.pausable){
        this.open = !this.open;
      }else{
        this.stop();
      }
    }
  }
  
  @Watch('startTime')
  start(){
    this.completed = false;
    this.lastEventTime = new Date().getTime();
    
    this.current = 0;
    this.prevCur = this.current;
    clearTimeout(this.clock)

    if(this.pausedAt){
      this.current = (this.pausedAt - this.startTime)/1000; // returns seconds
    }else if(this.startTime){
      this.current = (new Date().getTime() - this.startTime)/1000; // returns seconds
    }else{
      this.current = this.current - 1;
    }

    this.tick();
  }

  @Watch('forcePause')
  tick(){
    
    if(this.stoppedAt){
      this.current = (this.stoppedAt - this.startTime)/1000
      this.prevCur = this.current;
    }else if(!this.forcePause){
      this.milliseconds = 10;
      this.paused = false;
      this.prevCur = this.current;
      if(this.startTime){
        this.current = (new Date().getTime() - this.startTime)/1000; // returns seconds
      }else{
        this.current = this.current - 1;
      }

      if(this.sound && Math.floor(this.prevCur) < Math.floor(this.current)){
        new Audio("/sounds/tick.wav",true,3)
      }

      if((this.duration - this.current) > 0){
        this.clock = setTimeout(()=>{
          this.tick()
        },this.milliseconds)
      }else{
        this.complete();
      }
    }else{
      this.paused = true;
    }
  }

  stop(){
    this.$emit('stop')
  }

  pause(){
    this.lastEventTime = new Date().getTime();
    this.paused = !this.paused;
    if(!this.paused){
      this.unpause()
      this.$emit('play')
    }else{
      this.$emit('pause')
      clearTimeout(this.clock);
    }
  }
  unpause(){
    this.paused = false;
    this.tick();
  }

  complete(){
    this.completed = true;
    this.stop();
    this.$emit('complete')
  }
}
